import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";

const DEFAULT_DURATION = 5;

function CameraSettingsLiveView(props) {
  const { camera, setCamera } = props;

  const durationOptions = [
    { label: "Always", value: "always" },
    { label: "Limit time", value: "limit-time" },
  ];
  return (
    <div
      className="d-flex flex-column gap-8 pt-2"
      style={{
        fontSize: "14px",
        minHeight: "300px",
      }}
    >
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className="text-truncate fw-bolder"
          title="Enable Camera Live View"
          style={{
            maxWidth: "400px",
            width: "400px",
          }}
        >
          Enable Camera Live View
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {`Allow live view of the cameras through the user interface. 
                This increases the CPU consumption`}
            </Tooltip>
          }
        >
          <div className="w-100">
            <SwitchCheckbox
              checked={camera?.rtsp_stream_enabled}
              onChange={(e) =>
                setCamera({ ...camera, rtsp_stream_enabled: e.target.checked })
              }
            />
          </div>
        </OverlayTrigger>
      </div>
      {camera?.rtsp_stream_enabled && (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {`How long (minutes) to turn on live camera viewing, 
            after that time, viewing will stop.
            For re-watching click on "Reload cameras stream" button`}
              </Tooltip>
            }
          >
            <div className="d-flex align-items-center justify-content-start gap-8">
              <div
                className="text-truncate fw-bolder"
                title="Live stream duration"
                style={{
                  maxWidth: "250px",
                  width: "100%",
                }}
              >
                Live stream duration
              </div>
              <div
                style={{ maxWidth: "300px", width: "-webkit-fill-available" }}
              >
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select..."
                  id="edit-cam-live-stream-duration"
                  value={durationOptions.find((o) =>
                    camera?.rtsp_stream_duration > 0
                      ? o.value === "limit-time"
                      : o.value === "always"
                  )}
                  onChange={(e) => {
                    if (e.value === "always") {
                      setCamera({ ...camera, rtsp_stream_duration: -1 });
                    } else {
                      setCamera({
                        ...camera,
                        rtsp_stream_duration:
                          camera?.rtsp_stream_duration > 0
                            ? camera.rtsp_stream_duration
                            : DEFAULT_DURATION,
                      });
                    }
                  }}
                  options={durationOptions}
                  theme="dark"
                />
              </div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {`Duration of live viewing of this camera, after this time, 
      viewing will stop and to re-watch click on "Reload cameras stream" button`}
              </Tooltip>
            }
          >
            <div className="d-flex align-items-center justify-content-start gap-8">
              <div
                className={`text-truncate fw-bolder ${
                  camera?.rtsp_stream_duration > 0 ? "" : "sr-disabled"
                }`}
                title="Duration time in minutes"
                style={{
                  maxWidth: "250px",
                  width: "100%",
                }}
              >
                Duration time{" "}
                <span className="ms-1" style={{ fontSize: "11px" }}>
                  (minutes)
                </span>{" "}
              </div>

              <input
                className={`sr-text-input  ${
                  camera?.rtsp_stream_duration > 0 ? "" : "sr-disabled"
                }`}
                style={{
                  maxWidth: "300px",
                }}
                type="number"
                placeholder={`${DEFAULT_DURATION}...`}
                value={camera?.rtsp_stream_duration || DEFAULT_DURATION}
                onChange={(e) =>
                  setCamera({
                    ...camera,
                    rtsp_stream_duration: parseInt(e.target.value),
                  })
                }
              />
            </div>
          </OverlayTrigger>
        </>
      )}
    </div>
  );
}

export default CameraSettingsLiveView;
