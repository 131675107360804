import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaHistory, FaSearch, FaSpinner } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HistoryElement from "./HistoryElement";
import { HiChevronDoubleDown } from "react-icons/hi";
import { CircularProgress } from "@mui/material";
import { useApiFacesFilteredPagination } from "../../context/facesContext";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";

const defaultMinConf = 75;
const PAGE_SIZE = 50;
const SORT_BY = "-create_at";

function HistorySection(props) {
  const { person, isUnknown, initTime, face } = props;
  var end = new Date(initTime);
  end.setDate(end.getDate() - 1);

  const [filters, setFilters] = useState(null);

  const {
    data: facesData,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useApiFacesFilteredPagination(
    PAGE_SIZE,
    filters,
    SORT_BY,
    [],
    !!face, // enabled
    true, // attach details (face extra) to results
    true // no filters build
  );

  const [faces, setFaces] = useState([]);
  const [showSearch, setShowSearch] = useState(null);
  const [identificationOnly, setIdentificationOnly] = useState(!isUnknown);
  const [minimumConf, setMinimumConf] = useState(defaultMinConf);
  const initStartTime = initTime ? new Date(initTime) : new Date();

  const [startTime, setStartTime] = useState(initStartTime.toISOString());
  var initEndTime = initStartTime;
  initEndTime.setDate(initEndTime.getDate() - 1);
  const [endTime, setEndTime] = useState(initEndTime.toISOString());

  const imageToCompare = face && face.image ? face.image : person.faces[0];
  const groupResultsByHourAndCamera = (results) => {
    const groupedResults = results.reduce((facesSoFar, face) => {
      const timeKey =
        new Date(face.create_at).toLocaleDateString() +
        "-" +
        new Date(face.create_at).getUTCHours();
      const key = face.camera_id + "-" + timeKey;
      if (!facesSoFar[key]) facesSoFar[key] = [];
      facesSoFar[key].push(face);
      return facesSoFar;
    }, {});
    return groupedResults;
  };

  useEffect(() => {
    setFaces(facesData?.pages?.map((page) => page?.results || []).flat() || []);
  }, [facesData]);

  useEffect(() => {
    if (isUnknown) return;
    if (!person || !initTime || !person.id) return;
    // setIsFetching(true);
    var end = new Date(initTime);
    end.setDate(end.getDate() - 1);
    setFilters({
      create_at_before: new Date(initTime).toISOString(),
      person_id: person.id,
    });
  }, [person, isUnknown, initTime]);

  useEffect(() => {
    if (!isUnknown) return;
    if (!imageToCompare || !initTime) return;
    var end = new Date(initTime);
    end.setDate(end.getDate() - 1);
    setFilters({
      source_image: imageToCompare,
      create_at_before: new Date(initTime).toISOString(),
      grade_min: defaultMinConf,
      create_at_after: end.toISOString(),
      events_only: true,
      similarity: true,
    });
  }, [isUnknown, imageToCompare, initTime]);

  const facesGroupeBy = groupResultsByHourAndCamera(faces);
  return (
    <div>
      <div className="fs-5 fw-bold d-flex align-items-center">
        <FaHistory className="me-2" />
        <div>History</div>
        <div className="ms-4">
          <button
            className="sr-outline-btn p-1 d-flex align-items-center"
            style={{ fontSize: "14px" }}
            onClick={() => setShowSearch(!showSearch)}
          >
            <FaSearch className="me-2" />
            <span>Advanced search</span>
          </button>
        </div>
      </div>
      {showSearch && (
        <div className="mx-2 row my-2">
          <div
            className="p-2 "
            style={{
              backgroundColor: COLORS.Gray600,
              borderRadius: "8px",
              fontSize: "12px",
            }}
          >
            <div className="row d-flex justify-content-start">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Do a search only on the identified events or do a deep
                    search on all the events and look for similar faces.
                    <br />
                    <strong>Relevant only for identified event</strong>
                  </Tooltip>
                }
              >
                <div className="col-12 col-lg-2 d-flex align-items-center mb-1 mb-lg-0">
                  <label className={`me-2`}>Identification only</label>
                  <SwitchCheckbox
                    disabled={isUnknown}
                    checked={isUnknown ? false : identificationOnly}
                    onChange={(e) => setIdentificationOnly(e.target.checked)}
                  />
                </div>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Search for events before</Tooltip>}
              >
                <div className="col-12 col-lg-3 d-flex align-items-center mb-1 mb-lg-0">
                  <label className={`me-2`}>Before</label>
                  <input
                    className="sr-text-input w-75"
                    type="date"
                    value={startTime.slice(0, 10)}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>
              </OverlayTrigger>

              {!identificationOnly && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Search for events after</Tooltip>}
                >
                  <div className="col-12 col-lg-3 d-flex align-items-center mb-1 mb-lg-0">
                    <label className={`me-2`}>After</label>
                    <input
                      className="sr-text-input w-75"
                      type="date"
                      value={endTime.slice(0, 10)}
                      onChange={(e) => setEndTime(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              )}

              {!identificationOnly && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      A minimum confidence that will be considered for
                      identification in this search
                    </Tooltip>
                  }
                >
                  <div className="col-12 col-lg-3 d-flex align-items-center mb-1 mb-lg-0">
                    <label className={`me-2`}>Minimum confidence</label>
                    <input
                      className="sr-text-input w-25"
                      type="text"
                      value={minimumConf}
                      onChange={(e) => setMinimumConf(e.target.value)}
                    />
                  </div>
                </OverlayTrigger>
              )}
              <div className="col-12 col-lg-1 d-flex align-items-center mb-1 mb-lg-0">
                <button
                  className="sr-btn p-1"
                  type="button"
                  disabled={isFetching}
                  onClick={() => {
                    identificationOnly
                      ? setFilters({
                          create_at_before: new Date(startTime).toISOString(),
                          person_id: person.id,
                        })
                      : setFilters({
                          source_image: imageToCompare,
                          create_at_before: new Date(startTime).toISOString(),
                          grade_min: minimumConf,
                          create_at_after: new Date(endTime).toISOString(),
                          events_only: true,
                          similarity: true,
                        });
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="text-center">
        {isFetching && !isFetchingNextPage && (
          <>
            <CircularProgress color="primary" />
            <div>
              {!identificationOnly && (
                <div className="mt-2">This may take a few minutes...</div>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        {Object.keys(facesGroupeBy).length === 0 && !isFetching ? (
          <div className="text-center my-2">No events found</div>
        ) : (
          <VerticalTimeline layout={"1-column-left"} lineColor={COLORS.primary}>
            {Object.keys(facesGroupeBy)
              .filter(
                (key) => facesGroupeBy[key] && facesGroupeBy[key].length > 0
              )
              .map((facesGroupKey) => (
                <div key={facesGroupKey} className="pb-2">
                  <HistoryElement faces={facesGroupeBy[facesGroupKey]} />
                </div>
              ))}
            {hasNextPage && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip>Show more</Tooltip>}
              >
                <div className="pb-2" style={{ cursor: "pointer" }}>
                  <VerticalTimelineElement
                    className="vertical-timeline-element-icon--button"
                    iconStyle={{
                      background: COLORS.primary,
                      color: COLORS.FontGray,
                    }}
                    iconOnClick={fetchNextPage}
                    icon={
                      isFetchingNextPage ? (
                        <FaSpinner className="fa-spin" />
                      ) : (
                        <HiChevronDoubleDown
                          style={{ color: COLORS.FontGray }}
                        />
                      )
                    }
                  ></VerticalTimelineElement>
                </div>
              </OverlayTrigger>
            )}
          </VerticalTimeline>
        )}
      </div>
    </div>
  );
}

export default HistorySection;
