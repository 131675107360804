import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

type Props = {
  height?: string;
  color?: string;
};

export const SeparatorVertical = styled.div<Props>`
  height: ${(props) => props.height || "100%"};
  width: 1px;
  background-color: ${(props) => props.color || COLORS.FontGray};
`;
