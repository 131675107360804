import { createTheme } from "@mui/material/styles";
import { COLORS } from "../colors";

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: COLORS.PrimaryLight,
      main: COLORS.primary,
      dark: COLORS.primaryDark,
      contrastText: COLORS.FontGray,
    },
    secondary: {
      light: COLORS.LightSky,
      main: COLORS.FontGray,
      dark: COLORS.PrimaryLight,
      contrastText: COLORS.Gray800,
    },
  },
});
