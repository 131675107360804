import React from "react";
import { FaThumbsDown, FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";

function PersonBadImage(props) {
  const { result, image, badImages, setBadImages } = props;

  return (
    <div
      className="p-2 m-2 w-100"
      style={{
        position: "relative",
        backgroundColor: COLORS.Gray600,
        borderRadius: "8px",
      }}
    >
      <FaTimes
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
        onClick={() =>
          setBadImages(badImages.filter((r) => r.name !== result.name))
        }
      />
      <img src={image.data_url} alt={result.name} width="120" height="120" />

      <div className="mt-2">
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{
            color: COLORS.red,
          }}
        >
          <FaThumbsDown className="me-2" />
          Cannot find face in this image
        </div>
      </div>
    </div>
  );
}

export default PersonBadImage;
