import React from "react";
import { SketchPicker } from "react-color";
import { Flex } from "../elements/flex/Flex";
import { COLORS } from "../../assets/colors";
import { FaCloudUploadAlt } from "react-icons/fa";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { Camera, BrandingSettings } from "../../types/Camera";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";

type Props = {
  camera: Camera;
  setCamera: React.Dispatch<React.SetStateAction<Camera>>;
};

const defaultOverlayConfig: BrandingSettings = {
  enabled: false,
  text_color: COLORS.FontGray,
  background_image_url: "",
  background_image_data: "",
};

export const CameraSettingsBrandingAndroid: React.FC<Props> = ({
  camera,
  setCamera,
}: Props) => {
  const updateSettings = (settingKey: string, value: any) => {
    setCamera({
      ...camera,
      branding_settings: {
        ...(camera.branding_settings || defaultOverlayConfig),
        [settingKey]: value,
      },
    });
  };

  const handleColorChange = (color: any, name: string) => {
    const rgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
    updateSettings(name, rgba);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setCamera({
          ...camera,
          branding_settings: {
            ...(camera.branding_settings || defaultOverlayConfig),
            background_image_data: reader.result as string,
            background_image_type: file.type,
          },
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const previewStyle = {
    color: camera?.branding_settings?.text_color || COLORS.FontGray,
    backgroundImage: `url(${
      camera?.branding_settings?.background_image_data ||
      (camera?.branding_settings?.background_image_url
        ? `${IMAGE_BASE_PATH}/${camera?.branding_settings?.background_image_url}`
        : "")
    })`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ccc",
  };

  return (
    <Flex column gap="24px" className="py-3">
      <Flex align="center" gap="8px">
        <div
          className="text-truncate"
          title="Enable Branding"
          style={{
            width: "155px",
          }}
        >
          Enable Branding
        </div>
        <SwitchCheckbox
          checked={camera?.branding_settings?.enabled || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateSettings("enabled", e.target.checked)
          }
        />
      </Flex>
      {camera?.branding_settings?.enabled && (
        <>
          <Flex column gap="16px">
            <Flex flexWrap gap="16px" align="center">
              <label style={{ width: "150px" }} title="Screen Wallpaper">
                Screen Wallpaper
              </label>
              <Flex
                as="label"
                align="center"
                justify="center"
                className="custom-file-upload p-2"
                gap="8px"
              >
                <FaCloudUploadAlt />
                <input
                  type="file"
                  multiple={false}
                  id="background_image_data"
                  name="background_image_data"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                Upload Image
              </Flex>
            </Flex>
            <Flex flexWrap gap="16px">
              <label style={{ width: "150px" }}>Text Color</label>
              <SketchPicker
                color={camera?.branding_settings?.text_color || COLORS.FontGray}
                onChange={(color) => handleColorChange(color, "text_color")}
                disableAlpha={false}
                styles={{
                  default: {
                    picker: {
                      backgroundColor: COLORS.Gray600,
                    },
                  },
                }}
                presetColors={[
                  COLORS.FontGray,
                  COLORS.primary,
                  COLORS.primaryDark,
                  COLORS.BgBlack,
                  COLORS.Gray600,
                  COLORS.red,
                  COLORS.yellow,
                  COLORS.orange,
                ]}
              />
            </Flex>
          </Flex>
          <h4>Preview</h4>
          <Flex w100 justify="center" align="center">
            <Flex
              style={{
                width: "300px",
                height: "500px",
                border: "12px solid black",
                borderRadius: "36px",
                padding: "20px",
              }}
            >
              <div style={{ width: "100%", height: "100%", ...previewStyle }}>
                <Flex
                  column
                  justify="start"
                  align="center"
                  w100
                  h100
                  style={{ marginTop: "210px" }}
                >
                  <h3>{new Date().toLocaleTimeString().substring(0, 5)}</h3>
                  <label>{new Date().toDateString()}</label>
                </Flex>
              </div>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
