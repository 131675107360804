import React, { useEffect, useState } from "react";
import { Flex } from "../elements/flex/Flex";
import { COLORS } from "../../assets/colors";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { WebSettingsUpdate } from "../../types/WebSettings";
import {
  useApiUpdateWebSettings,
  useApiWebSettings,
} from "../../context/webSettingsContext";
import { Input } from "../elements/input/Input";
import { MainButton } from "../elements/buttons/MainButton";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";

export const GeneralSettings = () => {
  const { data: webSettings } = useApiWebSettings();

  const [webSettingsEdit, setWebSettingsEdit] = useState<WebSettingsUpdate>({
    auto_add_enabled: webSettings?.auto_add_enabled || false,
    auto_add_min_grade: webSettings?.auto_add_min_grade || 80,
    auto_add_max_faces: webSettings?.auto_add_max_faces || 3,
  });

  useEffect(() => {
    console.log(webSettings);
    setWebSettingsEdit({
      auto_add_enabled: webSettings?.auto_add_enabled || false,
      auto_add_min_grade: webSettings?.auto_add_min_grade || 80,
      auto_add_max_faces: webSettings?.auto_add_max_faces || 3,
    });
  }, [webSettings]);

  const { mutate: updateSettings, isLoading: isUpdating } =
    useApiUpdateWebSettings();

  const handleSave = () => {
    updateSettings({
      updateData: webSettingsEdit,
      onSuccessCallback: () => showSuccessDialog("Settings updated"),
      onErrorCallback: () => showErrorDialog("Failed to update settings"),
    });
  };

  return (
    <Flex
      column
      gap="16px"
      style={{
        color: COLORS.FontGray,
      }}
    >
      <h6>Automatically add faces to person</h6>
      <Flex column padding="0 24px" gap="16px">
        <Flex gap="8px" align="center">
          <label
            style={{
              width: "250px",
            }}
          >
            Enabled
          </label>
          <SwitchCheckbox
            checked={!!webSettingsEdit?.auto_add_enabled}
            onChange={() =>
              setWebSettingsEdit({
                ...webSettingsEdit,
                auto_add_enabled: !webSettingsEdit?.auto_add_enabled,
              })
            }
          />
        </Flex>
        <Flex gap="8px" align="center">
          <label
            style={{
              width: "250px",
            }}
            className={!webSettingsEdit?.auto_add_enabled ? "sr-disabled" : ""}
          >
            Minimum grade to add
          </label>
          <Input
            style={{
              width: "50px",
            }}
            type="number"
            placeholder="80"
            value={webSettingsEdit?.auto_add_min_grade}
            disabled={!webSettingsEdit?.auto_add_enabled}
            onChange={(e) =>
              setWebSettingsEdit({
                ...webSettingsEdit,
                auto_add_min_grade: parseInt(e.target.value),
              })
            }
          />
        </Flex>
        <Flex gap="8px" align="center">
          <label
            className={!webSettingsEdit?.auto_add_enabled ? "sr-disabled" : ""}
            style={{
              width: "250px",
            }}
          >
            Maximum faces per person
          </label>
          <Input
            style={{
              width: "50px",
            }}
            type="number"
            placeholder="3"
            value={webSettingsEdit?.auto_add_max_faces}
            disabled={!webSettingsEdit?.auto_add_enabled}
            onChange={(e) =>
              setWebSettingsEdit({
                ...webSettingsEdit,
                auto_add_max_faces: parseInt(e.target.value),
              })
            }
          />
        </Flex>
      </Flex>
      <MainButton
        onClick={handleSave}
        disabled={isUpdating}
        style={{
          width: "100px",
        }}
      >
        SAVE
      </MainButton>
    </Flex>
  );
};
