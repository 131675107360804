import React from "react";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";

function PersonSimilarityCard(props) {
  const { result, changeResultValue } = props;
  return (
    <div
      className="text-center"
      style={{
        height: "280px",
      }}
    >
      <div>The following people look similar to this image</div>

      <div
        className="text-start mt-2"
        style={{
          fontSize: "12px",
        }}
      >
        Note that different people with similar images may result in incorrect
        results
      </div>
      <div
        className="mt-2"
        style={{
          height: "140px",
        }}
      >
        <div className="text-center">
          <div className="text-capitalize me-2 fw-bold">
            {result.similarity.person_name.replaceAll("_", " ")} -{" "}
            {result.closets.grade}%
          </div>
          <img
            src={`${IMAGE_BASE_PATH}${result.similarity.faces[0]}`}
            alt={`similarity-${result.similarity.id}`}
            height="100"
            width="100"
            style={{
              borderRadius: "8px",
            }}
          />
        </div>
      </div>
      <div>
        <button
          className="sr-outline-btn mt-1"
          onClick={() => {
            changeResultValue("similarity", null);
            changeResultValue("approved", true);
          }}
        >
          Approve and take the risk
        </button>
      </div>
    </div>
  );
}

export default PersonSimilarityCard;
