import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  FaCheck,
  FaChevronDown,
  FaFileCsv,
  FaSpinner,
  FaTimes,
} from "react-icons/fa";
import { ImUserCheck } from "react-icons/im";
import { COLORS } from "../../assets/colors";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { showErrorDialog } from "../../popups/opretaions";
import { post } from "../../faceit-api/FaceitApi";
import { useApiCamerasData } from "../../context/camerasContext";

function AttendanceReportModal({ close }) {
  const { data: cameras } = useApiCamerasData();
  const [entranceZone, setEntranceZone] = useState("");
  const [exitZone, setExitZone] = useState("");
  const [startTime, setStartTime] = useState(moment().startOf("day"));
  const [endTime, setEndTime] = useState(moment().endOf("day"));
  const [isProcess, setIsProcess] = useState(false);

  const generateReport = () => {
    if (!isValidInput()) {
      return showErrorDialog("Invalid form");
    }
    setIsProcess(true);
    post(`faces/attendance_report`, {
      entrance_zone: entranceZone,
      exit_zone: exitZone,
      start_time: startTime.toISOString(),
      end_time: endTime.toISOString(),
    })
      .then((response) => {
        if (response.status === 0) {
          return showErrorDialog(response.errmsg).then(() => null);
        }
        return response;
      })
      .then((blob) => {
        if (!blob) return;
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Attendance-report.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
        showErrorDialog("Failed to generate attendance report");
      })
      .finally(() => setIsProcess(false));
  };

  const isValidInput = () => {
    return entranceZone && exitZone && startTime && endTime;
  };

  return (
    <div
      className="h-100"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <div
        className="d-flex pt-2 px-2 "
        style={{
          backgroundColor: COLORS.Gray800,
          borderRadius: "8px 8px 0 0",
        }}
      >
        <span
          className="mt-4 mt-md-2 mb-3 ms-2 text-start"
          style={{
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          <div className="d-flex align-items-center">
            <span>Download Reports</span>
          </div>
        </span>
        <span
          className="text-end"
          onClick={close}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "20px",
            top: "12px",
          }}
        >
          <FaTimes className="fs-3" />
        </span>
      </div>
      <div className="p-5">
        <div className="mb-4 p-1">
          <Card
            className="col-12 col-md-6 pt-2 px-2 mb-3"
            style={{
              boxShadow: `0 0 5px ${COLORS.PrimaryLight}`,
              background: COLORS.primaryDark,
            }}
          >
            <div className="d-flex align-items-center justify-content-between fs-5">
              <div>
                <ImUserCheck /> Attendance Report
              </div>
              <div>
                <FaCheck />
              </div>
            </div>
            <p className="mt-2" style={{ fontSize: "13px" }}>
              List people entry and exit times as well as the stay duration
              within a defined time range
            </p>
          </Card>
        </div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div>
            <FormControl
              className="col-12 col-md-6 pe-3 my-3"
              required
              size="small"
            >
              <InputLabel id="enter-zone-label">Entrance Zone</InputLabel>
              <Select
                labelId="enter-zone-label"
                id="enter-zone-select"
                value={entranceZone}
                label="Entrance Zone"
                onChange={(e) => setEntranceZone(e.target.value)}
                IconComponent={FaChevronDown}
              >
                {[
                  ...new Set(
                    cameras
                      .filter((cam) => cam.zone && cam.zone !== exitZone)
                      .map((cam) => cam.zone)
                  ),
                ].map((zone) => (
                  <MenuItem key={`zone-select-cam-${zone}`} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              className="col-12 col-md-6 pe-3 my-3"
              required
              size="small"
            >
              <InputLabel id="exit-zone-label">Exit Zone</InputLabel>
              <Select
                labelId="exit-zone-label"
                id="exit-zone-select"
                value={exitZone}
                label="Entrance Zone"
                onChange={(e) => setExitZone(e.target.value)}
                IconComponent={FaChevronDown}
              >
                {[
                  ...new Set(
                    cameras
                      .filter((cam) => cam.zone && cam.zone !== entranceZone)
                      .map((cam) => cam.zone)
                  ),
                ].map((zone) => (
                  <MenuItem key={`zone-select-cam-${zone}`} value={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DateTimePicker
              labelId="start-time-label"
              id="start-time-picker"
              value={startTime}
              label="Start Time"
              maxDateTime={endTime}
              onChange={(value) => setStartTime(value)}
              renderInput={(params) => (
                <TextField
                  className="col-12 col-md-6 pe-3 my-3"
                  required
                  size="small"
                  {...params}
                />
              )}
            />

            <DateTimePicker
              labelId="end-time-label"
              id="end-time-picker"
              value={endTime}
              minDateTime={startTime}
              label="End Time"
              onChange={(value) => setEndTime(value)}
              renderInput={(params) => (
                <TextField
                  className="col-12 col-md-6 pe-3 my-3"
                  required
                  size="small"
                  {...params}
                />
              )}
            />
          </div>
        </LocalizationProvider>
        <div className="d-flex w-100 justify-content-end pe-3">
          <Button
            startIcon={
              isProcess ? <FaSpinner className="fa-spin" /> : <FaFileCsv />
            }
            disabled={!isValidInput()}
            className="my-3"
            variant="contained"
            onClick={generateReport}
          >
            Generate Report
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AttendanceReportModal;
