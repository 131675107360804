import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { useApiPersonsData } from "../../context/personsContext";
import { FaceImage } from "../face/FaceImage";

function DashboardModal(props) {
  const { title, close, subtitle, faces } = props;
  const { data: persons } = useApiPersonsData();
  const [amountToShow, setAmountToShow] = useState(10);

  return (
    <div
      className="h-100 p-2"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray600,
        borderRadius: "12px",
      }}
    >
      <div className="d-flex ">
        <span
          className="text-end"
          onClick={close}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "20px",
          }}
        >
          <FaTimes className="fs-4" />
        </span>
        <span
          className="m-auto mt-4 mt-md-2 mb-1"
          style={{
            fontSize: "24px",
            lineHeight: "33px",
          }}
        >
          {title}
        </span>
      </div>
      <div
        className="text-center"
        style={{
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        {subtitle}
      </div>
      <div className="row mt-4">
        {faces.length === 0 && (
          <div className="text-center my-3">No faces to show</div>
        )}
        {faces.slice(0, amountToShow).map((face, i) => (
          <div key={`face-key-${face.image}-${i}`} className="col-6">
            <div className="row mb-3">
              <div className="col-4">
                {/* <img
                  width={"70px"}
                  height={"70px"}
                  style={{ borderRadius: "8px" }}
                  src={`${IMAGE_BASE_PATH}${face.image}`}
                  alt={`face-${face.image}`}
                /> */}
                <FaceImage  face={face} size="70px" alt={`face-${face.image}`} />
              </div>
              <div className="col-8">
                <div style={{ fontSize: "14px" }}>
                  {persons?.find((p) => p.id === face.person_id)?.person_name}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {new Date(face.create_at).toLocaleTimeString()},{" "}
                  {new Date(face.create_at).toLocaleDateString()}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {faces.length > amountToShow && (
        <div className="col-6 mx-auto my-2">
          <button
            className="sr-btn"
            onClick={() => setAmountToShow(amountToShow + 10)}
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
}

export default DashboardModal;
