import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteItem, get, post, put } from "../faceit-api/FaceitApi";
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import { Person, PersonUpdate } from "../types/Person";

export const PEOPLE_MAX_PAGE = 20000;

type UpdatePersonProps = {
  personId: string;
  personData?: PersonUpdate;
  onSuccessCallback: (person: Person) => void;
  onErrorCallback: (resp: any) => void;
};

type UpdateBulkPersonProps = {
  personsData: PersonUpdate[];
  onSuccessCallback: (persons: Person[]) => void;
  onErrorCallback: (resp: any) => void;
};

async function getPersons() {
  return get(
    `persons?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE.toString() }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}

async function updatePerson({ personId, personData }: UpdatePersonProps) {
  return put(`persons/${personId}`, personData);
}

async function createPerson({ personData }: UpdatePersonProps) {
  return post(`persons`, personData);
}

async function deletePerson({
  personId,
  onSuccessCallback,
  onErrorCallback,
}: UpdatePersonProps) {
  return deleteItem(`persons/${personId}`);
}

async function createBulkPersons({ personsData }: UpdateBulkPersonProps) {
  return post(`persons/bulk`, personsData);
}

async function addFacesPerson({ personId, personData }: UpdatePersonProps) {
  return put(`persons/${personId}/faces`, personData);
}

export const useApiPersonsData = () => {
  return useQuery<Person[]>("getPersons", getPersons, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdatePerson = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePerson, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        const newPerson = resp;
        return onSuccessCallback(newPerson);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries("getPersons");
    },
  });
};

export const useApiCreateBulkPersons = () => {
  const queryClient = useQueryClient();
  return useMutation(createBulkPersons, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries("getPersons");
    },
  });
};

export const useApiAddPersonFaces = () => {
  const queryClient = useQueryClient();
  return useMutation(addFacesPerson, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries("getPersons");
    },
  });
};

export const useApiCreatePerson = () => {
  const queryClient = useQueryClient();
  return useMutation(createPerson, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries("getPersons");
    },
  });
};

export const useApiDeletePerson = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePerson, {
    onSuccess: (resp, { personId, onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries("getPersons");
    },
  });
};
