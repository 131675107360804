import { Slider } from "@mui/material";
import React from "react";

function CustomRangeSlider(props) {
  const { value, onChange, min, max, hideSlider = false } = props;
  return (
    <div className="d-flex gap-8">
      {!hideSlider && (
        <div className="d-flex w-100">
          <Slider
            onChange={onChange}
            min={min}
            max={max}
            step={max / 100}
            variant="secondary"
            value={value}
            aria-label="slider"
          />
        </div>
      )}

      <input
        className=" sr-text-input "
        style={{
          width: "50px",
        }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default CustomRangeSlider;
