import React, { useState } from "react";
import { IMAGE_BASE_PATH, post } from "../../faceit-api/FaceitApi";
import { FaSearch, FaSpinner, FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { showErrorDialog } from "../../popups/opretaions";
import { BsCamera, BsPlayCircle } from "react-icons/bs";
import { MdImageSearch } from "react-icons/md";
import { isMobileDevice, isRecognized } from "../../utils/helper";
import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import { ImArrowRight } from "react-icons/im";
import { useApiPersonsData } from "../../context/personsContext";
import { FaceImage } from "../face/FaceImage";

function PredictPopup(props) {
  const { close } = props;
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isPredicting, setIsPredicting] = useState(false);
  const { data: persons } = useApiPersonsData();
  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = () => {
      var block = reader.result.split(";");
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      setImage(realData);
    };
    reader.onerror = (error) => console.error(error);
    reader.readAsDataURL(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const predictImage = () => {
    setIsPredicting(true);
    post(`persons/predict`, { image: image })
      .then((resp) => {
        console.log(resp);
        if (resp.status === 1) {
          const faces = resp.results.map((face) => {
            return {
              ...face,
              person: persons.find((p) => p.id === face.predicted_id),
            };
          });
          Swal.fire({
            title: "Results",
            html: ReactDOMServer.renderToString(
              <div>
                <div>
                  {faces.map((face) => (
                    <div
                      className="p-2 m-2"
                      key={`face-res-${face.image}`}
                      style={{
                        backgroundColor: COLORS.Gray800,
                        borderRadius: "8px",
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <FaceImage face={face} size="120px" alt="face" />
                        <div>
                          {isRecognized(face) ? (
                            <div className="fs-5">
                              {face.person.person_name}
                            </div>
                          ) : (
                            <div
                              className="fs-5"
                              style={{ color: COLORS.orange }}
                            >
                              UNKNOWN
                            </div>
                          )}

                          <div>
                            {" "}
                            <ImArrowRight className="fs-2" />
                          </div>
                        </div>
                        <img
                          src={`${IMAGE_BASE_PATH}${face.person.faces[0]}`}
                          style={{
                            height: "120px",
                            width: "120px",
                            borderRadius: "8px",
                          }}
                          alt="face"
                        />
                      </div>

                      <div>
                        {isRecognized(face) ? (
                          <div className="">{face.grade}% Confidence</div>
                        ) : (
                          <div className="">
                            Predicted as {face.person.person_name}, in{" "}
                            {face.grade}% confidence
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          });
        } else {
          showErrorDialog("Failed to identify from an image");
        }
      })
      .finally(() => setIsPredicting(false));
  };

  return (
    <div
      className="p-3"
      style={{
        color: COLORS.FontGray,
      }}
    >
      <div className="d-flex ">
        <span
          className="text-end"
          onClick={close}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "20px",
          }}
        >
          <FaTimes className="fs-4" />
        </span>
        <span
          className="m-auto mt-4 mt-md-2 mb-3"
          style={{
            fontSize: "24px",
            lineHeight: "33px",
          }}
        >
          <FaSearch className="me-2" />
          Search by image
        </span>
      </div>
      {preview && (
        <div className="text-center">
          <img
            src={preview}
            style={{
              borderRadius: "12px",
              maxHeight: "350px",
              maxWidth: "350px",
            }}
            alt="preview"
          />
        </div>
      )}

      <div className="text-center mt-3">
        <label className="custom-file-upload">
          <input
            type="file"
            multiple="false"
            onChange={handleImageChange}
            accept="image/*"
          />
          {isMobileDevice() ? (
            <div className="d-flex align-items-center">
              <BsCamera className="me-2" />
              Take a picture
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <MdImageSearch className="me-2" />
              Choose image
            </div>
          )}
        </label>
        {preview && (
          <div className="mt-2 w-50 mx-auto">
            <button
              className="sr-btn  d-flex align-items-center justify-content-center"
              onClick={predictImage}
            >
              {isPredicting ? (
                <FaSpinner className="me-md-3 d-none d-md-block fa-spin" />
              ) : (
                <BsPlayCircle className="me-md-3 d-none d-md-block" />
              )}
              RUN
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PredictPopup;
