import React from "react";
import {
  StyledSwitch,
  StyledHiddenCheckbox,
  StyledSlider,
} from "./StyledSwitch";

type SwitchCheckboxProps = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  size?: "small" | "medium";
};

export function SwitchCheckbox(props: SwitchCheckboxProps) {
  const { checked, onChange, className, disabled, size = "medium" } = props;
  return (
    <StyledSwitch className={className} size={size}>
      <StyledHiddenCheckbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <StyledSlider size={size} />
    </StyledSwitch>
  );
}
