import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  FaBell,
  FaCheckCircle,
  FaRegPlayCircle,
  FaRetweet,
  FaSortAmountUpAlt,
  FaSpinner,
  FaTrash,
  FaVest,
} from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import {
  useApiDeletePersonTracking,
  useApiPersonTrackingFilteredPagination,
} from "../../context/personTrackingContext";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import IconUser from "../../assets/icons/user.svg";
import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import MainContainer from "../main-menu/MainContainer";
import TooltipSr from "../general/TooltipSr";
import { useApiPersonsData } from "../../context/personsContext";
import {
  playVideoClip,
  showErrorDialog,
  showSuccessDialog,
} from "../../popups/opretaions";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { FilterCheckboxElement } from "../faces/filters/FilterCheckboxElement";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { Modal } from "react-bootstrap";
import PersonModal from "../face/PersonModal";
import TrackingFacesModal from "./TrackingFacesModal";
import { getSingleFace } from "../../context/facesContext";
import FaceRecognizedModal from "../face/FaceRecognizedModal";
import { useApiGroupsData } from "../../context/groupContext";
import HelmetIcon from "../../assets/icons/helmet-white.png";
import { useIsMobile } from "../../utils/hooks";

const MIN_PAGE_SIZE = 25;
const TIME_DELTA_UNION = 5; // minute

function PersonTracking() {
  let [searchParams] = useSearchParams();
  const isMobile = useIsMobile();
  const { data: cameras } = useApiCamerasData();
  const { data: persons } = useApiPersonsData();
  const { data: groups } = useApiGroupsData();

  const { mutate: deletePersonTracking, isLoading: isDeleting } =
    useApiDeletePersonTracking();

  const [personTrackingEvents, setPersonTrackingEvents] = useState([]);
  const [personTrackingEventsUnion, setPersonTrackingEventsUnion] = useState(
    []
  );
  const [totalRowsUnion, setTotalRowsUnion] = useState(0);
  const [showUnion, setShowUnion] = useState(false);
  const [paginationPerPage, setPaginationPerPage] = useState(MIN_PAGE_SIZE);
  const [ordering, setOrdering] = useState("-created");
  const [filters, setFilters] = useState({ has_related_faces: "true" });
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [showFacesWindow, setShowFacesWindow] = useState(false);

  const [relatedFaces, setRelatedFaces] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [selectedFace, setSelectedFace] = useState({});
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedCamera, setSelectedCamera] = useState({});
  const [isShowFaceModal, setIsShowFaceModal] = useState(false);

  const { data: personTrackingData, isFetching } =
    useApiPersonTrackingFilteredPagination(
      paginationPerPage,
      ordering,
      page,
      filters
    );

  useEffect(() => {
    if (personTrackingData?.results) {
      let updatedResults = [];
      let updatedResultsIds = new Set(); // Using a Set to store unique result IDs
      let prevPersonId = null;
      let prevId = null;
      let prevTime = null;

      personTrackingData.results.forEach((event) => {
        const currentPersonId =
          event.recognized_persons.length > 0
            ? event.recognized_persons[0]
            : null;
        if (
          (!currentPersonId || currentPersonId === "N/A") &&
          event.should_notify_unknown === false &&
          event.similar_unknown_track_id
        ) {
          // case unknown, change the prev data
          prevPersonId = null;
          prevId = event.id;
          prevTime = event.created;
          const similarResultIndex = updatedResults.findIndex(
            (r) => r.id === event.similar_unknown_track_id
          );
          if (similarResultIndex !== -1) {
            // Update related_faces if similarResult is already in updatedResults
            const updatedResult = {
              ...updatedResults[similarResultIndex], // Copy existing result
              related_faces: [
                ...(updatedResults[similarResultIndex].related_faces || []),
                ...(event.related_faces || []),
              ],
            };
            updatedResults[similarResultIndex] = updatedResult;
          } else {
            // If similarResult is not already in updatedResults, add it
            const new_result = personTrackingData.results.find(
              (r) => r.id === event.similar_unknown_track_id
            );
            if (new_result) {
              const updatedResult = {
                ...new_result, // Copy existing result
                related_faces: [
                  ...(new_result.related_faces || []),
                  ...(event.related_faces || []),
                ],
              };
              updatedResults.push(updatedResult); // Add the similarResult to the updatedResults list
              updatedResultsIds.add(new_result.id); // Store the IDs of these results in the Set
            }
          }
        } else {
          // check if this result is already in the updatedResults list
          if (!updatedResultsIds.has(event.id)) {
            // time check
            const newTime = new Date(event.created).getTime();
            const prevTimeAdapt = prevTime
              ? new Date(prevTime).getTime()
              : null;
            const timeDelta = prevTimeAdapt
              ? Math.abs(newTime - prevTimeAdapt) / (1000 * 60)
              : null;
            // compare to the previous event personId if they both weren't unknown, if they equal- make a union
            if (
              currentPersonId &&
              prevPersonId &&
              currentPersonId === prevPersonId &&
              timeDelta &&
              timeDelta < TIME_DELTA_UNION
            ) {
              // union to the prevId
              const similarResultIndex = updatedResults.findIndex(
                (r) => r.id === prevId
              );
              const newRelatedFaces = event?.related_faces || [];
              const newRecognizedFaces = event?.recognized_faces || [];
              const updatedResult = {
                ...updatedResults[similarResultIndex],
                related_faces: [
                  ...(updatedResults[similarResultIndex]?.related_faces || []),
                  ...newRelatedFaces,
                ],
                recognized_faces: [
                  ...(updatedResults[similarResultIndex]?.recognized_faces ||
                    []),
                  ...newRecognizedFaces,
                ],
                should_notify:
                  updatedResults[similarResultIndex].should_notify ||
                  event.should_notify,
              };
              updatedResults[similarResultIndex] = updatedResult;
              // change only the new date
              prevTime = event.created;
            } else {
              updatedResults.push(event);
              updatedResultsIds.add(event.id);
              prevPersonId = currentPersonId;
              prevId = event.id;
              prevTime = event.created;
            }
          }
        }
      });

      setPersonTrackingEventsUnion(updatedResults);
      setTotalRowsUnion(updatedResults.length || 0);
      setPersonTrackingEvents(personTrackingData.results || []);
      setTotalRows(personTrackingData.count || 0);
    }
  }, [personTrackingData]);

  useEffect(() => {
    if (persons)
      setPeopleOptions(
        persons.map((person) => ({
          label: person.person_name,
          value: person.id,
        }))
      );
  }, [persons]);

  const handlePersonTrackingClicked = () => {};

  const showFaceModal = (faceImage) => {
    getSingleFace(faceImage).then((face) => {
      if (face.status === 0) {
        showErrorDialog(face.message);
      } else {
        setSelectedFace(face || {});
        setSelectedCamera(
          cameras.find((camera) => camera.id === face.camera_id) || {}
        );
        setSelectedGroup(
          groups.find((group) => group.id === face.group_id) || {}
        );
        setSelectedPerson(
          persons.find((person) => person.id === face.predicted_id) || {}
        );
        setIsShowFaceModal(true);
      }
    });
  };

  const showRelatedFaces = (relatedFaces) => {
    setRelatedFaces(relatedFaces);
    setShowFacesWindow(true);
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const findCamera = (cameraId) => {
    return cameras?.find((cam) => cam.id === cameraId) || {};
  };

  const beforeDeleteEvent = (event) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      html: ReactDOMServer.renderToString(
        <div>
          <div>You are about to delete person track event.</div>

          <div>You won't be able to revert this.</div>
        </div>
      ),
    }).then((result) => {
      if (result.value) {
        handleDeleteEvent(event);
      }
    });
  };

  const handleDeleteEvent = (event) => {
    deletePersonTracking({
      personTrackingId: event.id,
      onSuccessCallback: () =>
        showSuccessDialog("Person tracking event deleted successfully"),
      onErrorCallback: () =>
        showErrorDialog("Failed to delete person tracking event"),
    });
  };

  const columns = [
    {
      name: "Capture face",
      id: "best_face_image",
      selector: (row) => row.related_faces,
      sortable: false,
      cell: (row) => {
        var image = IconUser;
        if (!!row.best_face_image) {
          image = `${IMAGE_BASE_PATH}/${row.best_face_image}`;
        } else if (row.recognized_faces.length > 0) {
          image = `${IMAGE_BASE_PATH}/${row.recognized_faces[0]}`;
        } else if (row.related_faces.length > 0) {
          image = `${IMAGE_BASE_PATH}/${row.related_faces[0]}`;
        }

        return (
          <div className="py-3 pointer">
            <img
              className="sr-image-person"
              style={
                {
                  // borderRadius: "50%",
                }
              }
              src={image}
              alt="person-track-event"
              onClick={() =>
                row.related_faces?.length
                  ? showFaceModal(image.replace(IMAGE_BASE_PATH + "/", ""))
                  : {}
              }
            />
            {row.best_face_video && (
              <div
                style={{
                  position: "absolute",
                  top: "80px",
                  left: "100px",
                }}
              >
                <FaRegPlayCircle
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={() => playVideoClip(row.best_face_video, isMobile)}
                />
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: "# ID",
      id: "id",
      selector: (row) => row.id,
      sortable: false,
      compact: true,
      with: "100px",
      cell: (row) => (
        <div onClick={() => handlePersonTrackingClicked(row, null)}>
          <TooltipSr placement="top" title={row.id}>
            <span style={{ width: "50px" }}>{row.id}</span>
          </TooltipSr>
        </div>
      ),
    },
    {
      name: "Person",
      id: "recognized_persons",
      selector: (row) => row.recognized_persons,
      sortable: false,
      cell: (row) => {
        const personId =
          row.recognized_persons.length > 0 ? row.recognized_persons[0] : null;
        const person = persons?.find((p) => p.id === personId) || {
          person_name: "N/A",
        };
        return (
          <div style={{ display: "contents", backgroundColor: "green" }}>
            <TooltipSr placement="top" title={person?.person_name}>
              {person?.person_name !== "N/A" ? (
                <Button
                  className="text-truncate fw-bold"
                  onClick={() => {
                    setSelectedPerson(person);
                    setShowPersonModal(true);
                  }}
                >
                  {person?.person_name}
                </Button>
              ) : (
                <span className="text-truncate fw-bold">
                  {person?.person_name}
                </span>
              )}
            </TooltipSr>
          </div>
        );
      },
    },
    {
      name: "# related faces",
      id: "related_faces",
      selector: (row) => row.related_faces,
      sortable: false,
      cell: (row) => (
        <div
          style={{ display: "contents" }}
          onClick={() => showRelatedFaces(row.related_faces)}
        >
          <Button className="text-truncate fw-bold">
            {row.related_faces?.length}
          </Button>
        </div>
      ),
    },
    {
      name: "# recognized faces",
      id: "recognized_faces",
      selector: (row) => row.recognized_faces,
      sortable: false,
      cell: (row) => (
        <div
          style={{ display: "contents" }}
          onClick={() => showRelatedFaces(row.recognized_faces)}
        >
          {row.recognized_faces?.length ? (
            <Button className="text-truncate fw-bold">
              {row.recognized_faces?.length}
            </Button>
          ) : (
            <span></span>
          )}
        </div>
      ),
    },
    {
      name: "Camera",
      id: "camera_id",
      sortable: true,
      selector: (row) =>
        row.camera_id ? findCamera(row.camera_id).name : "N/A",
      cell: (row) => {
        const camera = row?.camera_id
          ? findCamera(row.camera_id)
          : { name: "N/A" };
        return (
          <div
            style={{ display: "contents" }}
            onClick={() => handlePersonTrackingClicked(row, null)}
          >
            <TooltipSr placement="top" title={camera?.name}>
              <span className="text-truncate fw-bold">{camera?.name}</span>
            </TooltipSr>
          </div>
        );
      },
    },

    {
      name: "Created",
      selector: (row) => row.created,
      id: "created",
      sortable: true,
      cell: (row) => (
        <TooltipSr
          placement="top"
          title={new Date(row["created"]).toLocaleString()}
        >
          <div onClick={() => handlePersonTrackingClicked(row, null)}>
            {new Date(row["created"]).toLocaleString()}
          </div>
        </TooltipSr>
      ),
    },
    {
      name: "Properties",
      id: "has_swap",
      selector: (row) => row.has_swap,
      sortable: false,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {row.has_swap && (
            <TooltipSr title="Has identity swap">
              <FaRetweet className="mx-1" />
            </TooltipSr>
          )}
          {row.has_vest && (
            <TooltipSr title="Has vest">
              <FaVest className="mx-1" />
            </TooltipSr>
          )}
          {row.has_helmet && (
            <TooltipSr title="Has helmet">
              <img
                className="mx-1"
                src={HelmetIcon}
                height="16px"
                width="16px"
                alt="credit to https://www.flaticon.com/free-icons/helmet"
              />
            </TooltipSr>
          )}
          {row.is_recognizable && (
            <TooltipSr title="Is recognizable">
              <FaCheckCircle className="mx-1" />
            </TooltipSr>
          )}
          {(row.should_notify_unknown || row.should_notify) && (
            <TooltipSr title="Should notify">
              <FaBell className="mx-1" />
            </TooltipSr>
          )}
          {!showUnion &&
            row.should_notify_unknown === false &&
            row.similar_unknown_track_id.trim() !== "" && (
              <TooltipSr
                title={
                  "Identical to the unknown track id " +
                  row.similar_unknown_track_id +
                  " with conf " +
                  row.similar_unknown_confidence
                }
              >
                <MdPeopleAlt className="mx-1" />
              </TooltipSr>
            )}
        </div>
      ),
    },
    {
      name: "Actions",
      id: "actions",
      selector: (row) => row.date_joined,
      sortable: false,
      cell: (row) =>
        row.is_superuser ? (
          <></>
        ) : (
          <div className="d-flex align-items-center">
            {isDeleting ? (
              <FaSpinner className="fa-spin" />
            ) : (
              <FaTrash
                color={COLORS.GROUP.LIGHT.red}
                className="pointer fs-6 "
                onClick={() => beforeDeleteEvent(row)}
              />
            )}
          </div>
        ),
    },
  ];

  return (
    <MainContainer>
      <div className="fs-4">People tracking events</div>
      <Modal
        show={showPersonModal}
        onHide={() => setShowPersonModal(false)}
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <PersonModal
          close={() => setShowPersonModal(false)}
          person={selectedPerson || {}}
          setPerson={setSelectedPerson}
        />
      </Modal>
      <Modal
        show={isShowFaceModal}
        onHide={() => setIsShowFaceModal(false)}
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <FaceRecognizedModal
          close={() => setIsShowFaceModal(false)}
          camera={selectedCamera}
          face={selectedFace}
          person={selectedPerson}
          group={selectedGroup}
          isUnknown={["", "N/A"].includes(selectedFace.person_id)}
        />
      </Modal>

      <Dialog open={showFacesWindow} onClose={() => setShowFacesWindow(false)}>
        <TrackingFacesModal
          relatedFaces={relatedFaces}
          close={() => setShowFacesWindow(false)}
          showFaceModal={showFaceModal}
        />
      </Dialog>
      <div
        className="d-flex gap-24 "
        style={{
          alignItems: "self-end",
        }}
      >
        <TextField
          id="standard-basic"
          label="Search by person tacking ID"
          variant="standard"
          onChange={(e) =>
            setFilters((current) => {
              return { ...current, id: e.target.value };
            })
          }
        />
        <Autocomplete
          options={peopleOptions}
          id="select-person-filter-people-tracking"
          disableCloseOnSelect
          defaultValue={peopleOptions.find(
            (o) => o.value === searchParams.get("personId")
          )}
          onChange={(e, option) =>
            setFilters({ recognized_persons: [option?.value] || [""] })
          }
          style={{
            width: "280px",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by recognized person"
              variant="standard"
            />
          )}
        />
        <div
          className="d-flex align-items-center gap-2 "
          style={{ width: "300px" }}
        >
          <SwitchCheckbox
            checked={showUnion}
            onChange={(e) => setShowUnion(e.target.checked)}
          />
          <label>Union identical events</label>
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        <FilterCheckboxElement
          classes="col d-flex align-items-center"
          name="has_related_faces"
          title="Has related faces"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          classes="col d-flex align-items-center"
          name="is_recognizable"
          title="Is recognizable"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          classes="col d-flex align-items-center"
          name="is_recognized"
          title="Is recognized"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          classes="col d-flex align-items-center"
          name="has_helmet"
          title="Has helmet"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          classes="col d-flex align-items-center"
          name="has_vest"
          title="Has vest"
          filters={filters}
          setFilters={setFilters}
        />
        <FilterCheckboxElement
          classes="col d-flex align-items-center"
          name="has_swap"
          title="Has identity swap"
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      {isFetching && (
        <Box className="text-center fs-4 my-3">
          <CircularProgress color="primary" />
        </Box>
      )}
      <div className="mt-5">
        <DataTable
          key={
            showUnion
              ? personTrackingEventsUnion?.length
              : personTrackingEvents?.length
          }
          columns={columns}
          data={showUnion ? personTrackingEventsUnion : personTrackingEvents}
          pagination
          responsive={true}
          paginationServer={true}
          highlightOnHover
          paginationPerPage={paginationPerPage}
          paginationTotalRows={showUnion ? totalRowsUnion : totalRows}
          paginationRowsPerPageOptions={[MIN_PAGE_SIZE, 50, 100]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
            setPaginationPerPage(currentRowsPerPage)
          }
          onChangePage={(currentPage) => {
            setPage(currentPage);
          }}
          sortIcon={<FaSortAmountUpAlt />}
          noDataComponent={
            isFetching ? "Loading events..." : "No people tracking found"
          }
          theme="solarized"
          customStyles={{ rows: { style: { cursor: "pointer" } } }}
          sortServer
          sortFunction={(column, sortDirection, event) => {
            setOrdering(sortDirection === "asc" ? column.id : `-${column.id}`);
          }}
          fixedHeader={true}
          fixedHeaderScrollHeight={"100vh"}
        />
      </div>
    </MainContainer>
  );
}

export default PersonTracking;
