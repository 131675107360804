import React from "react";
import Controller from "./Controller";

function AdditionalControllers(props) {
  const { camera, setCamera, groupsOptions } = props;

  const changeControllerValue = (index, key, value) => {
    var tempControllers = [...camera.additional_controllers];
    tempControllers[index][key] = value;
    setCamera({ ...camera, additional_controllers: tempControllers });
  };
  const removeController = (index) => {
    var tempControllers = [...camera.additional_controllers];
    tempControllers[index] = null;
    setCamera({
      ...camera,
      additional_controllers: tempControllers.filter((c) => c),
    });
  };

  const addController = () => {
    var tempControllers = camera.additional_controllers
      ? [...camera.additional_controllers]
      : [];
    tempControllers.push({
      type: "send_url",
      groups: [],
      is_door: true,
    });
    setCamera({ ...camera, additional_controllers: tempControllers });
  };

  return (
    <div
      className="mx-3"
      style={{
        fontSize: "12px",
      }}
    >
      {camera.additional_controllers &&
        camera.additional_controllers.map((controller, i) => (
          <Controller
            index={i}
            key={`ctrl-${i}`}
            controller={controller}
            groupsOptions={groupsOptions}
            changeControllerValue={changeControllerValue}
            removeController={removeController}
          />
        ))}
      <div className="col-3 mx-auto mb-3">
        <button className="sr-btn" onClick={addController}>
          Add controller
        </button>
      </div>
    </div>
  );
}

export default AdditionalControllers;
