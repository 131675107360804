import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function TooltipSr(props) {
  const { children, title, placement } = props;
  return (
    <OverlayTrigger
      placement={placement || "top"}
      overlay={<Tooltip>{title}</Tooltip>}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
}

export default TooltipSr;
