import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import { deleteItem, get, post, put } from "../faceit-api/FaceitApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PEOPLE_MAX_PAGE } from "./personsContext";
import { Group, GroupUpdate } from "../types/Group";

type UpdateGroupProps = {
  groupId: string;
  groupData?: GroupUpdate;
  onSuccessCallback: (group: Group) => void;
  onErrorCallback: (resp: any) => void;
};

async function getGroups(): Promise<Group[]> {
  return get(
    `groups?${new URLSearchParams({ page_size: PEOPLE_MAX_PAGE.toString() }).toString()}`
  ).then((resp) => {
    return resp?.results || [];
  });
}
async function updateGroup({ groupId, groupData }: UpdateGroupProps) {
  return put(`groups/${groupId}`, groupData);
}

async function createGroup({ groupData }: UpdateGroupProps) {
  return post(`groups`, groupData);
}

async function deleteGroup({ groupId }: UpdateGroupProps) {
  return deleteItem(`groups/${groupId}`);
}

export const useApiGroupsData = () => {
  return useQuery("getGroups", getGroups, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

export const useApiUpdateGroups = () => {
  const queryClient = useQueryClient();
  return useMutation(updateGroup, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        const newGroup = resp;
        return onSuccessCallback(newGroup);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["getGroups"] }),
  });
};

export const useApiCreateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(createGroup, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["getGroups"] }),
  });
};

export const useApiDeleteGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteGroup, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["getGroups"] });
      queryClient.invalidateQueries({ queryKey: ["getPersons"] });
    },
  });
};
