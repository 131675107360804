import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSpinner, FaUsers } from "react-icons/fa";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";
import PeopleCreateGroupPopup from "./PeopleCreateGroupPopup";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { GroupCard } from "./GroupCard";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiGroupsData } from "../../context/groupContext";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { useIsMobile } from "../../utils/hooks";
import { Group } from "../../types/Group";
import { Flex } from "../elements/flex/Flex";

export function Groups() {
  const { userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();
  const { isFetching: isFetchingPersons } = useApiPersonsData();
  const { data: groups } = useApiGroupsData();
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [showGroups, setShowGroups] = useState<Group[]>([]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setShowGroups(groups || []);
    setTotalRows(groups?.length || 0);
  }, [groups]);

  const groupsOptions =
    groups?.map((g) => {
      return {
        label: g.name,
        value: g.id,
      };
    }) || [];

  const onFilter = (selectedGroups: string[]) => {
    var groupsToSet = [...(groups || [])];

    if (selectedGroups.length > 0) {
      groupsToSet = groupsToSet.filter((p) => selectedGroups.includes(p.id));
    }
    setTotalRows(groupsToSet.length);
    setShowGroups(groupsToSet);
  };

  const animatedComponents = makeAnimated();

  return (
    <Flex column padding="20px">
      <Modal
        show={showAddGroupModal}
        onHide={() => setShowAddGroupModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        fullscreen={"md-down"}
      >
        <PeopleCreateGroupPopup close={() => setShowAddGroupModal(false)} />
      </Modal>
      <Flex
        column={isMobile}
        gap="8px"
        align={isMobile ? "start" : "center"}
        justify={isMobile ? "center" : "between"}
        style={{ color: COLORS.FontGray }}
      >
        <div
          className={`d-flex justify-content-center ${isMobile ? "w-100" : ""}`}
        >
          <SecondaryButton
            className={`text-uppercase ${isMobile ? "w-100" : ""}`}
            onClick={() => setShowAddGroupModal(true)}
            disabled={!userInfo?.is_staff}
          >
            <FaUsers className="me-2" />
            Add group
          </SecondaryButton>
        </div>

        <div
          className={`d-flex align-items-center gap-2 ${
            isMobile ? "w-100 justify-content-between" : ""
          }`}
        >
          <div style={{ width: isMobile ? "100%" : "200px" }}>
            <Select
              className="react-select-container w-100"
              classNamePrefix="react-select"
              placeholder="Filter by group"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={groupsOptions}
              onChange={(options) => {
                onFilter(options.map((o: any) => o.value.trim()));
              }}
            />
          </div>
        </div>
        <div
          className="d-flex gap-2 justify-content-center"
          style={{
            fontSize: "14px",
          }}
        >
          <span>Groups count:</span>
          <strong>{totalRows}</strong>
        </div>
      </Flex>
      <hr style={{ height: "2px", color: COLORS.FontGray }} />
      {isFetchingPersons && (
        <div className="text-center mt-2">
          <FaSpinner
            style={{ color: COLORS.Gray50 }}
            className="fa-spin mb-2 fs-3"
          />
        </div>
      )}

      <Flex column gap="16px">
        {showGroups.map((group) => (
          <GroupCard group={group} key={group.id} />
        ))}
      </Flex>
    </Flex>
  );
}
