import styled from "styled-components";
import { BaseButton } from "./BaseButton";
import { COLORS } from "../../../assets/colors";

type SecondaryButtonProps = {
  variant?: "secondary" | "danger";
};

export const SecondaryButton = styled(BaseButton)<SecondaryButtonProps>`
  background: ${COLORS.Gray800};
  border: 1px solid
    ${(props) => (props.variant === "danger" ? COLORS.Gray600 : COLORS.Gray600)};
  &:hover:enabled {
    border: 1px solid
      ${(props) =>
        props.variant === "danger"
          ? COLORS.red
          : COLORS.PrimaryLight} !important;
    background: ${(props) =>
      props.variant === "danger"
        ? COLORS.GROUP.LIGHT.red
        : COLORS.PrimaryLight} !important;
  }
`;
