import React, { useContext, useState, useCallback } from "react";
import { DataContext } from "../../App";
import JSMpeg from "@cycjimmy/jsmpeg-player";
import { FaRegCirclePlay } from "react-icons/fa6";
import { COLORS } from "../../assets/colors";

function StreamPlayer(props) {
  const { width, height, camera } = props;
  const { webSettings } = useContext(DataContext);
  const elementId = `sr_rtsp_video_${camera.id}`;
  const [player, setPlayer] = useState(null);

  const startStream = useCallback(() => {
    if (!camera || !camera.rtsp_stream_enabled || player) return;

    const runPlayer = (rtsp_port, camera_hybrid_host) => {
      var host = window.location.host;
      if (webSettings?.is_hybrid) {
        host = camera_hybrid_host;
      }
      if (!rtsp_port) {
        rtsp_port = 8080 + parseInt(camera.id);
      }
      const websocket = `wss://${host}/rtsp-proxy/${camera.id}/${rtsp_port}/`;

      const newPlayer = new JSMpeg.Player(websocket, {
        canvas: document.getElementById(elementId),
      });
      setPlayer(newPlayer);
    };

    try {
      runPlayer(camera.proxy_port, camera.hybrid_host);
    } catch (err) {
      console.log("Failed in stream connection", err);
    }
  }, [camera, webSettings, elementId, player]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <canvas
        id={elementId}
        className="sr_rtsp_video"
        style={{
          width: width,
          height: height,
          borderRadius: "4px",
          backgroundColor: "black",
        }}
      ></canvas>
      {!player && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: COLORS.FontGray,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaRegCirclePlay
            onClick={startStream}
            size={"50%"}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default StreamPlayer;
