import React from "react";
import { COLORS } from "../../assets/colors";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import StreamPlayer from "../cameras/StreamPlayer";
import { useApiCamerasData } from "../../context/camerasContext";

function LiveViewCameras(props) {
  const { selectedCameras, setSelectedCameras, setSearchParams } = props;
  const { data: cameras } = useApiCamerasData();

  const camerasOptions = cameras.map((camera) => {
    return {
      label: camera.name,
      value: camera.id,
    };
  });

  const animatedComponents = makeAnimated();

  return (
    <div
      className="p-3"
      style={{
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>Select cameras to view, limited to 4 cameras</Tooltip>
        }
      >
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-4" style={{ fontSize: "14px" }}>
            Choose cameras to display:
          </div>
          <div className="col-12 col-lg-8">
            {" "}
            <Select
              className="react-select-container w-100"
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              value={selectedCameras}
              options={camerasOptions}
              onChange={(options) => {
                if (selectedCameras.length > 3) return;
                setSelectedCameras(options);
                setSearchParams({
                  cameraId: options.map((c) => c.value).join(","),
                });
              }}
              theme="dark"
            />
          </div>
        </div>
      </OverlayTrigger>
      <div
        className="row mt-2"
        style={{
          height: "550px",
        }}
      >
        {cameras
          .filter((camera) =>
            selectedCameras.map((c) => c.value).includes(camera.id)
          )
          .slice(0, 4)
          .map((camera) => (
            <div
              key={`cam-sel-${camera.id}`}
              className={`px-0 mx-0  col-12 ${
                selectedCameras.length > 1 ? "col-lg-6" : ""
              }`}
              ref={(node) => {
                if (node) {
                  node.style.setProperty("padding-left", "10px", "important");
                  node.style.setProperty("padding-right", "10px", "important");
                }
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                }}
                className="fw-bolder"
              >
                {camera.name}
              </div>
              <div className={``}>
                <StreamPlayer
                  width="100%"
                  height={selectedCameras.length === 1 ? "520px" : "240px"}
                  camera={camera}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default LiveViewCameras;
