import React, { useState } from "react";
import { put } from "../../faceit-api/FaceitApi";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2/src/sweetalert2";
import { FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";

export function EditAccountModal({
  userToEdit,
  close,
  integratorsList,
  integratorAdmin,
}) {
  const [username, setUsername] = useState(userToEdit.username);
  const [email, setEmail] = useState(userToEdit.email);
  const [first_name, setFirstName] = useState(userToEdit.first_name);
  const [last_name, setLastName] = useState(userToEdit.last_name);
  const [isStaff, setIsStaff] = useState(userToEdit.is_staff);
  const [isIntegratorAdmin, setIsIntegratorAdmin] = useState(
    userToEdit.is_integrator_admin || false
  );
  const [integrator, setIntegrator] = useState(userToEdit.integrator || "");
  const [integratorOption, setIntegratorOption] = useState(
    integratorsList.map((i) => ({ value: i, label: i }))
  );

  const checkBefore = () => {
    if (!username) {
      return "Username cannot be empty";
    }
    if (isStaff && isIntegratorAdmin) {
      return `User cannot be both "Staff" and "Integrator Admin"`;
    }
    return "ok";
  };

  const UpdateUser = () => {
    var data = {
      username: username,
      first_name: first_name,
      last_name: last_name,
      email: email,
      is_staff: isStaff,
      integrator: integrator,
      is_integrator_admin: isIntegratorAdmin,
    };
    console.log("to update data", data);
    put(`users/${userToEdit.id}`, data)
      .then((resp) => {
        if (resp.status === 1) {
          Swal.fire({
            icon: "success",
            title: "Awesome 🥳",
            text: "User has been update successfully",
          }).then(() => {
            window.location.reload();
            close();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ooops... 😕",
            text: resp.message || "Failed to update user",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Ooops... 😕",
          text: "Failed to update user",
        });
      });
  };

  const handleNewIntegrator = (newValue) => {
    if (!integratorAdmin) {
      Swal.fire({
        title: `Create New Integrator "${newValue}"?`,
        text: `Only Tenant Admins can create new integrators...`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setIntegrator(newValue);
          setIntegratorOption([
            ...integratorOption,
            { value: newValue, label: newValue },
          ]);
        } else {
          setIntegrator("");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Ooops... 😕",
        text: "You are not aloud to create new integrator",
      }).then(() => setIntegrator(""));
    }
  };

  return (
    <div
      className="align-items-center justify-content-center p-3 gap-2"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <div className="d-flex  px-2 align-items-center justify-content-between">
        <div
          className="d-flex"
          style={{
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          <div className="d-flex align-items-center fw-bold">Update user</div>
        </div>
        <div className="d-flex pointer" onClick={close}>
          <FaTimes className="fs-3" />
        </div>
      </div>
      <hr />
      <div className="">
        <div className="d-flex align-items-center mb-2 ">
          <label className="col-5 col-lg-3 text-start">Username: </label>
          <input
            type="text"
            className="sr-text-input mt-1"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center mb-2 ">
          <label className="col-5 col-lg-3 text-start">First Name: </label>
          <input
            type="text"
            className="sr-text-input mt-1"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center mb-2 ">
          <label className="col-5 col-lg-3 text-start">Last Name: </label>
          <input
            type="text"
            className="sr-text-input mt-1"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center mb-2 ">
          <label className="col-5 col-lg-3 text-start">Email:</label>
          <input
            type="text"
            className="sr-text-input mt-1"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center mb-2 ">
          <label className="col-5 col-lg-3 text-start">Integrator: </label>
          <CreatableSelect
            className="react-select-container mt-1 w-100"
            classNamePrefix="react-select"
            isClearable
            value={integratorOption.find((i) => i.value === integrator) || ""}
            onChange={(newValue) => {
              if (newValue) {
                setIntegrator(newValue.value);
                console.log(newValue.value);
              } else {
                setIntegrator("");
              }
            }}
            options={integratorOption}
            onCreateOption={handleNewIntegrator}
          />
        </div>
        {integrator || integratorAdmin ? (
          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-4 text-start text-truncate">
              Integrator Admin:{" "}
            </label>
            <input
              type="checkbox"
              className=" mt-1"
              checked={isIntegratorAdmin}
              onChange={(e) => setIsIntegratorAdmin(e.target.checked)}
            />
          </div>
        ) : (
          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">Staff: </label>
            <input
              type="checkbox"
              className=" mt-1"
              checked={isStaff}
              onChange={(e) => setIsStaff(e.target.checked)}
            />
          </div>
        )}
      </div>
      <div>
        {checkBefore() !== "ok" && (
          <div className="text-danger my-2">{checkBefore()}</div>
        )}
      </div>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary"
          style={{
            backgroundColor: "#176766",
            color: "#e5e5e5",
            borderWidth: "0px",
          }}
          onClick={UpdateUser}
          disabled={checkBefore() !== "ok"}
        >
          Update
        </button>
      </div>
    </div>
  );
}
