import React, { useState } from "react";
import { FaCloudUploadAlt, FaSpinner } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { importPersons } from "../../faceit-api/FaceitApi";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";

function ImportPeoplePopup(props) {
  const { close, setIsImporting, isImporting } = props;
  const [importData, setImportData] = useState(null);
  const [filename, setFilename] = useState("");

  const handleImportFileChange = (e) => {
    e.preventDefault();
    // const reader = new FileReader();
    // reader.onload = (resEvent) => {
    //   var block = reader.result.split(";");
    //   var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    //   setImportData(realData);
    // };
    // reader.onerror = (error) => console.error(error);
    // reader.readAsDataURL(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setImportData(e.target.files[0]);
  };

  const handleImport = () => {
    if (!importData) {
      return;
    } else {
      setIsImporting(true);
      const formData = new FormData();
      formData.append("File", importData);

      importPersons(importData)
        .then((resp) => {
          if (resp.status === 1) {
            showSuccessDialog("People list imported successfully");
            document.location.reload();
          } else {
            showErrorDialog("Failed to import people list");
          }
        })
        .catch((err) => {
          console.error(err);
          showErrorDialog("Failed to import people list");
        })
        .finally(() => setIsImporting(false));
    }
  };

  return (
    <div className="p-4 text-center" style={{ color: COLORS.FontGray }}>
      <p className="text-center">
        Select a zip file that was previously exported from Face-it
      </p>
      {filename && <div className="text-center my-1">{filename}</div>}
      <div className="form-group mt-2">
        <label className="custom-file-upload">
          <input
            type="file"
            multiple="false"
            onChange={handleImportFileChange}
            accept="application/zip"
          />
          {isImporting ? (
            <FaSpinner className="me-md-3 d-none d-md-inline fa-spin" />
          ) : (
            <FaCloudUploadAlt className="me-md-3 d-none d-md-inline" />
          )}
          Select exported ZIP file
        </label>
      </div>
      <div className="row mt-2 d-flex align-items-center justify-content-around">
        <button className="sr-outline-btn mt-2 col-12 col-md-4" onClick={close}>
          Cancel
        </button>
        <button
          className="sr-btn mt-2 col-12 col-md-4"
          disabled={!importData || isImporting}
          onClick={handleImport}
        >
          Import
        </button>
      </div>
    </div>
  );
}

export default ImportPeoplePopup;
