import React from "react";
import { COLORS } from "../../../assets/colors";

export function MenuPopup(props) {
  const { items = [] } = props;
  return (
    <div
      className="d-flex h-100 flex-column gap-8 p-1"
      style={{
        color: COLORS.FontGray,
        boxShadow: "1px 1px 10px rgba(229, 229, 229, 0.5)",
      }}
    >
      {items.map((item, i) => (
        <>
          {item}
          {i < items.length - 1 && (
            <div style={{ borderBottom: `1px solid ${COLORS.Gray600}` }}></div>
          )}
        </>
      ))}
    </div>
  );
}
