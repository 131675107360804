import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { Login } from "./components/login/Login";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./assets/styles/muiTheme";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function LoginRoute() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="*"
            element={
              <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </ThemeProvider>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default LoginRoute;
