import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";
import Select from "react-select";
import { get, put } from "../../faceit-api/FaceitApi";
import NotificationsTimeRanges from "./NotificationsTimeRanges";
import {
  FaChevronDown,
  FaChevronRight,
  FaGlobe,
  FaSave,
  FaSpinner,
  FaTelegramPlane,
} from "react-icons/fa";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { MdEmail } from "react-icons/md";
import NotificationsEmails from "./NotificationsEmails";
import NotificationsTelegram from "./NotificationsTelegram";
import NotificationsWeb from "./NotificationsWeb";
import { TimeZones } from "../../assets/time-zones";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useApiGroupsData } from "../../context/groupContext";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import SystemAlerts from "./SystemAlerts";
import TooltipSr from "../general/TooltipSr";
import { Flex } from "../elements/flex/Flex";

const timesOptions = [
  {
    label: "Disable",
    value: "disable",
  },
  {
    label: "All day",
    value: "all_day",
  },
  {
    label: "Time Ranges",
    value: "time_range",
  },
];

const timeZoneOptions = TimeZones.sort().map((tz) => {
  return { label: tz, value: tz };
});

const channels = [
  {
    key: "emails",
    name: "Email",
    icon: <MdEmail className="me-2" />,
  },
  {
    key: "telegram",
    name: "Telegram",
    icon: <FaTelegramPlane className="me-2" />,
  },
  {
    key: "web",
    name: "Web Push",
    icon: <FaGlobe className="me-2" />,
  },
];

function NotificationsSettings() {
  const { webSettings, userInfo } = useContext(DataContext);
  const { data: groups, isFetching: isFetchingGroups } = useApiGroupsData();
  const [notificationsSettings, setNotificationsSettings] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showGroupNotifications, setShowGroupNotifications] = useState(true);
  const [showGeneralNotifications, setShowGeneralNotifications] =
    useState(false);
  const [showChannelNotifications, setShowChannelNotifications] =
    useState(false);

  useEffect(() => {
    setNotificationsSettings((state) => {
      return {
        ...state,
        ...{
          frames_for_unknown: webSettings?.frames_for_unknown,
          unknowns_expration: webSettings?.unknowns_expration,
        },
      };
    });
  }, [webSettings]);

  useEffect(() => {
    get(`notifications-settings`).then((resp) => {
      if (resp.status === 1) {
        let tempNotificationsSettings = resp.results[0];
        if (!tempNotificationsSettings.notify_groups) {
          tempNotificationsSettings.notify_groups = [];
        }

        if (groups.length !== 0) {
          const tempNotificationsSettingsGroups =
            tempNotificationsSettings.notify_groups.map((np) => np.group_id);
          groups.forEach((group) => {
            if (!tempNotificationsSettingsGroups.includes(group.id)) {
              tempNotificationsSettings.notify_groups.push({
                group_id: group.id,
                active_type: "disable",
              });
            }
          });
          if (!tempNotificationsSettingsGroups.includes("unidentified")) {
            tempNotificationsSettings.notify_groups.push({
              group_id: "unidentified",
              active_type: "disable",
            });
          }
        }
        if (tempNotificationsSettings.web_notification_url) {
          if (
            tempNotificationsSettings.web_notification_urls &&
            !tempNotificationsSettings.web_notification_urls.includes(
              tempNotificationsSettings.web_notification_url
            )
          ) {
            tempNotificationsSettings.web_notification_urls.push(
              tempNotificationsSettings.web_notification_url
            );
          } else if (!tempNotificationsSettings.web_notification_urls) {
            tempNotificationsSettings.web_notification_urls = [
              tempNotificationsSettings.web_notification_url,
            ];
          }
        }

        setNotificationsSettings(tempNotificationsSettings);
      }
    });
  }, [groups]);

  const saveChanges = () => {
    var tempSettings = { ...notificationsSettings };
    if (!tempSettings.web_notification_urls) {
      tempSettings.web_notification_urls = [];
    }
    if (!tempSettings.emails) {
      tempSettings.emails = [];
    }
    if (!tempSettings.extra_urls) {
      tempSettings.extra_urls = [];
    }
    if (!tempSettings.notify_groups) {
      tempSettings.notify_groups = [];
    }
    if (!tempSettings.webhook_edge_sync_notifications) {
      tempSettings.webhook_edge_sync_notifications = "";
    }

    tempSettings.web_notification_urls =
      tempSettings.web_notification_urls.filter(
        (u) => u !== tempSettings.web_notification_url
      );

    setIsSaving(true);
    put(`notifications-settings/${tempSettings.tenant_id}`, tempSettings)
      .then((resp) => {
        if (resp.status === 1) {
          showSuccessDialog("Notifications settings updated successfully").then(
            () => window.location.reload()
          );
        } else {
          showErrorDialog("Failed to save changes");
        }
      })
      .finally(() => setIsSaving(false));
  };

  const changeNotifyGroup = (key, value, group_id) => {
    let tempNotifyGroups = [...notificationsSettings.notify_groups];
    tempNotifyGroups[tempNotifyGroups.map((g) => g.group_id).indexOf(group_id)][
      key
    ] = value;
    setNotificationsSettings({
      ...notificationsSettings,
      notify_groups: tempNotifyGroups,
    });
  };

  return (
    <div
      style={{
        color: COLORS.FontGray,
      }}
    >
      {isFetchingGroups && (
        <div className="my-5 d-flex justify-content-center">
          <FaSpinner className="fs-2 fa-spin" />
        </div>
      )}
      <div
        className="d-flex justify-content-start align-items-center pointer"
        onClick={() => setShowGroupNotifications(!showGroupNotifications)}
      >
        {showGroupNotifications ? (
          <FaChevronDown className="me-3 fs-4" />
        ) : (
          <FaChevronRight className="me-3 fs-4" />
        )}
        <div>
          <div className="fs-6 fw-bold">Group notifications</div>
          <div style={{ fontSize: "12px" }}>
            For each group, set the times when notifications for face
            recognition events will be triggered
          </div>
        </div>
      </div>

      {showGroupNotifications && (
        <div
          className="mt-4 ms-5 px-3 py-2"
          style={{
            backgroundColor: COLORS.Gray700,
            borderRadius: "8px",
          }}
        >
          {notificationsSettings.notify_groups &&
            notificationsSettings.notify_groups.map((notify_group) => (
              <div
                key={`notfy-group-${notify_group.group_id}`}
                className="row d-flex  my-2"
              >
                <div className="col-12 col-md-2 col-xl-1">
                  {notify_group.group_id === "unidentified"
                    ? "Unknowns"
                    : groups.find((g) => g.id === notify_group.group_id)?.name}
                </div>
                <div className="col-12 col-md-6 col-xl-5">
                  <Select
                    className="react-select-container col-12 col-lg-8 px-0"
                    classNamePrefix="react-select"
                    placeholder="Select or write new name..."
                    options={timesOptions}
                    value={timesOptions.find(
                      (o) => o.value === notify_group.active_type
                    )}
                    onChange={(e) =>
                      changeNotifyGroup(
                        "active_type",
                        e.value,
                        notify_group.group_id
                      )
                    }
                    theme="dark"
                  />
                  {notify_group.active_type === "time_range" && (
                    <NotificationsTimeRanges
                      notificationsSettings={notificationsSettings}
                      changeNotifyGroup={changeNotifyGroup}
                      notify_group={notify_group}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      )}

      <div
        className="d-flex justify-content-start align-items-center pointer  mt-5"
        onClick={() => setShowChannelNotifications(!showChannelNotifications)}
      >
        {showChannelNotifications ? (
          <FaChevronDown className="me-3 fs-4" />
        ) : (
          <FaChevronRight className="me-3 fs-4" />
        )}
        <div>
          <div className="fs-6 fw-bold">Notification Channels</div>
          <div style={{ fontSize: "12px" }}>
            Select the alert channels that will be activated for face
            recognition events
          </div>
        </div>
      </div>

      {showChannelNotifications && (
        <div
          className="mt-4 ms-5 px-3 py-2"
          style={{
            backgroundColor: COLORS.Gray700,
            borderRadius: "8px",
          }}
        >
          {channels.map((channel) => (
            <div
              key={`notfy-channel-${channel.key}`}
              className="row d-flex align-items-start  my-2"
            >
              <div className="col-12 col-md-2 col-xl-2 d-flex align-items-center">
                {channel.icon}
                {channel.name}
              </div>
              <div className="col-12 col-md-6 col-xl-5">
                <SwitchCheckbox
                  checked={notificationsSettings["enable_" + channel.key]}
                  onChange={(e) =>
                    setNotificationsSettings({
                      ...notificationsSettings,
                      ["enable_" + channel.key]: e.target.checked,
                    })
                  }
                />
                {notificationsSettings["enable_" + channel.key] &&
                  channel.key === "emails" && (
                    <NotificationsEmails
                      notificationsSettings={notificationsSettings}
                      setNotificationsSettings={setNotificationsSettings}
                    />
                  )}
                {notificationsSettings["enable_" + channel.key] &&
                  channel.key === "telegram" && (
                    <NotificationsTelegram
                      notificationsSettings={notificationsSettings}
                      setNotificationsSettings={setNotificationsSettings}
                    />
                  )}
                {notificationsSettings["enable_" + channel.key] &&
                  channel.key === "web" && (
                    <NotificationsWeb
                      notificationsSettings={notificationsSettings}
                      setNotificationsSettings={setNotificationsSettings}
                    />
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="pb-5">
        <div
          className="d-flex justify-content-start align-items-center pointer  mt-5"
          onClick={() => setShowGeneralNotifications(!showGeneralNotifications)}
        >
          {showGeneralNotifications ? (
            <FaChevronDown className="me-3 fs-4" />
          ) : (
            <FaChevronRight className="me-3 fs-4" />
          )}
          <div>
            <div className="fs-6 fw-bold">General</div>
            <div style={{ fontSize: "12px" }}>
              General settings for the system such as filtering identical
              notifications, unknowns definition and system alerts
            </div>
          </div>
        </div>

        {showGeneralNotifications && (
          <div
            className="mt-4 ms-5 px-3 py-2 mb-2 d-flex flex-column gap-8"
            style={{
              backgroundColor: COLORS.Gray700,
              borderRadius: "8px",
            }}
          >
            <div className="d-flex gap-8 align-items-center">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    In order to reduce noise, an identification event of same
                    person will be filtered and no further notification will be
                    sent
                  </Tooltip>
                }
              >
                <div
                  style={{
                    width: "320px",
                  }}
                >
                  Minimum seconds between identical alerts
                </div>
              </OverlayTrigger>

              <input
                className="sr-text-input"
                placeholder="5..."
                type="number"
                value={notificationsSettings.filter_events}
                onChange={(e) =>
                  setNotificationsSettings({
                    ...notificationsSettings,
                    filter_events: parseInt(e.target.value),
                  })
                }
                style={{
                  width: "50px",
                }}
                theme="dark"
              />
            </div>

            <div className="d-flex gap-8 align-items-center">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    The time zone of the event, will affect the time that will
                    appear in the content of the notification
                  </Tooltip>
                }
              >
                <div
                  style={{
                    width: "320px",
                  }}
                >
                  Time zone
                </div>
              </OverlayTrigger>
              <div
                style={{
                  width: "300px",
                }}
              >
                <Select
                  className="react-select-container col-12 px-0"
                  classNamePrefix="react-select"
                  placeholder="Select or time zone..."
                  options={timeZoneOptions}
                  value={timeZoneOptions.find(
                    (tz) => tz.value === notificationsSettings.country
                  )}
                  onChange={(e) =>
                    setNotificationsSettings({
                      ...notificationsSettings,
                      country: e.value,
                    })
                  }
                  theme="dark"
                />
              </div>
            </div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  In order to get notifications about unfamiliar faces
                  (unknowns), a threshold must be set for the number of frames
                  in which the face is not identified within a certain period of
                  time
                </Tooltip>
              }
            >
              <div
                className="fw-bolder w-25"
                style={{ textDecoration: "underline" }}
              >
                Unknowns Definition:
              </div>
            </OverlayTrigger>
            <Flex gap="16px">
              <Flex
                style={{
                  width: "1px",
                  height: "40px",
                  backgroundColor: COLORS.Gray500,
                }}
              ></Flex>

              <Flex column gap="8px">
                <div className="d-flex align-items-center">
                  <div className="d-flex">Frames without recognition:</div>
                  <input
                    className="sr-text-input d-flex mx-2"
                    style={{ width: "50px" }}
                    type="number"
                    value={notificationsSettings.frames_for_unknown}
                    onChange={(e) =>
                      setNotificationsSettings({
                        ...notificationsSettings,
                        frames_for_unknown: parseInt(e.target.value),
                      })
                    }
                    theme="dark"
                  />
                  <div className="d-flex">Reset after</div>
                  <input
                    className="sr-text-input d-flex mx-2"
                    style={{ width: "50px" }}
                    type="number"
                    value={notificationsSettings.unknowns_expration}
                    onChange={(e) =>
                      setNotificationsSettings({
                        ...notificationsSettings,
                        unknowns_expration: parseInt(e.target.value),
                      })
                    }
                    theme="dark"
                  />
                  <div className="d-flex">seconds</div>
                </div>
              </Flex>
            </Flex>
            <div
              className="fw-bolder w-25"
              style={{ textDecoration: "underline" }}
            >
              Tracking Settings:
            </div>
            <Flex gap="16px">
              <Flex
                style={{
                  width: "1px",
                  height: "180px",
                  backgroundColor: COLORS.Gray500,
                }}
              ></Flex>
              <Flex column gap="8px">
                <div className="d-flex gap-8 align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        In order to avoid false notifications, we recommend to
                        wait a minute or more before sending a notification
                        about an unknown person
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        width: "320px",
                      }}
                    >
                      Tracking wait time before notify unknown
                    </div>
                  </OverlayTrigger>

                  <input
                    className="sr-text-input"
                    placeholder="60"
                    type="number"
                    value={
                      notificationsSettings.time_wait_before_notify_unknown ??
                      60
                    }
                    onChange={(e) =>
                      setNotificationsSettings({
                        ...notificationsSettings,
                        time_wait_before_notify_unknown: parseInt(
                          e.target.value
                        ),
                      })
                    }
                    style={{
                      width: "50px",
                    }}
                    theme="dark"
                  />
                </div>
                <div className="d-flex gap-8 align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        In order to reduce noise, an person-tracking event of
                        similar person will be filtered base on the threshold
                        similarity set
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        width: "320px",
                      }}
                    >
                      Tracking similarity grade
                    </div>
                  </OverlayTrigger>

                  <input
                    className="sr-text-input"
                    placeholder="70.."
                    type="number"
                    value={
                      notificationsSettings.person_tracking_unknowns_similarity_threshold ??
                      65
                    }
                    onChange={(e) =>
                      setNotificationsSettings({
                        ...notificationsSettings,
                        person_tracking_unknowns_similarity_threshold: parseInt(
                          e.target.value
                        ),
                      })
                    }
                    style={{
                      width: "50px",
                    }}
                    theme="dark"
                  />
                </div>
                <div className="d-flex gap-8 align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        In order to reduce noise, an person-tracking event of
                        similar person will be filtered for the minuets set
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        width: "320px",
                      }}
                    >
                      Tracking union expiration time (minuets)
                    </div>
                  </OverlayTrigger>

                  <input
                    className="sr-text-input"
                    placeholder="5..."
                    type="number"
                    value={
                      notificationsSettings.person_tracking_union_expiration_time !==
                      null
                        ? notificationsSettings.person_tracking_union_expiration_time /
                          60
                        : 5
                    }
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      const valueInSec = value * 60;
                      setNotificationsSettings({
                        ...notificationsSettings,
                        person_tracking_union_expiration_time: valueInSec,
                      });
                    }}
                    style={{
                      width: "50px",
                    }}
                    theme="dark"
                  />
                </div>
                <div className="d-flex gap-8 align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        In order to increase the confidante of the alert, you
                        can set a minimum number of events that must be received
                        before sending an alert
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        width: "320px",
                      }}
                    >
                      Minimum events for alert
                    </div>
                  </OverlayTrigger>

                  <input
                    className="sr-text-input"
                    placeholder="4..."
                    type="number"
                    value={notificationsSettings.min_events_for_alert ?? 4}
                    onChange={(e) =>
                      setNotificationsSettings({
                        ...notificationsSettings,
                        min_events_for_alert: parseInt(e.target.value),
                      })
                    }
                    style={{
                      width: "50px",
                    }}
                    theme="dark"
                  />
                </div>
              </Flex>
            </Flex>

            <div className="d-flex align-items-center my-2 gap-8">
              <TooltipSr title="Please set up a webhook to receive notifications for edge synchronization updates.">
                <div
                  style={{
                    width: "320px",
                  }}
                >
                  Webhook for synchronization updates
                </div>
              </TooltipSr>
              <input
                className="sr-text-input"
                placeholder="https://example.com/..."
                type="text"
                value={notificationsSettings.webhook_edge_sync_notifications}
                onChange={(e) =>
                  setNotificationsSettings({
                    ...notificationsSettings,
                    webhook_edge_sync_notifications: e.target.value,
                  })
                }
                style={{
                  width: "300px",
                }}
                theme="dark"
              />
            </div>
            <div className="d-flex align-items-center my-2 gap-8">
              <TooltipSr title="URL for the server that creates video calls.">
                <div
                  style={{
                    width: "320px",
                  }}
                >
                  URL for video call
                </div>
              </TooltipSr>
              <input
                className="sr-text-input"
                placeholder="https://example.com/..."
                type="text"
                value={notificationsSettings.video_call_server}
                onChange={(e) =>
                  setNotificationsSettings({
                    ...notificationsSettings,
                    video_call_server: e.target.value,
                  })
                }
                style={{
                  width: "300px",
                }}
                theme="dark"
              />
            </div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  List of URLs that will receive push notifications about system
                  events, such as: hot CPU, high CPU, high memory usage, no disk
                  space, services unavailable, etc.
                </Tooltip>
              }
            >
              <div
                className="fs-6 fw-bold mt-2"
                style={{ width: "fit-content", textDecoration: "underline" }}
              >
                System alerts
              </div>
            </OverlayTrigger>

            <div className="col-12 col-md-6 col-xl-5 mb-4">
              <SystemAlerts
                notificationsSettings={notificationsSettings}
                setNotificationsSettings={setNotificationsSettings}
              />
            </div>
          </div>
        )}
      </div>
      {userInfo?.is_staff && (
        <div
          style={{
            position: "fixed",
            bottom: "40px",
            right: "20%",
            minWidth: "250px",
          }}
        >
          <button
            className="sr-btn d-flex align-items-center justify-content-center  w-100"
            onClick={saveChanges}
          >
            {isSaving ? (
              <FaSpinner className="me-2 fa-spin" />
            ) : (
              <FaSave className="me-2" />
            )}

            <span>Save Changes</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default NotificationsSettings;
