import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiGroupsData } from "../../context/groupContext";
import { useApiPersonsData } from "../../context/personsContext";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import IconUser from "../../assets/icons/user.svg";

function PeopleTable(props) {
  const { people, totalRows, setSelectedPerson, setShowPersonModal } = props;
  const { data: groups } = useApiGroupsData();
  const { isFetching: isFetchingPersons } = useApiPersonsData();
  const { data: cameras } = useApiCamerasData();

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );
  const findCamera = (cameraId) => {
    return cameras.find((cam) => cam.id === cameraId) || {};
  };
  const columns = [
    {
      name: "Image",
      id: "image",
      selector: (row) => row.faces,
      sortable: false,
      cell: (row) => (
        <div
          className="py-3 pointer"
          onClick={() => handlePersonClicked(row, null)}
        >
          <img
            className="sr-image-person"
            src={
              row.faces && row.faces.length > 0
                ? `${IMAGE_BASE_PATH}/${row.faces[0]}`
                : IconUser
            }
            alt="person-face"
          />
        </div>
      ),
    },
    {
      name: "Name",
      id: "person_name",
      selector: (row) => row.person_name,
      sortable: true,
      cell: (row) => (
        <div
          style={{ display: "contents" }}
          onClick={() => handlePersonClicked(row, null)}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.person_name}</Tooltip>}
          >
            <span className="text-truncate fw-bold">{row.person_name}</span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Group",
      id: "group",
      selector: (row) => row.group,
      sortable: true,
      cell: (row) => (
        <div
          style={{ display: "contents", backgroundColor: "green" }}
          onClick={() => handlePersonClicked(row, null)}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.group}</Tooltip>}
          >
            <span
              style={{
                borderRadius: "12px",
                backgroundColor: groups.find((g) => g.id === row.group)
                  ? COLORS.GROUP[groups.find((g) => g.id === row.group).color]
                  : COLORS.GROUP.none,
              }}
              className="p-4 text-truncate  fw-bold"
            >
              {groups.find((g) => g.id === row.group)
                ? groups.find((g) => g.id === row.group).name
                : row.group}
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Last Seen",
      id: "last_seen",
      selector: (row) => row.last_seen || "N/A",
      sortable: true,
      cell: (row) => (
        <div onClick={() => handlePersonClicked(row, null)}>
          <span
            title={
              row.last_seen ? new Date(row.last_seen).toLocaleString() : "N/A"
            }
            className="fw-bold"
          >
            {row.last_seen
              ? new Date(row.last_seen).toLocaleDateString()
              : "N/A"}
          </span>
        </div>
      ),
    },
    {
      name: "Last Location",
      id: "last_location",
      sortable: true,
      selector: (row) =>
        row.last_location ? findCamera(row.last_location).name : "N/A",
      cell: (row) => (
        <div
          style={{ display: "contents" }}
          onClick={() => handlePersonClicked(row, null)}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {row.last_location ? findCamera(row.last_location).name : "N/A"}
              </Tooltip>
            }
          >
            <span className="text-truncate fw-bold">
              {row.last_location ? findCamera(row.last_location).name : "N/A"}
            </span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Identifications",
      id: "identifications",
      selector: (row) => row.identifications,
      sortable: true,
      cell: (row) => (
        <div
          style={{ display: "contents" }}
          onClick={() => handlePersonClicked(row, null)}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.identifications}</Tooltip>}
          >
            <span className="text-truncate fw-bold">{row.identifications}</span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Created",
      selector: (row) => row.created,
      id: "created",
      sortable: true,
      cell: (row) => (
        <div onClick={() => handlePersonClicked(row, null)}>
          <span
            title={new Date(row["created"]).toLocaleString()}
            className="fw-bold"
          >
            {new Date(row["created"]).toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      name: "Tag Id",
      selector: (row) => row.tag_id,
      id: "tag_id",
      sortable: true,
      cell: (row) => (
        <div onClick={() => handlePersonClicked(row, null)}>
          <span className="fw-bold">{row.tag_id}</span>
        </div>
      ),
    },
  ];

  const handlePersonClicked = (person, e) => {
    setSelectedPerson(person);
    setShowPersonModal(true);
  };

  return (
    <div>
      <DataTable
        key={people.length}
        columns={columns}
        data={people}
        pagination
        highlightOnHover
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[25, 50, 100]}
        sortIcon={<FaSortAmountUpAlt />}
        onRowClicked={handlePersonClicked}
        // sortFunction={(row) => row}
        //   expandableRows
        //   expandableRowsComponent={({ data }) => (
        //     <Properties dict={data.properties} name={data.name} />
        //   )}
        // selectableRows // add for checkbox selection
        // Clicked
        noDataComponent={
          isFetchingPersons ? "Loading people..." : "No people found"
        }
        theme="solarized"
        customStyles={{ rows: { style: { cursor: "pointer" } } }}
        // Selected={handleAgencySelected}
      />
    </div>
  );
}

export default PeopleTable;
