import React from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { COLORS } from "../../assets/colors";

function NotificationsTelegram(props) {
  const { notificationsSettings } = props;

  return (
    <div
      className="p-2 m-2"
      style={{
        border: "1px solid",
        borderRadius: "8px",
        borderColor: COLORS.Gray800,
        fontSize: "14px",
      }}
    >
      <div>
        {notificationsSettings.telegram_link ? (
          <div className="d-flex align-items-center">
            <FaCheckCircle className="me-3 fs-3" />
            <span>
              <div>
                <strong>Step 1: </strong>Download and install Telegram app for
                desktop or mobile from{" "}
                <span>
                  <a
                    style={{ color: COLORS.PrimaryLight }}
                    href="https://telegram.org/"
                  >
                    here
                  </a>
                </span>
              </div>
              <div>
                <strong>Step 2: </strong>Join to your channel here:{" "}
                <span>
                  <a
                    style={{ color: COLORS.PrimaryLight }}
                    href={notificationsSettings.telegram_link}
                  >
                    Telegram Channel
                  </a>
                </span>
              </div>
            </span>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <FaExclamationTriangle className="me-3 fs-3" />
            <span>
              Please contact support to open a Telegram Channel for your account
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationsTelegram;
