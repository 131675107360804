import React from "react";
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2/src/sweetalert2.js";
import { COLORS } from "../../assets/colors";

function SystemAlerts(props) {
  const { notificationsSettings, setNotificationsSettings } = props;

  function addEmail() {
    Swal.fire({
      title: "Add HTTPS URL",
      input: "text",
      inputPlaceholder: "https://myapp.myorg.com/system-alerts...",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Add URL",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.value) {
        setNotificationsSettings({
          ...notificationsSettings,
          extra_urls: [
            ...notificationsSettings.extra_urls,
            { url: result.value },
          ],
        });
      }
    });
  }

  return (
    <div
      className="p-2 m-2 "
      style={{
        border: "1px solid",
        borderRadius: "8px",
        borderColor: COLORS.Gray800,
      }}
    >
      <div>
        {notificationsSettings.extra_urls &&
          notificationsSettings.extra_urls.map(({ url }, i) => (
            <div
              key={`url-${i}`}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div>{url}</div>
              <div>
                <FaTrash
                  className="mx-3 pointer"
                  onClick={() =>
                    setNotificationsSettings({
                      ...notificationsSettings,
                      extra_urls: notificationsSettings.extra_urls.filter(
                        (e, j) => j !== i
                      ),
                    })
                  }
                />
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-center">
          <button className="sr-outline-btn" onClick={addEmail}>
            Add URL
          </button>
        </div>
      </div>
    </div>
  );
}

export default SystemAlerts;
