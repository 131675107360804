import React, { useState } from "react";
import Select from "react-select";
import { COLORS } from "../../assets/colors";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { MdGroupAdd } from "react-icons/md";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import {
  useApiPersonsData,
  useApiUpdatePerson,
} from "../../context/personsContext";

function AddFaceToExistingPerson(props) {
  const { face, close } = props;
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  const { mutate: updatePerson, isLoading: updatingPerson } =
    useApiUpdatePerson();
  const [selectedPerson, setSelectedPerson] = useState(null);

  const addExisting = () => {
    var personToSend = { ...selectedPerson };
    personToSend.faces = [...selectedPerson.faces, face.image];
    const pid = personToSend.id;
    delete personToSend.id;
    updatePerson({
      personId: pid,
      personData: personToSend,
      onSuccessCallback: () =>
        showSuccessDialog("Person updated successfully").then(() => close()),
      onErrorCallback: () => showErrorDialog("Failed to update person"),
    });
  };

  return (
    <div className="mx-2 row mt-2">
      <div
        className="p-2 "
        style={{
          backgroundColor: COLORS.Gray600,
          borderRadius: "8px",
        }}
      >
        <div className="d-flex justify-content-end">
          <FaTimes
            className="text-end fs-4"
            onClick={close}
            style={{ color: COLORS.FontGray, cursor: "pointer" }}
          />
        </div>
        <div className="text-center mb-2 ">
          Adding multiple faces to a person can improve performance for future
          recognitions
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Select
            isDisabled={isFetchingPersons}
            className={`react-select-container col-12 col-lg-6`}
            classNamePrefix="react-select"
            placeholder="Select person..."
            onChange={(e) =>
              setSelectedPerson(persons.find((p) => p.id === e.value))
            }
            options={persons.map((p) => {
              return {
                label: p.person_name,
                value: p.id,
              };
            })}
            theme="dark"
          />
          {selectedPerson && (
            <div className="ms-2">
              <img
                src={`${IMAGE_BASE_PATH}${selectedPerson.faces[0]}`}
                alt="selected-person"
                style={{
                  height: "80px",
                  width: "80px",
                  borderRadius: "50%",
                  borderColor: COLORS.FontGray,
                  border: "2px solid",
                }}
              />
            </div>
          )}
        </div>

        <div className="d-lg-flex justify-content-around mt-2">
          <button
            onClick={close}
            className="sr-outline-btn col-12 col-lg-3 mt-2"
          >
            Cancel
          </button>
          <button
            disabled={
              !selectedPerson ||
              (selectedPerson.faces &&
                selectedPerson.faces.includes(face.image))
            }
            className="sr-btn  col-12 col-lg-3 mt-2 d-flex align-items-center justify-content-center"
            onClick={addExisting}
          >
            {updatingPerson ? (
              <FaSpinner className="fa-spin me-2 fs-4" />
            ) : (
              <MdGroupAdd className="me-2 fs-4" />
            )}
            {selectedPerson
              ? `Add this face to ${selectedPerson.person_name.replaceAll(
                  "_",
                  " "
                )}`
              : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddFaceToExistingPerson;
