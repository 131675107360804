import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

export const Input = styled.input`
  background-color: ${COLORS.Gray800};
  color: ${COLORS.FontGray};
  display: flex;
  border-width: 0;
  border-radius: 8px;
  padding: 7px 8px 7px 8px;
  outline: none;
  width: 100%;

  &:disabled {
    color: ${COLORS.GROUP.gray};
    cursor: not-allowed;
  }

  &:focus:enabled,
  &:hover:enabled {
    box-shadow: 1px 1px 10px ${COLORS.ShadowLightGray} !important;
  }
`;
