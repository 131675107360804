import React, { useContext } from "react";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";

function MainContainer(props) {
  const { children, noPadding, bgWhite } = props;
  const { sidebarCollapse } = useContext(DataContext);
  const { isMobile } = useContext(DataContext);
  if (isMobile) {
    return (
      <div
        className="p-2 px-3"
        style={{
          width: "100%",
          marginTop: "8px",
          backgroundColor: bgWhite ? bgWhite : COLORS.BgBlack,
          color: COLORS.FontGray,
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      style={{
        width: `calc(100% - ${sidebarCollapse ? "80px" : "280px"})`,
        minHeight: "100vh",
        marginLeft: sidebarCollapse ? "80px" : "280px",
        marginRight: "20px",
        padding: noPadding ? "unset" : "20px",
        marginTop: "8px",
        borderRadius: "12px 0 0 0",
        backgroundColor: bgWhite ? bgWhite : COLORS.BgBlack,
        color: COLORS.FontGray,
      }}
    >
      {children}
    </div>
  );
}

export default MainContainer;
