import React, { useContext, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { COLORS } from "../../assets/colors";
import { DataContext } from "../../App";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { FaPencilAlt, FaSpinner, FaTrashAlt } from "react-icons/fa";
import EmptyIcon from "../../assets/icons/empty.svg";
// @ts-ignore
import Swal from "sweetalert2/src/sweetalert2.js";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { Modal } from "react-bootstrap";
import PeopleCreateGroupPopup from "./PeopleCreateGroupPopup";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiDeleteGroup } from "../../context/groupContext";
import { useIsMobile } from "../../utils/hooks";
import { Group } from "../../types/Group";
import { Flex } from "../elements/flex/Flex";
import { SecondaryButton } from "../elements/buttons/SecondaryButton";
import { ThumbnailGallery } from "../shared-components/ThumbnailGallery";
import { SeparatorVertical } from "../elements/separators/SeparatorVertical";

type GroupCardProps = {
  group: Group;
};

export function GroupCard({ group }: GroupCardProps) {
  const { userInfo } = useContext(DataContext);
  const isMobile = useIsMobile();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  const { mutate: deleteGroup } = useApiDeleteGroup();

  const [showEditGroupModal, setShowEditGroupModal] = useState(false);

  const groupPersons = persons?.filter((p) => p.group === group.id) || [];

  const handleDeleteGroup = () => {
    deleteGroup({
      groupId: group.id,
      onSuccessCallback: () => showSuccessDialog("Group deleted successfully"),
      onErrorCallback: () => showErrorDialog("Failed to delete group"),
    });
  };

  const beforeDeleteGroup = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      html: ReactDOMServer.renderToString(
        <div>
          <div>
            You are about to delete the "{group.name}" group{" "}
            {groupPersons.length > 0 && (
              <>
                along with the
                {" " + groupPersons.length} people in it
              </>
            )}
          </div>
          <div className="d-flex my-2 justify-content-center">
            {groupPersons.slice(0, 3).map((person) => (
              <div
                className="mx-2"
                key={`delete-person-${person.id}`}
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: COLORS.FontGray,
                }}
              >
                <img
                  src={`${IMAGE_BASE_PATH}${person.faces[0]}`}
                  alt={`delete-person-${person.id}`}
                  height="50px"
                  width="50px"
                  style={{
                    borderRadius: "50%",
                  }}
                />
              </div>
            ))}
            {groupPersons.length > 3 && (
              <span className="align-self-end" style={{ fontSize: "12px" }}>
                and more...
              </span>
            )}
          </div>

          <div>You won't be able to revert this.</div>
        </div>
      ),
    }).then((result: any) => {
      if (result.value) {
        handleDeleteGroup();
      }
    });
  };

  return (
    <Flex
      w100
      style={{
        border: "solid 1px",
        borderRadius: "8px",
        borderColor: COLORS.Gray100,
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray800,
        height: isMobile ? "330px" : "180px",
      }}
    >
      <Flex
        h100
        style={{
          width: "10px",
          backgroundColor: COLORS.GROUP[group.color],
          borderRadius: "8px 0 0 8px",
        }}
      ></Flex>
      <Flex
        w100
        padding="12px"
        gap="16px"
        align="start"
        justify="between"
        column={isMobile}
      >
        <Modal
          show={showEditGroupModal}
          onHide={() => setShowEditGroupModal(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          fullscreen={"md-down"}
        >
          <PeopleCreateGroupPopup
            close={() => setShowEditGroupModal(false)}
            group={group}
          />
        </Modal>
        <Flex column gap="16px">
          <Flex align="center" gap="4px">
            <div className="fs-5 text-uppercase">{group.name}</div>
          </Flex>
          <Flex column gap="4px">
            <Flex
              align="center"
              gap="4px"
              title="Number of people in the group"
            >
              <label style={{ width: "110px" }}>People count:</label>
              <label className="fw-bold">{groupPersons.length}</label>
            </Flex>
            <Flex
              align="center"
              gap="4px"
              title="Minimum similarity grade for recognition"
            >
              <label style={{ width: "110px" }}>Grade needed:</label>
              <label className="fw-bold">{group.conf}%</label>
            </Flex>
          </Flex>
          <Flex align="center" gap="8px">
            <SecondaryButton
              onClick={() => setShowEditGroupModal(true)}
              disabled={!userInfo?.is_staff}
            >
              <Flex gap="4px" align="center">
                <FaPencilAlt /> <label>EDIT</label>
              </Flex>
            </SecondaryButton>
            <SecondaryButton
              variant="danger"
              onClick={beforeDeleteGroup}
              disabled={!userInfo?.is_staff}
            >
              <Flex gap="4px" align="center">
                <FaTrashAlt /> <label>DELETE</label>
              </Flex>
            </SecondaryButton>
          </Flex>
        </Flex>

        {!isMobile && (
          <SeparatorVertical
            style={{
              backgroundColor: COLORS.Gray500,
            }}
          />
        )}

        {isFetchingPersons ? (
          <div className="my-4 text-center ">
            <FaSpinner className="fa-spin fs-3" />
          </div>
        ) : (
          <Flex
            align="center"
            justify="center"
            w100
            style={{
              height: "90%",
            }}
          >
            {groupPersons.length === 0 ? (
              <Flex column align="center" className="text-center" gap="8px">
                <img src={EmptyIcon} width={80} height={80} alt="is empty" />
                <h6>There are no people in this group</h6>
              </Flex>
            ) : (
              <ThumbnailGallery
                key={isMobile.toString()}
                thumbnails={groupPersons
                  .filter((p) => p.faces?.length)
                  .map((p) => ({
                    title: p.person_name,
                    src: p.faces[0],
                  }))}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
