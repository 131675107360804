import moment from "moment";
import React from "react";
import { BsPersonBoundingBox } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight, FaRegPlayCircle } from "react-icons/fa";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { COLORS } from "../../assets/colors";
import { playVideoClip } from "../../popups/opretaions";
import { Slide } from "react-slideshow-image";
import { useApiCamerasData } from "../../context/camerasContext";
import { useIsMobile } from "../../utils/hooks";
import { FaceImage } from "./FaceImage";

function HistoryElement(props) {
  const { faces } = props;
  const { data: cameras } = useApiCamerasData();
  const isMobile = useIsMobile();
  const slideProperties = (facesLength) => {
    return {
      duration: 5000,
      slidesToShow: 1, // calculate how many pics we can put in the screen at the same time
      slidesToScroll: 1,
      infinite: false,
      indicators: facesLength > 1,
      arrows: facesLength > 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 8,
            indicators: facesLength > 8,
            arrows: facesLength > 8,
          },
        },

        {
          breakpoint: 720,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            indicators: facesLength > 3,
            arrows: facesLength > 3,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            indicators: facesLength > 2,
            arrows: facesLength > 2,
          },
        },
        {
          breakpoint: 220,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            indicators: facesLength > 1,
            arrows: facesLength > 1,
          },
        },
      ],
      autoplay: false,
      prevArrow: (
        <FaChevronLeft
          className="box-shadow-hover"
          style={{ cursor: "pointer", borderRadius: "12px" }}
        />
      ),
      nextArrow: (
        <FaChevronRight
          className="box-shadow-hover"
          style={{ cursor: "pointer", borderRadius: "12px" }}
        />
      ),
    };
  };

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      dateClassName="p-0"
      contentStyle={{
        background: COLORS.Gray800,
        color: COLORS.FontGray,
      }}
      contentArrowStyle={{
        borderRight: `7px solid  ${COLORS.Gray800}`,
      }}
      date={`${moment(new Date(faces[0].create_at)).fromNow()}, ${new Date(
        faces[0].create_at
      ).toLocaleDateString()}`}
      iconStyle={{
        background: COLORS.primary,
        color: COLORS.FontGray,
      }}
      icon={<BsPersonBoundingBox style={{ color: COLORS.FontGray }} />}
    >
      <h5 className="vertical-timeline-element-title">
        {cameras.find((c) => c.id === faces[0]?.camera_id)?.name}
      </h5>

      <Slide {...slideProperties(faces.length)}>
        {faces.map((face) => (
          <div
            key={`history-face-${face.image}`}
            style={{
              textAlign: "center",
              fontSize: "10px",
              color: COLORS.FontGray,
              width: "100px",
            }}
          >
            <FaceImage
              face={face}
              size="100px"
              alt={`history-face-${face.image}`}
              borderRadius="6px"
            />
            <div className="row" style={{}}>
              <div className="col-8 text-truncate text-start">
                <div>{new Date(face.create_at).toLocaleTimeString()}</div>
                <div>Confidence: {face.grade}%</div>
              </div>
              <div className="col-2">
                {(face.video || face.face_extra?.video) && (
                  <FaRegPlayCircle
                    style={{
                      fontSize: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      playVideoClip(
                        face.video ? face.video : face.face_extra?.video,
                        isMobile
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </VerticalTimelineElement>
  );
}

export default HistoryElement;
