import React from "react";
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2/src/sweetalert2.js";
import { COLORS } from "../../assets/colors";

function NotificationsEmails(props) {
  const { notificationsSettings, setNotificationsSettings } = props;

  function addEmail() {
    Swal.fire({
      title: "Add E-Mail Address",
      input: "email",
      inputPlaceholder: "Enter your email address",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Add email",
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.value) {
        setNotificationsSettings({
          ...notificationsSettings,
          emails: [...notificationsSettings.emails, result.value],
        });
      }
    });
  }

  return (
    <div
      className="p-2 m-2"
      style={{
        border: "1px solid",
        borderRadius: "8px",
        borderColor: COLORS.Gray800,
      }}
    >
      <div>
        {notificationsSettings.emails &&
          notificationsSettings.emails.map((email, i) => (
            <div
              key={`email-${i}`}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div>{email}</div>
              <div>
                <FaTrash
                  className="mx-3 pointer"
                  onClick={() =>
                    setNotificationsSettings({
                      ...notificationsSettings,
                      emails: notificationsSettings.emails.filter(
                        (e, j) => j !== i
                      ),
                    })
                  }
                />
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-center">
          <button className="sr-outline-btn" onClick={addEmail}>
            Add Email
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotificationsEmails;
