import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Flex } from "../../elements/flex/Flex";

export const FilterSelectElement = (props) => {
  const animatedComponents = makeAnimated();

  const { title, name, options, filters, setFilters } = props;
  return (
    <Flex
      column
      style={{
        width: "250px",
      }}
    >
      <label>{title}</label>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder="Select..."
        value={options.filter(
          (o) => filters[name] && filters[name].includes(o.value)
        )}
        onChange={(options) =>
          setFilters({ ...filters, [name]: options.map((e) => e.value) })
        }
        options={options}
        components={animatedComponents}
        isMulti={true}
        theme="dark"
      />
    </Flex>
  );
};
