import React from "react";

export function MenuItem(props) {
  const { icon, text, onClick, disabled = false } = props;
  return (
    <div
      className={`d-flex align-items-center gap-4 p-1 ${
        disabled ? "sr-disabled" : "hover-primary-light pointer"
      }`}
      onClick={() => !!onClick && !disabled && onClick()}
    >
      {icon}
      <div className="">{text}</div>
    </div>
  );
}
