import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import { IMAGE_BASE_PATH, VIDEO_BASE_PATH } from "../faceit-api/FaceitApi";

export const showInfoDialog = (message) => {
  return Swal.fire({
    icon: "info",
    title: "FYI 🧐",
    text: message,
  });
};

export const showSuccessDialog = (message) => {
  return Swal.fire({
    icon: "success",
    title: "Awesome 🥳",
    text: message,
  });
};

export const showErrorDialog = (message) => {
  return Swal.fire({
    icon: "error",
    title: "Ooops... 😕",
    text: message,
  });
};

export const playVideoClip = (video_name, isMobile = false) => {
  return Swal.fire({
    width: isMobile ? "100%" : "70%",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: "Close",
    html: ReactDOMServer.renderToString(
      <video
        id="video_clip"
        className="sr-video col-12 p-0 m-0"
        style={{ maxHeight: "500px", maxWidth: "1200px" }}
        controls
        autoPlay
      >
        <source src={`${VIDEO_BASE_PATH}${video_name}`} type="video/mp4" />{" "}
      </video>
    ),
  });
};

export const openSpoofPopup = (spoof_image, spoof_score) => {
  return Swal.fire({
    width: "70%",
    height: "70%",
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: "Close",
    html: ReactDOMServer.renderToString(
      <>
        <img
          alt="spoof"
          style={{
            maxHeight: "500px",
            maxWidth: "1200px",
            borderRadius: "8px",
          }}
          src={`${IMAGE_BASE_PATH}${spoof_image}`}
          class="col"
        />{" "}
        <div class="mt-2">Score: {spoof_score}</div>
      </>
    ),
  });
};
