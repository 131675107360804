import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, post } from "../faceit-api/FaceitApi";
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import {
  DockerContainer,
  DockerContainerContext,
  DockerContainerUpdate,
} from "../types/DockerContainer";

const queryKey = "docker/containers";
const updateQueryKey = "docker/action";

const getContainers = (): DockerContainer[] | any =>
  get(queryKey).then((resp) => {
    if (resp.results) {
      return resp.results;
    }
  });

export const useApiContainers = () =>
  useQuery<DockerContainer[], Error>({
    queryKey: [queryKey],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    refetchInterval: 5000, // Polling every 5 seconds
    queryFn: async (): Promise<DockerContainer[]> => getContainers(),
  });

export const useApiTriggerContainers = () => {
  const queryClient = useQueryClient();
  return useMutation<DockerContainerUpdate, Error, DockerContainerContext>({
    mutationKey: [updateQueryKey],
    mutationFn: async ({
      updateData,
    }: DockerContainerContext): Promise<DockerContainerUpdate> =>
      await post(updateQueryKey, updateData).then((resp) => {
        if (resp?.status !== 1) throw new Error("Failed to trigger containers");
        return resp;
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
