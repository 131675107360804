import React, { useEffect, useState } from "react";
import { exportLogs, get, post } from "../../faceit-api/FaceitApi";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import moment from "moment";
import { FaDownload, FaSpinner, FaUpload } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";

export function CameraSettingsLogs(props) {
  const { camera, setCamera } = props;
  const [isExporting, setIsExporting] = useState(false);
  const [files, setFiles] = useState([]);
  const [isOnlineLogsEnabled, setIsOnlineLogsEnabled] = useState(
    camera?.online_logs_enabled || true
  );

  useEffect(() => {
    if (!camera) return;
    get(`support/android_logs_list?camera_id=${camera?.id}`).then((resp) => {
      if (resp.status === 1) {
        setFiles(resp.files);
      } else {
        showErrorDialog(resp.message || "Failed to get logs list");
      }
    });
  }, [camera]);

  const logLevelOptions = [
    { value: 2, label: "Verbose" },
    { value: 3, label: "Debug" },
    { value: 4, label: "Info" },
    { value: 5, label: "Warning" },
    { value: 6, label: "Error" },
  ];

  const downloadLogs = (filename) => {
    // Create blob link to download
    setIsExporting(true);
    exportLogs(true, camera?.id, filename)
      .then((response) => response.blob())
      .then((blob) => {
        setIsExporting(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
        showErrorDialog("Failed to export logs");
        setIsExporting(false);
      });
  };

  const refreshLogs = () => {
    post(`support/android_send_logs`, { camera_id: camera?.id }).then(
      (resp) => {
        if (resp.status === 1) {
          showSuccessDialog(
            resp.message ||
              "Query sent successfully, refresh page in a few minutes"
          );
        } else {
          showErrorDialog(resp.message || "Failed to refresh logs");
        }
      }
    );
  };

  return (
    <div className="d-flex flex-column gap-8 mt-3">
      {!files?.length && (
        <div>No logs found for this camera, please refresh logs</div>
      )}
      {files?.map((file, i) => (
        <div key={`log-file-${i}`}>
          <div className="d-flex align-items-center gap-16">
            <div className="fw-bold">File Name:</div>
            <div
              className="text-truncate"
              title={file?.filename}
              style={{
                width: "200px",
              }}
            >
              {file.filename}
            </div>
            <div className="fw-bold">Modified Time:</div>
            <div
              className="text-truncate"
              title={new Date(file.created).toLocaleString()}
              style={{
                width: "100px",
              }}
            >
              {moment(file.created)?.fromNow()}
            </div>
            <div
              style={{
                color: COLORS.PrimaryLight,
              }}
              onClick={() => downloadLogs(file.filename)}
              className="pointer d-flex gap-1 align-items-center"
            >
              {isExporting ? <FaSpinner className="fa-spin" /> : <FaDownload />}
              <div>Download Logs</div>
            </div>
          </div>
        </div>
      ))}

      <div
        className=" d-flex gap-2 align-items-center"
        style={{
          color: COLORS.PrimaryLight,
          display: "flex",
        }}
        onClick={refreshLogs}
      >
        <FaUpload />
        <div>Refresh Logs</div>
      </div>

      {camera.is_android && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Enable / Disable logs to be sent online</Tooltip>}
        >
          <div className="d-flex align-items-center justify-content-start gap-8">
            <div
              className="text-truncate fw-bolder"
              style={{
                maxWidth: "200px",
                width: "200px",
              }}
            >
              Online Logs Enabled
            </div>
            <div
              className="w-100"
              style={{
                maxWidth: "300px",
              }}
            >
              <SwitchCheckbox
                checked={isOnlineLogsEnabled}
                onChange={(e) => {
                  setIsOnlineLogsEnabled(e.target.checked);
                  setCamera({
                    ...camera,
                    online_logs_enabled: e.target.checked,
                  });
                }}
              />
            </div>
          </div>
        </OverlayTrigger>
      )}

      <div>
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              !isOnlineLogsEnabled && "disabled"
            }`}
            title="Online log level"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Online Log Level
          </div>
          <div
            className={`w-100 ${!isOnlineLogsEnabled && "disabled"}`}
            style={{
              maxWidth: "300px",
              marginBottom: camera.is_android ? "10px" : "0px",
            }}
          >
            {" "}
            <Select
              isDisabled={!isOnlineLogsEnabled}
              className="react-select-container "
              classNamePrefix="react-select"
              placeholder="Select log level..."
              theme="dark"
              value={
                logLevelOptions.find(
                  (o) => camera && o.value === camera.online_logs_level
                ) || logLevelOptions[2]
              }
              options={logLevelOptions}
              onChange={(e) =>
                setCamera({
                  ...camera,
                  online_logs_level: e.value,
                })
              }
            ></Select>
          </div>
        </div>
      </div>

      {}
    </div>
  );
}
