import React, { useEffect, useState } from "react";
import { BsClock, BsDownload, BsPeopleFill } from "react-icons/bs";
import { COLORS } from "../../assets/colors";
import MainContainer from "../main-menu/MainContainer";
import Select from "react-select";
import GrayBox from "./GrayBox";
import { GiHumanTarget } from "react-icons/gi";
import { FaIdBadge, FaSpinner, FaUserSecret } from "react-icons/fa";

import { get } from "../../faceit-api/FaceitApi";
import { MANUALLY_UPLOADED, PREDICTED } from "../../assets/consts";
import DashboardBarChart from "./DashboardBarChart";
import DashboardLineChart from "./DashboardLineChart";
import DashboardPieChart from "./DashboardPieChart";
import { getThresholdDate } from "../../utils/helper";
import TooltipSr from "../general/TooltipSr";
import { Modal } from "react-bootstrap";
import AttendanceReportModal from "./AttendanceReportModal";
import { useApiPersonsData } from "../../context/personsContext";
import { useApiGroupsData } from "../../context/groupContext";
import { useIsMobile } from "../../utils/hooks";

export const HEADER_HEIGHT = "90px";
function Dashboard() {
  const isMobile = useIsMobile();
  const [showExportModal, setShowExportModal] = useState(false);
  const [isFetchingFaces, setIsFetchingFaces] = useState(false);
  const [isFetchingCounts, setIsFetchingCounts] = useState(false);
  const [recognitionsCount, setRecognitionsCount] = useState(0);
  const [detectionsCount, setDetectionsCount] = useState(0);
  const [fraudsCount, setFraudsCount] = useState(0);
  const [facesDataRange, setFacesDataRange] = useState(7);
  const [recognizedFaces, setRecognizedFaces] = useState([]);
  const [recognizedFacesNextPage, setRecognizedFacesNextPage] = useState("");
  const { data: groups } = useApiGroupsData();
  const groupsIds = groups.map((g) => g.id).join(",");

  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  useEffect(() => {
    if (!groupsIds) return;
    setRecognizedFaces([]);
  }, [groupsIds, facesDataRange]);

  useEffect(() => {
    if (!groupsIds) return;
    var weekAgo = getThresholdDate(facesDataRange);
    setRecognizedFaces([]);
    setRecognizedFacesNextPage(
      `faces?create_at_after=${weekAgo.toISOString()}&group_id=${groupsIds}`
    );
    setIsFetchingCounts(true);
    get(`faces/count?create_at_after=${weekAgo.toISOString()}`).then((resp) => {
      setIsFetchingCounts(false);
      if (resp.status === 1) {
        setDetectionsCount(resp.detections || 0);
        setRecognitionsCount(resp.recognitions || 0);
        setFraudsCount(resp.frauds || 0);
      }
    });
  }, [groupsIds, facesDataRange]);

  useEffect(() => {
    if (!recognizedFacesNextPage) return;
    setIsFetchingFaces(true);
    var nextLink = recognizedFacesNextPage.includes("/api/v2/")
      ? recognizedFacesNextPage.split("/api/v2/")[1]
      : recognizedFacesNextPage;
    get(nextLink).then((resp) => {
      setIsFetchingFaces(false);
      if (resp.results) {
        setRecognizedFaces((state) => [
          ...state,
          ...resp.results.filter(
            (f) =>
              f.camera_id !== MANUALLY_UPLOADED && f.camera_id !== PREDICTED
          ),
        ]);
      }
      if (resp.next) {
        setRecognizedFacesNextPage(resp.next);
      }
    });
  }, [recognizedFacesNextPage]);

  const timeRanges = [
    {
      label: "Today",
      value: 0,
    },
    {
      label: "Last 3 days",
      value: 3,
    },
    {
      label: "Last Week",
      value: 7,
    },
    {
      label: "Last Month",
      value: 30,
    },
  ];

  return (
    <MainContainer>
      <Modal
        show={showExportModal}
        onHide={() => setShowExportModal(false)}
        keyboard={false}
        size="lg"
        fullscreen={"md-down"}
      >
        <AttendanceReportModal close={() => setShowExportModal(false)} />
      </Modal>

      <div
        style={{
          color: COLORS.FontGray,
        }}
      >
        <div
          className={`d-flex align-items-center justify-content-between text-center mt-0 ${
            isMobile ? "flex-column gap-2" : ""
          }`}
        >
          <div
            className="d-flex flex-column justify-content-between align-items-start w-100"
            style={{
              height: HEADER_HEIGHT,
            }}
          >
            <div
              className="text-start"
              style={{
                fontSize: "28px",
                lineHeight: "38px",
              }}
            >
              Dashboard
            </div>
            <div
              className="d-flex align-items-center w-100 gap-2"
              style={{
                fontSize: "16px",
              }}
            >
              <BsClock />
              <div className="d-sm-flex d-none text-truncate">
                Showing data for:
              </div>
              <div
                className="w-100"
                style={{
                  maxWidth: "300px",
                }}
              >
                <Select
                  className={`react-select-container`}
                  classNamePrefix="react-select"
                  placeholder="Select..."
                  value={timeRanges.find((c) => c.value === facesDataRange)}
                  onChange={(e) =>
                    setFacesDataRange(
                      timeRanges.find((c) => c.value === e.value).value
                    )
                  }
                  options={timeRanges}
                  theme="dark"
                />
              </div>
              <TooltipSr title="Export reports" placement="bottom">
                <div
                  className="pointer fs-5 color-primary-light-hover"
                  style={{ borderRadius: "6px" }}
                  onClick={() => setShowExportModal(true)}
                >
                  <BsDownload />
                </div>
              </TooltipSr>
            </div>
          </div>
          <div
            className={`d-flex gap-4 w-100 ${
              isMobile ? "flex-wrap justify-content-around" : ""
            }`}
            style={{
              minWidth: !isMobile ? "800px" : "",
            }}
          >
            <GrayBox
              link="/people"
              title="Total listed people"
              icon={
                isFetchingPersons ? (
                  <FaSpinner className="fa-spin" />
                ) : (
                  <BsPeopleFill />
                )
              }
              color={COLORS.PrimaryLight}
              number={persons?.length || 0}
            />
            <GrayBox
              link="/faces"
              title="Total Detections"
              icon={
                isFetchingCounts ? (
                  <FaSpinner className="fa-spin" />
                ) : (
                  <GiHumanTarget />
                )
              }
              color={COLORS.LightSky}
              number={detectionsCount}
            />
            <GrayBox
              link="/faces?identification_only=true"
              title="Total Recognitions"
              icon={
                isFetchingCounts ? (
                  <FaSpinner className="fa-spin" />
                ) : (
                  <FaIdBadge />
                )
              }
              color={COLORS.Indigo}
              number={recognitionsCount}
            />
            <GrayBox
              link="/faces?spoof=lock"
              title="Fraud attempts"
              icon={
                isFetchingCounts ? (
                  <FaSpinner className="fa-spin" />
                ) : (
                  <FaUserSecret />
                )
              }
              color={COLORS.orange}
              number={fraudsCount}
            />
          </div>
        </div>
        <div className="w-100">
          <DashboardLineChart
            faces={recognizedFaces}
            isFetchingFaces={isFetchingFaces}
            dateRange={facesDataRange}
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <DashboardBarChart
              faces={recognizedFaces}
              isFetchingFaces={isFetchingFaces}
              dateRange={facesDataRange}
            />
          </div>
          <div className="col-12 col-lg-6">
            <DashboardPieChart
              faces={recognizedFaces}
              isFetchingFaces={isFetchingFaces}
              dateRange={facesDataRange}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default Dashboard;
