import React from "react";

function PersonAddResultInputSection(props) {
  const { changeResultValue, result, groupsOptions, person } = props;
  return (
    <div className="px-2">
      <div
        className="row mt-2 align-items-center justify-content-between"
        style={{
          fontSize: "14px",
        }}
      >
        <label className="col-2 ">Name</label>
        <input
          type="text"
          disabled={person}
          className="col-9 sr-text-input"
          onChange={(e) => changeResultValue("name", e.target.value)}
          value={person ? person.person_name : result.name}
          id={`name-input-${result.name}`}
        />
      </div>
      <div
        className="row mt-2 align-items-center justify-content-between"
        style={{
          fontSize: "14px",
        }}
      >
        <label className="col-2 ">Group</label>
        <select
          className="col-9 sr-text-input"
          disabled={person}
          value={person ? person.group : null}
          onChange={(e) => changeResultValue("group", e.target.value)}
          id={`group-select-${result.name}`}
        >
          {groupsOptions.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default PersonAddResultInputSection;
