import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteItem, get } from "../faceit-api/FaceitApi";
import { DEFAULT_STALE_TIME } from "../utils/helper";

async function getTagEvents(params) {
  return get(`tag-events?${new URLSearchParams(params).toString()}`).then(
    (resp) => {
      return resp;
    }
  );
}

async function deleteTagEvent({
  tagEventId,

  onSuccessCallback,
  onErrorCallback,
}) {
  return deleteItem(`tag-events/bulk?id=${tagEventId}`);
}

export const useApiTagEventsFilteredPagination = (
  paginationPerPage,
  ordering,
  page,
  filters
) => {
  const readyToUseFilters = {
    ...{
      page_size: paginationPerPage,
      ordering: ordering,
      page: page,
    },
    ...filters,
  };

  return useQuery(
    ["getTagEvents", readyToUseFilters],
    () => getTagEvents(readyToUseFilters),
    {
      keepPreviousData: true,
      staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
    }
  );
};

export const useApiDeleteTagEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTagEvent, {
    onSuccess: (resp, { tagEventId, onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getTagEvents", (oldTagEvents) =>
          oldTagEvents?.filter((t) => t.id !== tagEventId)
        );
        return onSuccessCallback();
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};
