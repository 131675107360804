import {  get } from "../faceit-api/FaceitApi";
import { useQuery} from "react-query";  
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";

async function healthCheck() {
    return get("web-settings/health_check").then((resp) => {
        return resp || {}; 
    });
}

export const useApiHealthCheck = () => {
    //return the resp.results or an empty array if the resp is undefined or null 
    return useQuery("healthCheck", healthCheck, {
        keepPreviousData: true,
        initialData: {},
        initialDataUpdatedAt: bitAfterNow(),
        staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
    });
}

