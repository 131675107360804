import React, { useState, useCallback } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaDoorClosed,
  FaDoorOpen,
  FaFemale,
  FaLock,
  FaMale,
  FaRegUser,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserSecret,
} from "react-icons/fa";
import { BsCameraVideo, BsPeople } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";

import { COLORS } from "../../assets/colors";
import PlayIcon from "../../assets/icons/play.svg";
import UsersGroupIcon from "../../assets/icons/users_group.svg";
import AlertIcon from "../../assets/icons/alert.svg";
import VestIcon from "../../assets/icons/safety_vest.svg";
import HelmetIcon from "../../assets/icons/safety_helmet.svg";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import moment from "moment";
import "./PersonImageCircle";
import { openSpoofPopup, playVideoClip } from "../../popups/opretaions";
import FaceRecognizedModal from "./FaceRecognizedModal";
import { dateDisplay } from "../../utils/helper";
import { useIsMobile } from "../../utils/hooks";
import { FaceImage } from "./FaceImage";
import { Flex } from "../elements/flex/Flex";
import { Camera } from "../../types/Camera";
import { Group } from "../../types/Group";
import { Person } from "../../types/Person";
import { Face } from "../../types/Face";
import { PersonImageCircle } from "./PersonImageCircle";

type FaceCardProps = {
  face: Face;
  person?: Person;
  camera: Camera;
  group?: Group;
  isUnknown: boolean;
  openTrackingModal: (trackingId: string) => void;
};

function FaceCard(props: FaceCardProps) {
  const { face, person, camera, group, isUnknown, openTrackingModal } = props;
  const isMobile = useIsMobile();
  const [showDate, setShowDate] = useState(false);
  const [showFaceModal, setShowFaceModal] = useState(false);
  const isPPEEnabled = camera?.ppe_detection?.enabled;

  const faceExtra = {
    video: face.video ? face.video : face.face_extra?.video,
    alerted: face.alerted ? face.alerted : face.face_extra?.alerted,
    entrance: face.entrance ? face.entrance : face.face_extra?.entrance,
    blocked: face.blocked ? face.blocked : face.face_extra?.blocked,
  };

  const handleTrackingModalOpen = useCallback(() => {
    !!face.tracking_id && openTrackingModal(face.tracking_id);
  }, [openTrackingModal, face.tracking_id]);

  const getAlertTypeName = (alert_type: string): string => {
    switch (alert_type) {
      case "ppe_detection":
        return "Personal Protective Equipment (PPE) Violation";
      case "involuntary_detection":
        return "Involuntary Detection";
      case "restricted_time":
        return "Restricted Time Violation";
    }
    return alert_type;
  };

  return (
    <Flex
      column
      padding="8px"
      style={{
        borderRadius: "8px",
        backgroundColor: COLORS.Gray800,
        height: "254px",
        width: "176px",
        color: COLORS.FontGray,
      }}
      className="box-shadow-hover"
    >
      {person && group && (
        <Modal
          show={showFaceModal}
          onHide={() => setShowFaceModal(false)}
          keyboard={false}
          size="xl"
          fullscreen={"md-down"}
        >
          <FaceRecognizedModal
            close={() => setShowFaceModal(false)}
            camera={camera}
            face={face}
            person={person}
            group={group}
            isUnknown={isUnknown}
          />
        </Modal>
      )}

      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ position: "relative", cursor: "pointer" }}
        onClick={() => setShowFaceModal(true)}
      >
        <FaceImage
          face={face}
          size="140px"
          isTrackMode={face.first_seen ?? false}
        />

        {!!face.track_count && (
          <Flex
            align="center"
            justify="center"
            style={{
              position: "absolute",
              border: `1px solid ${COLORS.FontGray}`,
              backgroundColor: COLORS.Gray800,
              color: COLORS.FontGray,
              borderRadius: "50%",
              top: "-3px",
              left: "12px",
              width: "24px",
              height: "24px",
              fontSize: "9px",
            }}
          >
            <label
              style={{
                lineHeight: "14px",
                fontSize: "11px",
              }}
            >
              {face.track_count}
            </label>
          </Flex>
        )}

        {!isUnknown && person?.faces && (
          <PersonImageCircle
            src={`${IMAGE_BASE_PATH}${person.faces[0]}`}
            alt={`face-person-${person.faces[0]}`}
          />
        )}
      </div>

      <Flex justify="between" gap="4px">
        <Flex
          column
          style={{
            maxWidth: "140px",
            overflow: "auto",
          }}
        >
          <div>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {isUnknown
                    ? `This event has not passed the identification threshold, therefore defined as UNKNOWN`
                    : person?.person_name}
                </Tooltip>
              }
            >
              {isUnknown ? (
                <div
                  className="text-truncate fw-bolder"
                  style={{
                    color: COLORS.orange,
                  }}
                >
                  UNKNOWN
                </div>
              ) : (
                <div className="text-truncate fw-bolder">
                  {person?.person_name}
                </div>
              )}
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{dateDisplay(new Date(face.create_at))}</Tooltip>
              }
            >
              <div
                className="text-truncate"
                onClick={() => setShowDate(!showDate)}
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                {showDate
                  ? dateDisplay(new Date(face.create_at))
                  : moment(new Date(face.create_at)).fromNow()}
              </div>
            </OverlayTrigger>
          </div>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Camera - {camera.name}</Tooltip>}
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <BsCameraVideo className="me-1" />
              <div className="text-truncate">{camera.name}</div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Confidence Grade - {parseInt(face.grade.toString())}%
              </Tooltip>
            }
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <AiOutlineCalculator className="me-1" />
              <div className="text-truncate">
                Grade: {parseInt(face.grade.toString())}%
              </div>
            </div>
          </OverlayTrigger>

          {isUnknown ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Top match result is - {person?.person_name}</Tooltip>
              }
            >
              <div
                className="d-flex align-items-center"
                style={{ fontSize: "12px" }}
              >
                <FaRegUser className="me-1" />
                <div className="text-truncate">{person?.person_name}</div>
              </div>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Group - {group?.name}</Tooltip>}
            >
              <div
                className="d-flex align-items-center fw-bold"
                style={{
                  fontSize: "12px",
                  color: COLORS.GROUP[group?.color || "green"],
                }}
              >
                <BsPeople className="me-1" />
                <div className="text-truncate">{group?.name}</div>
              </div>
            </OverlayTrigger>
          )}
        </Flex>
        <Flex column gap="4px">
          {faceExtra.video && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Watch a short video of this event</Tooltip>}
            >
              <img
                alt="play-icon"
                src={PlayIcon}
                width={16}
                height={16}
                onClick={() => playVideoClip(faceExtra.video, isMobile)}
                className="pointer"
              />
            </OverlayTrigger>
          )}
          {face.spoof === "suspicion" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  This event is not clear enough to determine whether it is an
                  attempted fraud
                </Tooltip>
              }
            >
              <FaUserSecret
                style={{
                  cursor:
                    face.spoof_image && face.spoof_score ? "pointer" : "unset",
                }}
                onClick={() =>
                  face.spoof_image &&
                  face.spoof_score &&
                  openSpoofPopup(face.spoof_image, face.spoof_score)
                }
                width={16}
                height={16}
                color={COLORS.FontGray}
              />
            </OverlayTrigger>
          )}
          {face.spoof === "lock" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{`Fraud attempted, score: ${parseFloat(
                  face.spoof_score?.toString() || "0"
                ).toFixed(2)}`}</Tooltip>
              }
            >
              <FaLock
                width={16}
                height={16}
                color={COLORS.FontGray}
                style={{
                  cursor:
                    face.spoof_image && face.spoof_score ? "pointer" : "unset",
                }}
                onClick={() =>
                  face.spoof_image &&
                  face.spoof_score &&
                  openSpoofPopup(face.spoof_image, face.spoof_score)
                }
              />
            </OverlayTrigger>
          )}
          {faceExtra.alerted && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {`An alert of types: "${face.alerted_types?.map((alert_type) => getAlertTypeName(alert_type))?.join(", ")}" has been sent for this event at: ${new Date(face.alerted_at || "").toLocaleString()}.`}
                </Tooltip>
              }
            >
              <img alt="alert-icon" src={AlertIcon} width={16} height={16} />
            </OverlayTrigger>
          )}
          {face.first_seen && face.tracking_id && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  This is the best event of this {face.track_count || 0} track
                  events. Click to see them all
                </Tooltip>
              }
            >
              <img
                alt="track-icon"
                src={UsersGroupIcon}
                width={16}
                height={16}
                onClick={handleTrackingModalOpen}
                className="pointer"
              />
            </OverlayTrigger>
          )}
          {face.first_seen &&
            isPPEEnabled &&
            face.track_has_helmet === false && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>This person did not wear a helmet</Tooltip>}
              >
                <img
                  alt="helmet-icon"
                  src={HelmetIcon}
                  width={16}
                  height={16}
                />
              </OverlayTrigger>
            )}
          {face.first_seen && isPPEEnabled && face.track_has_vest === false && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>This person did not wear a vest</Tooltip>}
            >
              <img alt="vest-icon" src={VestIcon} width={16} height={16} />
            </OverlayTrigger>
          )}
          {faceExtra.entrance && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  This event activated the door opener controller
                </Tooltip>
              }
            >
              <FaDoorOpen width={16} height={16} color={COLORS.FontGray} />
            </OverlayTrigger>
          )}
          {faceExtra.blocked === "anti_passback" && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  The door was not opened for this event as it did not comply
                  with the Anti-Passback Policy
                </Tooltip>
              }
            >
              <FaDoorClosed width={16} height={16} color={COLORS.FontGray} />
            </OverlayTrigger>
          )}
          {face.user_action === "clicked_enter" && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>User pressed on Enter button</Tooltip>}
            >
              <FaSignInAlt width={16} height={16} color={COLORS.FontGray} />
            </OverlayTrigger>
          )}
          {face.user_action === "clicked_exit" && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>User pressed on Exit button </Tooltip>}
            >
              <FaSignOutAlt width={16} height={16} color={COLORS.FontGray} />
            </OverlayTrigger>
          )}
          {face?.gender_proba &&
            face?.gender_proba > 0.05 &&
            face.gender === 0 && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>{`Face predicted as a ${face.age}-year-old woman`}</Tooltip>
                }
              >
                <FaFemale width={16} height={16} color={COLORS.FontGray} />
              </OverlayTrigger>
            )}
          {face?.gender_proba &&
            face.gender_proba > 0.05 &&
            face.gender === 1 && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>{`Face predicted as a ${face.age}-year-old man`}</Tooltip>
                }
              >
                <FaMale width={16} height={16} color={COLORS.FontGray} />
              </OverlayTrigger>
            )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FaceCard;
