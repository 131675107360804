import React, { useState } from "react";
import { FaCheck, FaTimes, FaSpinner, FaSave } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import CustomRangeSlider from "../general/CustomRangeSlider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useApiCreateGroup, useApiGroupsData, useApiUpdateGroups } from "../../context/groupContext";
import { green } from "@mui/material/colors";



function PeopleCreateGroupPopup(props) {
  const { close, group } = props;
  const [updatedGroup, setUpdatedGroup] = useState(group || {
    name: "",
    conf: 85, color: green
  });
  const { mutate: createGroup } = useApiCreateGroup();
  const { data: groups } = useApiGroupsData();
  const { mutate: updateGroup, isLoading: isUpdating } = useApiUpdateGroups();
  const isEdit = group;


  const handleEditGroup = () => {
    let tempGroup = { ...updatedGroup };
    let gid = tempGroup.id;
    delete tempGroup.id;
    updateGroup({
      groupId: gid,
      groupData: tempGroup,
      onSuccessCallback: () =>
        showSuccessDialog("Group updated successfully").then(close),
      onErrorCallback: () => showErrorDialog("Failed to update Group"),
    });
  };



  const handleCreatGroup = () => {

    createGroup({
      groupData: updatedGroup,
      onSuccessCallback: () =>
        showSuccessDialog("Group added successfully").then(close),
      onErrorCallback: () => showErrorDialog("Failed to add group"),
    });

  };

  const checkInput = () => {
    if (updatedGroup.name === null) {
      return "ok";
    }
    if (!updatedGroup.name) {
      return "Name cannot be empty";
    }
    if (
      groups
        .filter((g) => !group || group.id !== g.id)
        .some((g) => g.name.toLowerCase() === updatedGroup?.name?.toLowerCase())
    ) {
      return "Group with the same name already exists";
    }
    return "ok";
  };

  return (
    <div
      className="h-100 p-2"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray600,
        borderRadius: "12px",
      }}
    >
      <div className="d-flex ">
        <span
          className="text-end"
          onClick={close}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "20px",
          }}
        >
          <FaTimes className="fs-4" />
        </span>
        <span
          className="m-auto mt-4 mt-md-2 mb-3"
          style={{
            fontSize: "24px",
            lineHeight: "33px",
          }}
        >
          {isEdit ? `Edit "${updatedGroup.name}" group` : "Create new group"}
        </span>
      </div>
      <div className="w-75 mx-auto">
        <div className="py-3 px-5 row">
          <div
            className="d-flex justify-content-start  align-items-center row"
            style={{
              fontSize: "18px",
            }}
          >
            <label className="col-12 col-md-4">Group Name</label>
            <input
              type={"text"}
              className="col-12 col-md-8 sr-text-input"
              placeholder="Blacklist..."
              value={updatedGroup.name}
              onChange={(e) =>
                setUpdatedGroup({
                  ...updatedGroup,
                  name: e.target.value,
                })
              }
            />
          </div>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                The color of the group will only be used in the user interface,
                events of identifying people from the group will be marked in
                this color
              </Tooltip>
            }
          >
            <div
              className="d-flex mt-3 justify-content-start align-items-center row"
              style={{
                fontSize: "18px",
              }}
            >
              <label className="col-12 col-md-4">Group Color</label>
              <div
                className="justify-content-start align-items-center col-12 col-md-8"
                style={{
                  display: "contents",
                }}
              >
                {[
                  "gray",
                  "red",
                  "blue",
                  "green",
                  "yellow",
                  "pink",
                  "orange",
                  "purple",
                ].map((color) => (
                  <div key={color}>
                    <div
                      className="me-2 mt-1 d-flex justify-content-center align-items-center"

                      onClick={() => setUpdatedGroup({ ...updatedGroup, color: color })}



                      style={{
                        backgroundColor: COLORS.GROUP[color],
                        height: "25px",
                        width: "25px",
                        borderRadius: "6px",
                      }}

                    >
                      {updatedGroup.color === color && (
                        <FaCheck style={{ color: COLORS.FontGray }} />
                      )}

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                The confidence threshold that will be used to identify people in
                this group
              </Tooltip>
            }
          >
            <div
              className="d-flex justify-content-start  align-items-center row mt-3"
              style={{
                fontSize: "18px",
              }}
            >
              <label className="col-3 col-md-4">Group Confidence</label>
              <div className="col-12 col-md-8 mx-0 px-0">
                <CustomRangeSlider

                  min={0}
                  max={100}
                  value={updatedGroup.conf}
                  onChange={(changeEvent) =>
                    setUpdatedGroup({ ...updatedGroup, conf: changeEvent.target.value })
                  }
                />
              </div>
            </div>
          </OverlayTrigger>

          <div
            className="d-flex justify-content-start  align-items-center row mb-3 mt-5"
            style={{
              fontSize: "18px",
            }}
          >
            <button
              className="sr-btn"
              disabled={checkInput() !== "ok" || !updatedGroup.name}
              onClick={isEdit ? handleEditGroup : handleCreatGroup}
            >
              {isUpdating ? (
                <FaSpinner className="me-2 fa-spin" />
              ) : (
                <FaSave className="me-2" />
              )}
              <span>SAVE</span>
            </button>

            {checkInput() !== "ok" && (
              <div
                className="text-center mt-1"
                style={{
                  fontSize: "12px",
                  color: COLORS.red,
                }}
              >
                {checkInput()
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PeopleCreateGroupPopup;

