import React from "react";
import { FaTrash } from "react-icons/fa";
import Swal from "sweetalert2/src/sweetalert2.js";
import { COLORS } from "../../assets/colors";

function NotificationsWeb(props) {
  const { notificationsSettings, setNotificationsSettings } = props;

  function addEmail() {
    Swal.fire({
      title: "Add HTTPS URL",
      input: "text",
      inputPlaceholder:
        "https://myapp.myorg.com/face-recognition-notifications....",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Add URL",
      showLoaderOnConfirm: true,
      // inputValidator: (value) => {
      //   if (value.startsWith("http://")) {
      //     return Swal.showValidationMessage(
      //       `Unsecured connection! Please ensure that use is made only within a local area network`
      //     );
      //   }
      //   if (!value.startsWith("http")) {
      //     return Swal.showValidationMessage(`Enter valid https URL`);
      //   }
      // },
    }).then((result) => {
      if (result.value) {
        setNotificationsSettings({
          ...notificationsSettings,
          web_notification_urls: [
            ...notificationsSettings.web_notification_urls,
            result.value,
          ],
        });
      }
    });
  }

  return (
    <div
      className="p-2 m-2"
      style={{
        border: "1px solid",
        borderRadius: "8px",
        borderColor: COLORS.Gray800,
      }}
    >
      <div>
        {notificationsSettings.web_notification_urls &&
          notificationsSettings.web_notification_urls.map((url, i) => (
            <div
              key={`url-${i}`}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div>{url}</div>
              <div>
                <FaTrash
                  className="mx-3 pointer"
                  onClick={() =>
                    setNotificationsSettings({
                      ...notificationsSettings,
                      web_notification_urls:
                        notificationsSettings.web_notification_urls.filter(
                          (e, j) => j !== i
                        ),
                    })
                  }
                />
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-center">
          <button className="sr-outline-btn" onClick={addEmail}>
            Add URL
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotificationsWeb;
