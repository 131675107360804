import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { COLORS } from "../../../assets/colors";
import { get } from "../../../faceit-api/FaceitApi";
import FiqFace from "./FiqFace";
import MainContainer from "../../main-menu/MainContainer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const RESOURCE = "fiq";

function FiqResults() {
  const [faces, setFaces] = useState(null);
  const [reload, setReload] = useState(false);
  const [nextPage, setNextPage] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPredictPage = pathname.endsWith("/predict");
  const getNextPage = () => {
    if (!nextPage) return;
    get(nextPage.slice(nextPage.indexOf(RESOURCE))).then((response) => {
      setNextPage(response.next);
      if (response.results.length > 0) {
        setFaces([...faces, ...response.results]);
      }
    });
  };

  useEffect(() => {
    get(RESOURCE).then((response) => {
      if (response.status === 1) {
        setFaces(response.results);
        setNextPage(response.next);
      }
    });
  }, [reload, pathname]);

  return (
    <MainContainer>
      <div className="mt-3">
        <div
          className="d-flex fw-bold mb-2 align-items-center justify-content-between"
          style={{ color: COLORS.Gray600 }}
        >
          <span className=" fs-4">Face Image Quality</span>
          <button
            className="sr-btn"
            style={{ width: "fit-content" }}
            onClick={() =>
              isPredictPage ? navigate("/fiq") : navigate("/fiq/predict")
            }
          >
            {isPredictPage ? "Results" : "Predict"}
          </button>
        </div>
        {!isPredictPage && (
          <>
            <div className="container-fluid d-flex row m-auto">
              {faces &&
                faces.map((face, i) => (
                  <div className=" m-3 col" key={i}>
                    <FiqFace
                      fiqFace={face}
                      i={i}
                      reload={() => setReload(!reload)}
                    />
                  </div>
                ))}
            </div>
            <div className="text-center col-10 col-md-6 col-lg-4 mt-4 m-auto">
              {nextPage && (
                <button className="sr-btn" onClick={() => getNextPage()}>
                  <FaEye className="me-2" />
                  SHOW MORE
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <Outlet />
    </MainContainer>
  );
}

export default FiqResults;
