import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import "./styles.css";
import Swal from "sweetalert2/src/sweetalert2.js";
import { renderToString } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { FIQ_STATICS_URL, post } from "../../../faceit-api/FaceitApi";
import { FaCloudUploadAlt, FaSave, FaTimes } from "react-icons/fa";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const FiqPredict = (props) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const buildCroppedImage = useCallback(async () => {
    try {
      const imageBase64 = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(imageBase64);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setImageSrc(null);
  }, []);

  const saveImageCrop = () => {
    buildCroppedImage();
  };

  const FiqResponse = (props) => {
    const { faces } = props;

    return (
      <div>
        {faces.map((face, i) => (
          <div key={i}>
            <div>
              <div>{face.label + " - " + face.conf}</div>
              <img
                src={FIQ_STATICS_URL + face.image}
                alt={"fiq-face-" + i}
                height="150"
                width="150"
                style={{ borderRadius: "4px" }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!croppedImage) return;
    const SaveImagePopup = () => {
      return (
        <div>
          <img
            height="350"
            src={croppedImage}
            alt="cropped"
            style={{ borderRadius: "6px" }}
          />
        </div>
      );
    };
    Swal.fire({
      title: "Send this image to quality check",
      html: renderToString(<SaveImagePopup />),
      confirmButtonText: "Send",
      showCancelButton: true,
    }).then((send) => {
      if (send.value) {
        post("fiq", {
          image: croppedImage.replace("data:image/jpeg;base64,", ""),
        })
          .then((response) => {
            if (response.status === 1) {
              Swal.fire({
                title: "Results:",
                html: renderToString(<FiqResponse faces={response} />),
                confirmButtonText: "Ok",
              });
            } else {
              Swal.fire({
                title: "Oops... 😕",
                icon: "error",
                text: response.message,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Oops... 😕",
              icon: "error",
              text: err,
            });
          })
          .finally(() => {
            navigate("/fiq");
          });
      }
    });
  }, [croppedImage, navigate]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  return (
    <div className="mt-3">
      {imageSrc ? (
        <div>
          <div className="container row mb-3">
            <span className="text-end ms-5">
              <button
                className="btn btn-outline-secondary fw-bold"
                onClick={onClose}
              >
                <FaTimes className="" />
              </button>
            </span>
          </div>
          <div
            className="m-auto"
            style={{
              position: "relative",
              height: "350px",
              width: "70%",
              borderRadius: "12px",
            }}
          >
            <Cropper
              objectFit="vertical-cover"
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 4}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="container">
            <div className="mx-5 my-3 d-flex  row justify-content-center ">
              <div className="col-12 col-md-8">
                <div className="">
                  <Typography variant="overline">Zoom</Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="">
                  <Typography variant="overline">Rotation</Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div>
              </div>
              <div className="my-5 col-6">
                <button className="sr-btn" onClick={saveImageCrop}>
                  <FaSave className="me-3" />
                  SAVE
                </button>
              </div>
            </div>
          </div>
          {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
        </div>
      ) : (
        <div className="container d-flex align-items-center justify-content-center mt-5">
          <div>
            <label
              className="custom-file-upload d-flex align-items-center"
              style={{ width: "fit-content" }}
            >
              <input
                type="file"
                multiple="false"
                onChange={onFileChange}
                accept="image/*"
              />
              <FaCloudUploadAlt className="me-md-3 d-none d-md-inline" />
              Select image file
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default FiqPredict;
