import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import { COLORS } from "../../assets/colors";
import { exportLogs } from "../../faceit-api/FaceitApi";
import MainContainer from "../main-menu/MainContainer";
import ContainersTable from "./ContainersTable";
import { showErrorDialog } from "../../popups/opretaions";
import { FaDownload, FaSpinner } from "react-icons/fa";

function SystemSettings() {
  const { webSettings } = useContext(DataContext);
  const [isExporting, setIsExporting] = useState(false);
  const { userInfo } = useContext(DataContext);

  const exportLogsToZip = () => {
    setIsExporting(true);
    exportLogs()
      .then((response) => response.blob())
      .then((blob) => {
        setIsExporting(false);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Logs.zip`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.error(err);
        showErrorDialog("Failed to export logs");
        setIsExporting(false);
      });
  };

  return (
    <MainContainer>
      <div className="">
        <div className="fs-4 fw-bold my-2">System Services</div>
        <div style={{ color: COLORS.Gray100 }}>
          <span className="fw-bolder me-2">Edge ID: </span>
          <span>{webSettings?.edge_id}</span>
        </div>
        {webSettings?.is_hobbit && userInfo?.is_superuser && (
          <div className="col mb-1 d-flex align-items-end  mt-2">
            <div
              className="d-flex align-items-center justify-content-center "
              style={{
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={exportLogsToZip}
            >
              {isExporting ? (
                <FaSpinner className="fa-spin me-1" />
              ) : (
                <FaDownload className="me-2 " />
              )}
              Export logs
            </div>
          </div>
        )}

        <div>
          {webSettings?.is_hobbit ? (
            <ContainersTable />
          ) : (
            <div className="m-5 fs-3 fw-bold">COMING SOON...</div>
          )}
        </div>
      </div>
    </MainContainer>
  );
}

export default SystemSettings;
