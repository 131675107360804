import React from "react";
import "./CustomCheckbox.css";

function CustomCheckbox(props) {
  const { checked, onChange, className, disabled } = props;
  return (
    <div className={className}>
      <label
        className={`custom-checkbox-container ${disabled ? "sr-disabled" : ""}`}
      >
        <input
          disabled={disabled}
          type="checkbox"
          role="button"
          aria-expanded="false"
          checked={checked}
          onChange={onChange}
        />
        <span className={`checkmark ${disabled ? "sr-disabled" : ""}`}></span>
      </label>
    </div>
  );
}

export default CustomCheckbox;
