import React from "react";
import { FaExclamationTriangle, FaThumbsUp, FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import PersonAddResultInputSection from "./PersonAddResultInputSection";
import PersonLowQualityCard from "./PersonLowQualityCard";
import PersonSimilarityCard from "./PersonSimilarityCard";

function PersonAddResult(props) {
  const { setProccessResults, proccessResults, result, groupsOptions, person } =
    props;

  const changeResultValue = (key, value) => {
    var tempResults = [...proccessResults];
    tempResults.find((res) => res.image === result.image)[key] = value;
    setProccessResults(tempResults);
  };

  return (
    <div
      className="p-2 m-2 w-100"
      style={{
        position: "relative",
        backgroundColor: COLORS.Gray600,
        borderRadius: "8px",
      }}
    >
      <FaTimes
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
        onClick={() =>
          setProccessResults(
            proccessResults.filter((r) => r.image !== result.image)
          )
        }
      />
      <img
        src={`${IMAGE_BASE_PATH}${result.image}`}
        alt={result.name}
        width="120"
        height="120"
        style={{ borderRadius: "8px" }}
      />
      {(result.approved || result.isGoodQuality) && !result.similarity && (
        <PersonAddResultInputSection
          changeResultValue={changeResultValue}
          result={result}
          groupsOptions={groupsOptions}
          person={person}
        />
      )}
      <div className="mt-2">
        <div
          className="row px-3 mt-2 align-items-center "
          style={{
            fontSize: "14px",
          }}
        >
          {result.approved && (
            <div className="d-flex justify-content-center align-items-center">
              <FaExclamationTriangle className="me-2" />
              Manually approved
            </div>
          )}
          {result.isGoodQuality && !result.similarity && !result.approved && (
            <div className="d-flex justify-content-center  align-items-center">
              <FaThumbsUp className="me-2" />
              Good face image quality
            </div>
          )}
          {result.similarity && !result.approved && (
            <PersonSimilarityCard
              result={result}
              changeResultValue={changeResultValue}
            />
          )}
          {!result.similarity && !result.isGoodQuality && !result.approved && (
            <PersonLowQualityCard
              result={result}
              changeResultValue={changeResultValue}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonAddResult;
