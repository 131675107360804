import React, { useState } from "react";
import { RestrictedTime } from "../../types/Camera";
import { Modal } from "react-bootstrap";
import { Flex } from "../elements/flex/Flex";
import { FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import Select from "react-select";
import { TimeInput } from "../elements/input/TimeInput";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { MainButton } from "../elements/buttons/MainButton";
import { BlackButton } from "../elements/buttons/BlackButton";

type Props = {
  onClose: () => void;
  onSave: (restrictedTime: RestrictedTime) => void;
};

const daysOptions = [
  { value: "all", label: "All" },
  { value: "sunday", label: "Sunday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
];

export const RestrictedTimesModal = ({ onClose, onSave }: Props) => {
  const [day, setDay] = useState<RestrictedTime["day"]>("all");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [allDay, setAllDay] = useState<boolean>(false);

  const validate = () => {
    if (allDay) {
      return true;
    }
    if (!startTime || !endTime) {
      return false;
    }
    return true;
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      fullscreen={"md-down"}
      style={{
        color: COLORS.FontGray,
      }}
    >
      <Flex column w100>
        <Flex align="center" justify="between" gap="8px" padding="24px">
          <label className="fs-4">Create Restricted Time Rule</label>
          <FaTimes onClick={onClose} className="pointer" size={"24px"} />
        </Flex>
        <Flex
          column
          padding="24px"
          gap="16px"
          style={{
            background: COLORS.Gray700,
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Flex align="center" gap="8px">
            <label style={{ width: "100px" }}>Select Day</label>
            <div style={{ width: "300px" }}>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Day"
                value={daysOptions.find((o) => o.value === day)}
                onChange={(e) => setDay(e?.value as RestrictedTime["day"])}
                options={daysOptions}
              />
            </div>
          </Flex>
          <Flex align="center" gap="8px">
            <label style={{ width: "100px" }}>All Day</label>
            <SwitchCheckbox
              checked={allDay}
              onChange={(e) => setAllDay(e.target.checked)}
            />
          </Flex>
          {!allDay && (
            <>
              <div className="fw-bolder" style={{ fontSize: "14px" }}>
                Set the start and end times using the UTC time zone and the
                24-hour format only.
              </div>
              <Flex align="center" gap="8px">
                <label style={{ width: "100px" }}>Start Time</label>
                <TimeInput
                  initialTime={
                    !!startTime
                      ? {
                          hours: startTime?.split(":")[0],
                          minutes: startTime?.split(":")[1],
                        }
                      : undefined
                  }
                  onTimeChange={(time) =>
                    setStartTime(time.hours + ":" + time.minutes)
                  }
                />
              </Flex>
              <Flex align="center" gap="8px">
                <label style={{ width: "100px" }}>End Time</label>
                <TimeInput
                  initialTime={
                    !!endTime
                      ? {
                          hours: endTime?.split(":")[0],
                          minutes: endTime?.split(":")[1],
                        }
                      : undefined
                  }
                  onTimeChange={(time) =>
                    setEndTime(time.hours + ":" + time.minutes)
                  }
                />
              </Flex>
            </>
          )}
          <Flex w100 justify="between">
            <BlackButton onClick={onClose}>CANCEL</BlackButton>
            <MainButton
              onClick={() =>
                onSave({
                  day,
                  start_time: startTime,
                  end_time: endTime,
                  is_all_day: allDay,
                })
              }
              disabled={!validate()}
              style={{
                width: "fit-content",
              }}
            >
              ADD RULE
            </MainButton>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};
