import React, { useState } from "react";
import TooltipSr from "../general/TooltipSr";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FaEye, FaTrash } from "react-icons/fa";
import CustomCheckbox from "../general/CustomCheckbox";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";

function CameraSettingsTagReader(props) {
  const { camera, setCamera } = props;
  const readerSettings = camera?.tag_reader || {};
  const readersSupported = [
    { value: "tibbo", label: "Tibbo" },
    { value: "er750", label: "ER-750" },
    // Waiting for SafeguardApproval
    // { value: "axis", label: "Axis Video Door Station" },
  ];
  const [newWebhook, setNewWebhook] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const changeValue = (key, value) => {
    var tempSettings = { ...camera.tag_reader };
    tempSettings[key] = value;
    setCamera({ ...camera, tag_reader: tempSettings });
  };

  const addWebhook = () => {
    var tempSettings = { ...camera.tag_reader };
    if (!tempSettings.webhooks) {
      tempSettings.webhooks = [];
    }
    tempSettings.webhooks.push(newWebhook);
    setCamera({ ...camera, tag_reader: tempSettings });
    setNewWebhook("");
  };

  const removeWebhook = (webhook) => {
    var tempSettings = { ...camera.tag_reader };
    if (!tempSettings.webhooks) {
      return;
    }
    tempSettings.webhooks = tempSettings.webhooks.filter((w) => w !== webhook);
    setCamera({ ...camera, tag_reader: tempSettings });
  };

  const animatedComponents = makeAnimated();

  const NotifyCheckbox = (props) => {
    const { notifyType, label } = props;
    return (
      <div className={"d-flex col-12 col-lg-6 mb-3 "}>
        <div className="d-flex align-items-center ms-lg-5">
          <CustomCheckbox
            disabled={!readerSettings.enabled && !camera?.is_android}
            checked={readerSettings[notifyType]}
            id={`edit-cam-tag-${notifyType}`}
            onChange={(e) => changeValue(notifyType, e.target.checked)}
          />
          <label
            className={`ms-2 ${
              !readerSettings.enabled && !camera?.is_android
                ? "sr-disabled"
                : ""
            }`}
            style={{ fontSize: "12px" }}
          >
            {label}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-column gap-8 py-2"
      style={{
        fontSize: "14px",
      }}
    >
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className="text-truncate fw-bolder"
          title="Enable tag reader"
          style={{
            maxWidth: "200px",
            width: "100%",
          }}
        >
          Tag reader enabled
        </div>

        <div className={`w-100 `} style={{ maxWidth: "300px" }}>
          <SwitchCheckbox
            checked={
              readerSettings.enabled ||
              (camera?.is_android &&
                camera?.executor_trigger_on?.includes("tag"))
            }
            onChange={(e) => changeValue("enabled", e.target.checked)}
          />
        </div>
      </div>

      {!camera?.is_android && (
        <TooltipSr
          placement="top"
          title={"Select the type / vendor of the reader device"}
        >
          <div className="d-flex align-items-center justify-content-start gap-8">
            <div
              className={`text-truncate fw-bolder ${
                !readerSettings.enabled ? "sr-disabled" : ""
              }`}
              title="Reader type"
              style={{
                maxWidth: "200px",
                width: "100%",
              }}
            >
              Reader type
            </div>

            <div className="w-100" style={{ maxWidth: "300px" }}>
              <Select
                isDisabled={!readerSettings.enabled}
                className={`react-select-container`}
                classNamePrefix="react-select"
                placeholder="Select..."
                value={readersSupported.find(
                  (c) => c.value === readerSettings.reader_type
                )}
                onChange={(e) => changeValue("reader_type", e.value)}
                options={readersSupported}
                theme="dark"
                components={animatedComponents}
              />
            </div>
          </div>
        </TooltipSr>
      )}
      {!camera?.is_android &&
        ["tibbo", "er750"].includes(readerSettings.reader_type) && (
          <TooltipSr
            placement="top"
            title="The port of the reader device, make sure this port is defined in the reader device settings, and is not already in use of another camera"
          >
            <div className="d-flex align-items-center justify-content-start gap-8">
              <div
                className={`text-truncate fw-bolder ${
                  !readerSettings.enabled ? "sr-disabled" : ""
                }`}
                title="Port"
                style={{
                  maxWidth: "200px",
                  width: "100%",
                }}
              >
                Port
              </div>
              <div className="w-100">
                <input
                  disabled={!readerSettings.enabled}
                  className="sr-text-input "
                  style={{ width: "80px" }}
                  type="number"
                  id="edit-cam-tag-reader-port"
                  placeholder="1441"
                  value={readerSettings.reader_port || 1441}
                  onChange={(e) => changeValue("reader_port", e.target.value)}
                />
              </div>
            </div>
          </TooltipSr>
        )}

      {!camera?.is_android && ["axis"].includes(readerSettings.reader_type) && (
        <TooltipSr placement="top" title="The IP address of the reader device">
          <div className="d-flex align-items-center justify-content-start gap-8">
            <div
              className={`text-truncate fw-bolder ${
                !readerSettings.enabled ? "sr-disabled" : ""
              }`}
              title=" IP Address"
              style={{
                maxWidth: "200px",
                width: "100%",
              }}
            >
              IP Address
            </div>
            <input
              disabled={!readerSettings.enabled}
              className="sr-text-input w-100"
              style={{
                maxWidth: "300px",
              }}
              type="text"
              id="edit-cam-tag-reader-ip"
              placeholder="192.168.1..."
              value={readerSettings.reader_ip_address}
              onChange={(e) => changeValue("reader_ip_address", e.target.value)}
            />
          </div>
        </TooltipSr>
      )}
      {!camera?.is_android && ["axis"].includes(readerSettings.reader_type) && (
        <TooltipSr
          placement="top"
          title="Username for reader device authentication"
        >
          <div className="d-flex align-items-center justify-content-start gap-8">
            <div
              className={`text-truncate fw-bolder ${
                !readerSettings.enabled ? "sr-disabled" : ""
              }`}
              title="Username"
              style={{
                maxWidth: "200px",
                width: "100%",
              }}
            >
              Username
            </div>
            <input
              disabled={!readerSettings.enabled}
              className="sr-text-input w-100"
              style={{
                maxWidth: "300px",
              }}
              type="text"
              name="reader-username"
              id="edit-cam-tag-reader-username"
              value={readerSettings.username}
              onChange={(e) => changeValue("username", e.target.value)}
            />
          </div>
        </TooltipSr>
      )}
      {!camera?.is_android && ["axis"].includes(readerSettings.reader_type) && (
        <TooltipSr
          placement="top"
          title="Password for reader device authentication"
        >
          <div className="d-flex align-items-center justify-content-start gap-8">
            <div
              className={`text-truncate fw-bolder ${
                !readerSettings.enabled ? "sr-disabled" : ""
              }`}
              title="Password"
              style={{
                maxWidth: "200px",
                width: "100%",
              }}
            >
              Password
            </div>
            <FaEye
              className="col-1"
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
              style={{
                cursor: "pointer",
              }}
            />
            <input
              disabled={!readerSettings.enabled}
              className="sr-text-input w-100"
              style={{
                maxWidth: "300px",
              }}
              type={showPassword ? "text" : "password"}
              name="reader-password"
              id="edit-cam-tag-reader-password"
              value={readerSettings.password}
              onChange={(e) => changeValue("password", e.target.value)}
            />
          </div>
        </TooltipSr>
      )}
      <div
        className={`col-12 col-lg-3 fw-bolder ${
          !readerSettings.enabled && !camera?.is_android ? "sr-disabled" : ""
        }`}
      >
        Notifications
      </div>
      <div className={"row ms-lg-5 "}>
        <NotifyCheckbox
          notifyType="notify_known_tag_id"
          label="Notify on any known tag"
        />
        <NotifyCheckbox
          notifyType="notify_unknown_tag_id"
          label="Notify on unknown tag"
        />
        <NotifyCheckbox
          notifyType="notify_tag_id_match_person"
          label="Notify on tag match person face"
        />
        <NotifyCheckbox
          notifyType="notify_tag_id_mismatch_person"
          label="Notify on tag mismatch person face"
        />
        <NotifyCheckbox
          notifyType="notify_tag_id_unknown_face"
          label="Notify when tag does not have a familiar face"
        />
      </div>
      {!camera?.is_android && (
        <TooltipSr
          placement="top"
          title="List of URLs that will receive notifications"
        >
          <div className="d-flex flex-column gap-8">
            <div className="d-flex align-items-center justify-content-start gap-8">
              <div
                className={`text-truncate fw-bolder ${
                  !readerSettings.enabled ? "sr-disabled" : ""
                }`}
                title="Webhooks for notifications"
                style={{
                  maxWidth: "200px",
                  width: "100%",
                }}
              >
                Webhooks for notifications
              </div>
              <input
                disabled={!readerSettings.enabled}
                className="sr-text-input w-100"
                style={{ maxWidth: "300px" }}
                type="text"
                id="edit-cam-tag-reader-webhooks"
                placeholder="https://domain.com..."
                value={newWebhook}
                onChange={(e) => setNewWebhook(e.target.value)}
              />
            </div>
            <div className="d-flex w-100 justify-content-center">
              <button
                onClick={addWebhook}
                disabled={!readerSettings.enabled}
                className="p-1 sr-outline-btn w-25"
              >
                Add
              </button>
            </div>
          </div>
        </TooltipSr>
      )}

      <div className="mb-4">
        {readerSettings.enabled &&
          !camera?.is_android &&
          readerSettings.webhooks?.map((webhook) => (
            <div className="d-flex align-items-center row mb-2" key={webhook}>
              <div placeholder-div className={`col-12 col-lg-3`}></div>
              <div
                className="col-12 col-lg-8 d-flex align-items-center justify-content-between p-1 px-3"
                style={{
                  border: "1px solid",
                  borderRadius: "6px",
                }}
              >
                <label className="text-truncate">{webhook}</label>
                <div
                  onClick={() => removeWebhook(webhook)}
                  className="ms-1 pointer"
                >
                  <FaTrash />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default CameraSettingsTagReader;
