import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import { get } from "../../faceit-api/FaceitApi";
import MainContainer from "../main-menu/MainContainer";
import LiveViewCameras from "./LiveViewCameras";
import LiveViewIdentifications from "./LiveViewIdentifications";
import LiveViewUnkowns from "./LiveViewUnkowns";

export const isFaceModalOrPopupOpen = () => {
  return (
    document.querySelectorAll(".modal.in").length > 0 ||
    document.querySelectorAll(".face-recognized-modal").length > 0 ||
    document.querySelectorAll(".popup-face").length > 0
  );
};

function LiveView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: cameras } = useApiCamerasData();
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!cameras?.length || !searchParams.get("cameraId")) return;
    setSelectedCameras(
      searchParams
        .get("cameraId")
        .split(",")
        .map((id) => ({
          value: id,
          label: cameras.find((c) => c.id === id)?.name || "",
        })) || []
    );
  }, [cameras, searchParams]);

  useEffect(() => {
    let active = true;
    var delay = 1000;
    const getEvents = (newetEventTime) => {
      if (isFaceModalOrPopupOpen()) {
        setTimeout(() => getEvents(newetEventTime), delay);
        return () => {
          active = false;
        };
      }
      get(
        `faces/events?last_event_time=${newetEventTime}&camera_id=${selectedCameras.map(
          (c) => c.value
        )}`
      )
        .then((response) => {
          var eventTime = new Date();
          // reset the delay in case an error has happened and changed it.
          delay = 1000;
          // now call the API again after 1 second
          if (active) {
            if (response.results && response.results.length > 0) {
              eventTime = new Date(response.results[0].create_at);
              eventTime.setMilliseconds(eventTime.getMilliseconds() + 5);
              setEvents((state) => {
                const lastEventsIds = state.slice(0, 50).map((e) => e.image);
                return response.results.filter(
                  (e) => !lastEventsIds.includes(e.image)
                );
              });
            }
            setTimeout(() => getEvents(eventTime.toISOString()), delay);
          }
        })
        .catch((error) => {
          // exponential backoff here.
          // 1 - the first error will call the API after 2sec
          // 2 - the second error will call the API after 4 sec
          // 3 - the third error will call the API after 8 sec
          // and so on
          console.error("Could not update events. waiting a bit...", error);
          delay = delay * 2;
          setTimeout(() => getEvents(newetEventTime), delay);
        });
    };

    if (selectedCameras.length === 0) return;
    getEvents(new Date().toISOString());
    return () => {
      active = false;
    };
  }, [selectedCameras]);

  return (
    <MainContainer>
      <div
        style={{
          color: COLORS.FontGray,
        }}
      >
        <div className="row">
          <div className="col-12 col-lg-6">
            <LiveViewCameras
              selectedCameras={selectedCameras}
              setSelectedCameras={setSelectedCameras}
              setSearchParams={setSearchParams}
            />
            <LiveViewUnkowns
              selectedCameras={selectedCameras}
              events={events.filter(
                (e) => !e.person_id || e.person_id === "N/A"
              )}
            />
          </div>
          <div className="col-12 col-lg-6">
            <LiveViewIdentifications
              selectedCameras={selectedCameras}
              events={events.filter(
                (e) => e.person_id && e.person_id !== "N/A"
              )}
            />
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

export default LiveView;
