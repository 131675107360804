import React, { useContext } from "react";
import { DataContext } from "../../App";
import MobileMenu from "./MobileMenu";
import Sidebar from "./Sidebar";

function Menu(props) {
  const { activeComponent } = props;
  const { isMobile } = useContext(DataContext);
  if (isMobile) {
    return <MobileMenu activeComponent={activeComponent} />;
  }
  return <Sidebar activeComponent={activeComponent} />;
}

export default Menu;
