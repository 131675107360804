import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function TCPSocketController(props) {
  const { camera, setCamera, onControllerChange, index } = props;

  const onChange = (value, key) => {
    if (setCamera) {
      setCamera({
        ...camera,
        [key]: value,
      });
    } else {
      onControllerChange(index, key, value);
    }
  };

  return (
    <div className="d-flex flex-column gap-8">
      {" "}
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {
              "IP Address of the hardware component responsible for opening the door"
            }
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="IP Address"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            IP Address
          </div>
          <input
            className="sr-text-input w-100"
            style={{ maxWidth: "300px" }}
            type="text"
            placeholder="192.168.1.244..."
            value={camera.executer_ip}
            onChange={(e) => onChange(e.target.value, "executer_ip")}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{"Controller port"}</Tooltip>}
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Port"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Port
          </div>
          <input
            className="sr-text-input w-100"
            style={{ maxWidth: "300px" }}
            type="number"
            placeholder="4730"
            value={camera.executer_port}
            onChange={(e) =>
              onChange(parseInt(e.target.value), "executer_port")
            }
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{"Payload body to send via TCP socket"}</Tooltip>}
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Payload"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Payload
          </div>
          <input
            className="sr-text-input w-100"
            style={{ maxWidth: "300px" }}
            type="text"
            placeholder="Payload bytes to send"
            value={camera.executer_payload}
            onChange={(e) => onChange(e.target.value, "executer_payload")}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}
