import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

type Props = {
  size?: "small" | "medium";
};

export const StyledSwitch = styled.label<Props>`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.size === "small" ? "44px" : "48px")};
  height: ${(props) => (props.size === "small" ? "20px" : "28px")};
`;

export const StyledHiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledSlider = styled.span<Props>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${COLORS.Gray600};
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: ${(props) => (props.size === "small" ? "16px" : "20px")};
    width: ${(props) => (props.size === "small" ? "16px" : "20px")};
    left: 4px;
    bottom: ${(props) => (props.size === "small" ? "2px" : "4px")};
    background-color: ${COLORS.FontGray};
    transition: 0.4s;
    border-radius: 50%;
  }

  ${StyledHiddenCheckbox}:checked + & {
    background: linear-gradient(
      104.64deg,
      ${COLORS.primary} 12.61%,
      #125a5a 74.03%,
      #105656 92.64%
    );
  }

  ${StyledHiddenCheckbox}:disabled + & {
    background: ${COLORS.Gray600};
    cursor: not-allowed;
  }

  ${StyledHiddenCheckbox}:disabled + &:before {
    background: ${COLORS.Gray800};
  }

  ${StyledHiddenCheckbox}:checked:disabled + &:before {
    background: ${COLORS.Gray800};
  }

  ${StyledHiddenCheckbox}:checked + &:before {
    background: linear-gradient(130.6deg, #8dc4c2 0.82%, #519c9a 94.23%);
  }

  ${StyledHiddenCheckbox}:focus + & {
    box-shadow: 0 0 1px ${COLORS.primary};
  }

  ${StyledHiddenCheckbox}:checked + &:before {
    transform: translateX(20px);
  }
`;
