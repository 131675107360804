import { SetStateAction } from "react";
import { Flex } from "../../elements/flex/Flex";
import { SwitchCheckbox } from "../../elements/switch/SwitchCheckbox";

type Props = {
  title: string;
  name: string;
  filters: Record<string, string>;
  setFilters: React.Dispatch<SetStateAction<Record<string, string>>>;
  classes?: string;
  size?: "small" | "medium";
};

export const FilterCheckboxElement = (props: Props) => {
  const { title, name, filters, setFilters, classes, size = "medium" } = props;

  return (
    <Flex column className={classes} gap={"8px"} padding="2px 0">
      <label>{title}</label>
      <SwitchCheckbox
        className="me-2"
        checked={filters[name] === "true"}
        onChange={(e) =>
          e.target.checked
            ? setFilters({
                ...filters,
                [name]: "true",
              })
            : setFilters((oldFilters) => {
                const newFilters = { ...oldFilters };
                delete newFilters[name];
                return newFilters || {};
              })
        }
        size={size}
      />
    </Flex>
  );
};
