// TimeInput.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

// Styled component for the time input wrapper
const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

// Styled component for the input fields
const TimeInputField = styled.input`
  width: 40px;
  padding: 4px;
  border: 1px solid ${COLORS.Gray500};
  border-radius: 4px;
  text-align: center;
  background-color: ${COLORS.Gray800};
  color: ${COLORS.FontGray};

  &:focus {
    outline: none;
    border-color: ${COLORS.Gray100};
  }
`;

// Styled component for colon separator
const ColonSeparator = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${COLORS.Gray500};
`;

type TimeInputProps = {
  onTimeChange?: (time: { hours: string; minutes: string }) => void;
  initialTime?: { hours: string; minutes: string };
};

// Main component
export const TimeInput = ({ onTimeChange, initialTime }: TimeInputProps) => {
  const [hours, setHours] = useState<string>(initialTime?.hours || "");
  const [minutes, setMinutes] = useState<string>(initialTime?.minutes || "");

  // Handle hours input change (validates between 0 and 23)
  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value) && parseInt(value, 10) <= 23) {
      setHours(value);
    }
  };

  // Handle minutes input change (validates between 0 and 59)
  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value) && parseInt(value, 10) <= 59) {
      setMinutes(value);
    }
  };

  // Use effect to trigger the onTimeChange callback whenever hours or minutes change
  useEffect(() => {
    if (!!hours && !!minutes && !!onTimeChange) {
      onTimeChange({ hours, minutes });
    }
  }, [hours, minutes, onTimeChange]);

  return (
    <TimeInputWrapper>
      <TimeInputField
        type="text"
        placeholder="HH"
        value={hours}
        onChange={handleHoursChange}
        maxLength={2}
      />
      <ColonSeparator>:</ColonSeparator>
      <TimeInputField
        type="text"
        placeholder="MM"
        value={minutes}
        onChange={handleMinutesChange}
        maxLength={2}
      />
    </TimeInputWrapper>
  );
};
