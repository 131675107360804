import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";

function CameraSettingsDisplayAndroid(props) {
  const { camera, setCamera } = props;

  const changeDisplayAndroidValue = (key, value) => {
    var tempSettings = { ...camera.display_android };
    tempSettings[key] = value;
    setCamera({ ...camera, display_android: tempSettings });
  };
  const defaultTrue = [
    "privacy_mode_enabled",
    "show_entrance_button",
    "show_exit_button",
  ];

  const isEnabled = (fieldName) => {
    // check if some field of display_android are enable
    if (camera.display_android) {
      if (fieldName in camera.display_android) {
        return camera.display_android[fieldName];
      }
    }
    for (var i = 0; i < defaultTrue.length; i++) {
      if (defaultTrue[i] === fieldName) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      className="d-flex flex-column gap-8 py-2"
      style={{
        fontSize: "14px",
      }}
    >
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className="text-truncate fw-bolder"
          title="Privacy mode"
          style={{
            maxWidth: "230px",
            width: "100%",
          }}
        >
          Privacy mode
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Hide person personal details, like name, description and source
              image
            </Tooltip>
          }
        >
          <div style={{ maxWidth: "300px" }}>
            <SwitchCheckbox
              checked={isEnabled("privacy_mode_enabled")}
              onChange={(e) =>
                changeDisplayAndroidValue(
                  "privacy_mode_enabled",
                  e.target.checked
                )
              }
            />
          </div>
        </OverlayTrigger>
      </div>
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className={`text-truncate fw-bolder `}
          title="Show identification popup"
          style={{
            maxWidth: "230px",
            width: "100%",
          }}
        >
          Show Identification Popup
        </div>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Show identification popup</Tooltip>}
        >
          <div style={{ maxWidth: "300px" }}>
            <SwitchCheckbox
              checked={isEnabled("identification_popup_enabled")}
              onChange={(e) =>
                changeDisplayAndroidValue(
                  "identification_popup_enabled",
                  e.target.checked
                )
              }
            />
          </div>
        </OverlayTrigger>
      </div>

      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className={`text-truncate fw-bolder ${
            isEnabled("identification_popup_enabled") ? "" : "sr-disabled"
          }`}
          title="Display Entrance Button"
          style={{
            maxWidth: "230px",
            width: "100%",
          }}
        >
          Display Entrance Button
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>Display entrance button on identification popup</Tooltip>
          }
        >
          <div style={{ maxWidth: "300px" }}>
            <SwitchCheckbox
              disabled={!isEnabled("identification_popup_enabled")}
              checked={isEnabled("show_entrance_button")}
              onChange={(e) =>
                changeDisplayAndroidValue(
                  "show_entrance_button",
                  e.target.checked
                )
              }
            />
          </div>
        </OverlayTrigger>
      </div>

      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className={`text-truncate fw-bolder ${
            isEnabled("identification_popup_enabled") ? "" : "sr-disabled"
          }`}
          title="Display Exit Button"
          style={{
            maxWidth: "230px",
            width: "100%",
          }}
        >
          Display Exit Button
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>Display exit button on identification popup</Tooltip>
          }
        >
          <div style={{ maxWidth: "300px" }}>
            <SwitchCheckbox
              checked={isEnabled("show_exit_button")}
              onChange={(e) =>
                changeDisplayAndroidValue("show_exit_button", e.target.checked)
              }
              disabled={!isEnabled("identification_popup_enabled")}
            />
          </div>
        </OverlayTrigger>
      </div>

      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className={`text-truncate fw-bolder  ${
            isEnabled("identification_popup_enabled") ? "" : "sr-disabled"
          }`}
          title="Display person description"
          style={{
            maxWidth: "230px",
            width: "100%",
          }}
        >
          Display person description
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Display person description on identification popup
            </Tooltip>
          }
        >
          <div style={{ maxWidth: "300px" }}>
            <SwitchCheckbox
              checked={isEnabled("show_person_description")}
              onChange={(e) =>
                changeDisplayAndroidValue(
                  "show_person_description",
                  e.target.checked
                )
              }
              disabled={!isEnabled("identification_popup_enabled")}
            />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
}

export default CameraSettingsDisplayAndroid;
