import { Flex } from "../../elements/flex/Flex";

export const FilterTextElement = (props) => {
  const { title, name, type, filters, setFilters } = props;
  return (
    <Flex
      column
      style={{
        width: "250px",
      }}
    >
      <label>{title}</label>
      <input
        type={type || "text"}
        className="sr-text-input"
        value={filters[name] && filters[name] !== "null" ? filters[name] : ""}
        onChange={(e) => setFilters({ ...filters, [name]: e.target.value })}
      />
    </Flex>
  );
};
