import { Button } from "@mui/material";
import React from "react";
import { FaSave, FaSpinner, FaTimes, FaTrashAlt } from "react-icons/fa";
import ImageUploading from "react-images-uploading";
import { COLORS } from "../../assets/colors";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import PersonAddResult from "./PersonAddResult";
import PersonBadImage from "./PersonBadImage";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import {
  useApiAddPersonFaces,
  useApiCreateBulkPersons,
  useApiPersonsData,
} from "../../context/personsContext";
import { useApiCheckFaces } from "../../context/facesContext";
import { useIsMobile } from "../../utils/hooks";

const SIMILARITY = 0.5;

function PeopleAddPopup(props) {
  const { close, closeParent, groupsOptions, person } = props;
  const isMobile = useIsMobile();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();

  const [images, setImages] = React.useState([]);
  const [processResultStatus, setProcessResultStatus] =
    React.useState("pending");
  const [processResults, setProcessResults] = React.useState([]);
  const [badImages, setBadImages] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");

  const { mutate: createBulkPersons, isLoading: isProcessingBulk } =
    useApiCreateBulkPersons();
  const { mutate: addFaces, isLoading: isProcessingAddFaces } =
    useApiAddPersonFaces();
  const { mutate: checkFaces, isLoading: isProcessingCheckFaces } =
    useApiCheckFaces();

  const isProcessing =
    isProcessingBulk || isProcessingAddFaces || isProcessingCheckFaces;

  const maxNumber = 30;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const isGoodQuality = (result) => {
    return result.fiq && result.fiq.label === "good";
  };

  const cannotSave = () => {
    const approvedOnly = processResults.filter((r) => r.approved);
    // Disable save button if there any approved image that there name or group are not set
    if (approvedOnly.filter((res) => !res.group || !res.name).length > 0) {
      setErrorMsg("Not all names have value");
      return true;
    }
    // Disable save button if people have the same name
    if (
      new Set(
        approvedOnly.map((r) =>
          r.name.replaceAll(" ", "_").toLocaleLowerCase().trim()
        )
      ).size !== approvedOnly.length
    ) {
      setErrorMsg("People cannot have the same name");
      return true;
    }
    setErrorMsg("");
    return (
      approvedOnly.length === 0 &&
      processResults.filter((r) => isGoodQuality(r))?.length === 0
    );
  };

  const getDefaultName = (names, name, index) => {
    const cleanName = name
      .replace(".png", "")
      .replace(".jpeg", "")
      .replace(".jpg", "");
    if (names.filter((n) => n === name).length > 1) {
      return `${cleanName}-${index}`;
    }
    return cleanName;
  };

  const getSimilarity = (result) => {
    if (persons.length === 0) {
      return null;
    }
    if (!result.closets) return null;
    if (result.closets.conf > SIMILARITY) {
      return persons.find((p) => p.id === result.closets.person_id);
    }
    return null;
  };

  const onSaveUploads = () => {
    checkFaces({
      facesData: {
        images: images.map((img) => {
          return { data: img.data_url.split(",")[1], name: img.file.name };
        }),
      },
      onSuccessCallback: (resp) => {
        setProcessResultStatus("ok");
        setProcessResults(
          resp.results
            .filter((r) => r.status === 1)
            .map((res, i) => {
              const similarity = getSimilarity(res);
              return {
                ...res,
                ...{
                  name: getDefaultName(
                    resp.results.map((r) => r.name),
                    res.name,
                    i
                  ),
                  group: groupsOptions[0] ? groupsOptions[0].value : "",
                  isGoodQuality: isGoodQuality(res),
                  noIssue: isGoodQuality(res) && !similarity,
                  approved: false,
                  similarity: similarity,
                },
              };
            })
        );
        setBadImages(resp.results.filter((r) => r.status !== 1));
      },
      onErrorCallback: () => showErrorDialog("Failed check faces"),
    });
  };

  const updateFaces = () => {
    addFaces({
      personId: person.id,
      personData: {
        faces: processResults
          .filter((res) => res.approved || res.noIssue)
          .map((res) => {
            return {
              image: res.image,
              vectors: res.vectors,
              pose_vector: res.fiq["head-pose"],
              bbox: res.bbox,
            };
          }),
      },
      onSuccessCallback: () =>
        showSuccessDialog(`Faces added successfully`).then(() => {
          close();
          if (!!closeParent) {
            closeParent();
          }
        }),
      onErrorCallback: () => showErrorDialog(`Failed to add faces`),
    });
  };

  const onSaveResults = () => {
    if (person) {
      return updateFaces();
    }

    createBulkPersons({
      personsData: {
        persons: processResults
          .filter((res) => res.approved || res.noIssue)
          .map((res) => {
            return {
              group: res.group,
              person_name: res.name,
              description: res.description,
              faces: [res.image],
              vectors: res.vectors,
              pose_vector: res.fiq["head-pose"],
              bbox: res.bbox,
            };
          }),
      },
      onSuccessCallback: (resp) =>
        showSuccessDialog(`${resp.count} people added successfully`).then(
          close
        ),
      onErrorCallback: (resp) =>
        showErrorDialog(resp.message || `Failed to add people`),
    });
  };

  return (
    <div
      className="h-100"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray800,
        borderRadius: "12px",
        zIndex: 1200,
      }}
    >
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        id="files-input-people"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          // onImageUpdate,
          onImageRemove,
          // isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper p-2">
            <div className="d-flex ">
              <span
                className="text-end"
                onClick={close}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "20px",
                }}
              >
                <FaTimes className="fs-4" />
              </span>
              <span
                className="m-auto mt-4 mt-md-2 mb-3"
                style={{
                  fontSize: "24px",
                  lineHeight: "33px",
                }}
              >
                {person
                  ? `Add faces to "${person.person_name}"`
                  : "Add people to recognize"}
              </span>
            </div>
            {processResultStatus !== "ok" && (
              <>
                <div
                  className="mx-auto"
                  style={{
                    cursor: "pointer",
                    height: "150px",
                    width: "75%",
                    border: "dashed 2px",
                    borderColor: COLORS.FontGray,
                  }}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    {isMobile
                      ? "Upload Image"
                      : "Drag & Drop your file(s) or click here"}
                  </div>
                </div>
                <div className="d-flex row my-3 justify-content-center">
                  {imageList.map((image, index) => (
                    <div className="col mb-2 d-flex justify-content-center">
                      <div
                        key={index}
                        className="p-2 me-2"
                        style={{
                          width: "fit-content",
                          backgroundColor: COLORS.Gray600,
                          borderRadius: "8px",
                        }}
                      >
                        <div className="image-item__btn-wrapper d-flex justify-content-between mb-1">
                          <FaTimes
                            style={{ cursor: "pointer" }}
                            onClick={() => onImageRemove(index)}
                          />
                        </div>
                        <img
                          src={image["data_url"]}
                          alt={`face-${index}`}
                          width="80"
                          height={"80"}
                          style={{ borderRadius: "8px" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            <div>
              {!isProcessing &&
                processResultStatus !== "ok" &&
                imageList &&
                imageList.length > 0 && (
                  <div className="d-flex justify-content-around">
                    <button
                      className="sr-outline-btn"
                      onClick={onImageRemoveAll}
                    >
                      <FaTrashAlt className="me-2" />
                      Remove all images
                    </button>

                    <button
                      className="sr-outline-btn"
                      onClick={onSaveUploads}
                      disabled={isFetchingPersons}
                    >
                      {isFetchingPersons ? (
                        <FaSpinner className="me-2 fa-spin" />
                      ) : (
                        <FaSave className="me-2" />
                      )}
                      SAVE
                    </button>
                  </div>
                )}
            </div>
            <div className="text-center">
              {isProcessing && (
                <>
                  <FaSpinner className="fa-spin mb-2 fs-3" />
                  <div>Checking the face images quality...</div>
                </>
              )}
            </div>
            <div className="text-center">
              {processResultStatus === "error" && (
                <>
                  <div className="fw-bold">Failed to check images... 😥</div>
                </>
              )}
            </div>
            <div className="">
              {processResultStatus === "ok" && (
                <>
                  <div className="d-flex row justify-content-around mx-auto">
                    {processResults
                      .filter((res) => res.isGoodQuality && !res.similarity)
                      .map((result) => (
                        <div
                          className="col text-center "
                          key={result.image}
                          style={{
                            maxWidth: "33%",
                            minWidth: "270px",
                          }}
                        >
                          <PersonAddResult
                            setProccessResults={setProcessResults}
                            proccessResults={processResults}
                            result={result}
                            groupsOptions={groupsOptions}
                            person={person}
                          />
                        </div>
                      ))}
                  </div>

                  {processResults.filter((res) => res.similarity).length >
                    0 && (
                    <div>
                      <hr />
                      <h5 className="text-center">Images with similarities</h5>
                    </div>
                  )}
                  <div className="d-flex row justify-content-around  mx-auto">
                    {processResults
                      .filter((res) => res.similarity)
                      .map((result) => (
                        <div
                          className="col text-center "
                          style={{
                            maxWidth: "33%",
                            minWidth: "300px",
                          }}
                          key={result.image}
                        >
                          <PersonAddResult
                            setProccessResults={setProcessResults}
                            proccessResults={processResults}
                            result={result}
                            groupsOptions={groupsOptions}
                            person={person}
                          />
                        </div>
                      ))}
                  </div>

                  {processResults.filter(
                    (res) => !res.isGoodQuality && !res.similarity
                  ).length > 0 && (
                    <div>
                      <hr />
                      <h5 className="text-center">Low quality images</h5>
                    </div>
                  )}
                  <div className="d-flex row justify-content-around  mx-auto">
                    {processResults
                      .filter((res) => !res.isGoodQuality && !res.similarity)
                      .map((result) => (
                        <div
                          className="col text-center "
                          style={{
                            maxWidth: "33%",
                            minWidth: "300px",
                          }}
                          key={result.image}
                        >
                          <PersonAddResult
                            setProccessResults={setProcessResults}
                            proccessResults={processResults}
                            result={result}
                            groupsOptions={groupsOptions}
                            person={person}
                          />
                        </div>
                      ))}
                  </div>
                  {badImages.length > 0 && (
                    <div>
                      <hr />
                      <h5 className="text-center">Bad images</h5>
                    </div>
                  )}
                  <div className="d-flex row justify-content-around  mx-auto">
                    {badImages.map((result) => (
                      <div
                        className="col text-center "
                        style={{
                          maxWidth: "33%",
                        }}
                        key={result.image}
                      >
                        <PersonBadImage
                          badImages={badImages}
                          setBadImages={setBadImages}
                          result={result}
                          image={images.find(
                            (img) => img.file.name === result.name
                          )}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="col-8 col-lg-3 mx-auto my-3">
                    <button
                      className="sr-btn"
                      onClick={onSaveResults}
                      disabled={cannotSave()}
                    >
                      <FaSave className="me-2" />
                      SAVE
                    </button>
                    <div className="text-center" style={{ color: COLORS.red }}>
                      {errorMsg}
                    </div>
                  </div>
                  <Button
                    startIcon={<MdOutlineArrowBackIosNew />}
                    onClick={() => setProcessResultStatus("back")}
                    // variant="contained"
                    color="secondary"
                  >
                    Back
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default PeopleAddPopup;
