import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import { HEADER_HEIGHT } from "./Dashboard";
import { useIsMobile } from "../../utils/hooks";

function GrayBox(props) {
  const { icon, number, title, color, link } = props;
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <div
      className={`shadow-hover pointer `}
      style={{
        width: isMobile ? "calc(50% - 20px)" : "100%",
        padding: "20px 12px 20px 12px",
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
        height: HEADER_HEIGHT,
      }}
      onClick={() => (link ? navigate(link) : {})}
    >
      <div
        className="d-flex align-items-center justify-content-center gap-2"
        style={{
          color: color,
          fontSize: "26px",
          lineHeight: "30px",
        }}
      >
        <div>{icon}</div>
        <div>{number}</div>
      </div>
      <div
        className="d-flex align-items-center justify-content-center mt-1"
        style={{
          fontSize: "14px",
          lineHeight: "16px",
        }}
      >
        {title}
      </div>
    </div>
  );
}

export default GrayBox;
