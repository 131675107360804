import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { get } from "../../faceit-api/FaceitApi";

function LogsModal(props) {
  const { containerName, close } = props;
  const [logs, setLogs] = useState("");

  useEffect(() => {
    if (!containerName) return;
    get(`docker/logs?container=${containerName}`).then((resp) => {
      if (resp.status === 1) {
        setLogs(resp.logs);
      }
    });

    const appendTail = () => {
      setTimeout(appendTail, 5000);
      get(`docker/logs?tail=true&container=${containerName}`).then((resp) => {
        if (resp.status === 1) {
          setLogs((state) => resp.logs + state);
        }
      });
    };
    setTimeout(appendTail, 5000);
  }, [containerName]);

  return (
    <div
      className="h-100"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <div
        className="d-flex pt-2 px-2 "
        style={{
          backgroundColor: COLORS.Gray800,
          borderRadius: "8px 8px 0 0",
        }}
      >
        <span
          className="mt-4 mt-md-2 mb-3 ms-2 text-start"
          style={{
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          <div className="d-flex align-items-center">
            <span>{containerName} Logs</span>
          </div>
        </span>
        <span
          className="text-end"
          onClick={close}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "20px",
            top: "12px",
          }}
        >
          <FaTimes className="fs-3" />
        </span>
      </div>
      <div className="m-3" style={{ height: "800px" }}>
        <div
          className="w-100 h-100"
          style={{
            overflowY: "scroll",
            whiteSpace: "pre",
          }}
        >
          {logs}
        </div>
      </div>
    </div>
  );
}

export default LogsModal;
