import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiPersonsData } from "../../context/personsContext";
import { getThresholdDate } from "../../utils/helper";
import DashboardModal from "./DashboardModal";

function DashboardPieChart(props) {
  const { dateRange } = props;
  const { data: cameras } = useApiCamerasData();
  const { data: persons, isFetching: isFetchingPersons } = useApiPersonsData();
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [modalFaces, setModalFaces] = useState([]);
  var dateThreshold = getThresholdDate(dateRange);

  const chartData = cameras.map((camera) => {
    return {
      name: camera.name,
      value: persons.filter(
        (p) =>
          p.last_location &&
          p.last_seen &&
          p.last_location === camera.id &&
          new Date(p.last_seen) > dateThreshold
      ).length,
    };
  });

  const chartColors = [
    COLORS.LightSky,
    COLORS.Indigo,
    COLORS.primary,
    COLORS.GROUP.orange,
  ];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value === 0) return null;

    return (
      <svg width="100%" height="100%">
        <defs>
          <filter x="-0.15" y="-0.25" width="1.25" height="1.5" id="solid">
            <feFlood floodColor={COLORS.Gray600 + "d3"} result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <text
          filter="url(#solid)"
          fill={COLORS.FontGray}
          x={x}
          y={y}
          fontSize="16"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${value} people`}
        </text>
      </svg>
    );
  };

  const handlePieChartClicked = (data, index) => {
    const clickedCamera = data.name;
    setModalSubtitle(
      `People seen on "${clickedCamera}" starting from ${dateThreshold.toLocaleDateString()}`
    );
    setModalTitle(clickedCamera);

    const camera = cameras.find((c) => c.name === clickedCamera);

    setModalFaces(
      persons
        .filter(
          (p) =>
            p.last_location === camera.id &&
            p.faces?.length &&
            new Date(p.last_seen) > dateThreshold
        )
        ?.map((p) => ({
          image: p.faces[0],
          is_deleted: false,
          create_at: p.last_seen,
          person_id: p.id,
        }))
    );
    setShowDashboardModal(true);
  };

  return (
    <div
      className="mt-3"
      style={{
        width: "100%",
        padding: "20px 12px 20px 12px",
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <Modal
        show={showDashboardModal}
        onHide={() => setShowDashboardModal(false)}
        keyboard={false}
        size="md"
        fullscreen={"md-down"}
      >
        <DashboardModal
          close={() => setShowDashboardModal(false)}
          faces={modalFaces}
          title={modalTitle}
          subtitle={modalSubtitle}
        />
      </Modal>
      <div className=" w-100 mb-2">
        <span className="fs-5 mx-2">People last location</span>
      </div>{" "}
      <div style={{ width: "100%", height: "280px" }}>
        {isFetchingPersons ? (
          <div className="text-center h-100 mt-1 d-flex align-items-center justify-content-center">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400} cursor="pointer">
              {cameras.map((c, i) => (
                <defs key={`defs-${c.id}`}>
                  <linearGradient
                    id={`camColor-${c.id}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={chartColors[i % chartColors.length]}
                      stopOpacity={1}
                    />
                    <stop
                      offset="95%"
                      stopColor={chartColors[i % chartColors.length]}
                      stopOpacity={0.5}
                    />
                  </linearGradient>
                </defs>
              ))}
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{
                  paddingLeft: "20px",
                }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: COLORS.Gray800,
                  borderWidth: "0",
                  borderRadius: "8px",
                }}
                labelStyle={{
                  color: COLORS.FontGray,
                }}
                itemStyle={{
                  color: COLORS.FontGray,
                }}
              />
              <Pie
                data={chartData}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={60}
                // outerRadius={90}
                isAnimationActive={false}
                paddingAngle={3}
                label={renderCustomizedLabel}
                cornerRadius={8}
                onClick={handlePieChartClicked}
              >
                {cameras.map((c) => (
                  <Cell
                    key={`cell-${c.id}`}
                    fill={`url(#camColor-${c.id})`}
                    strokeWidth={0}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
      <div style={{ width: "15%" }}></div>
    </div>
  );
}
export default DashboardPieChart;
