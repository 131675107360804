// <FaceImage face={face} size="140px" onclick = null />
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import IconUser from "../../assets/icons/user.svg";
import { COLORS } from "../../assets/colors";

export function FaceImage(props) {
  const {
    face,
    size,
    onclick,
    alt,
    borderRadius,
    key,
    moreStyle,
    isTrackMode = false,
  } = props;
  let number = parseInt(size, 10);
  let iconSize = number * 0.8 + "px";
  return (
    <>
      {face.is_deleted ? (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            width: { size },
            height: { size },
            borderRadius: borderRadius ?? "8px",
            backgroundColor: "#E7ECED",
          }}
        >
          <img
            height={iconSize}
            width={iconSize}
            src={IconUser}
            alt={alt ?? `face-rec-${face.image}`}
            style={{ ...moreStyle, cursor: onclick ? "pointer" : "default" }}
            onClick={onclick ?? null}
          />
          <div
            className="text-truncate fw-bolder"
            style={{
              fontSize: number < 110 ? "8px" : number < 141 ? "9px" : "10px",
              color: COLORS.BgBlack,
            }}
          >
            IMAGE NOT AVAILABLE
          </div>
        </div>
      ) : (
        <img
          key={key ?? null}
          height={size}
          width={size}
          style={{
            ...moreStyle,
            borderRadius: borderRadius ?? "8px",
            cursor: onclick ? "pointer" : "default",
          }}
          src={`${IMAGE_BASE_PATH}${isTrackMode && face.track_representor ? face.track_representor : face.image}`}
          alt={
            alt ??
            `face-rec-${isTrackMode && face.track_representor ? face.track_representor : face.image}`
          }
          onClick={onclick ?? null}
        />
      )}
    </>
  );
}
