import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FaHandPaper,
  FaSpinner,
  FaThumbsUp,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import { useApiPersonsData } from "../../context/personsContext";
import { deleteItem } from "../../faceit-api/FaceitApi";
import { buildFiltersQuery } from "../../utils/helper";
import { FaceImage } from "../face/FaceImage";

const DELETE_BATCH = 1000;

function DeleteFaces(props) {
  const { totalEvents, filters, close } = props;
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [stop, setStop] = useState(false);
  const [deletedAmount, setDeletedAmount] = useState(0);
  const [failedFaces, setFailedFaces] = useState([]);

  const { data: persons } = useApiPersonsData();

  const deleteFaces = () => {
    const filtersToUseWithPage = buildFiltersQuery(filters, persons);
    const readyToUseFilters = {
      page_size: DELETE_BATCH,
      ...filtersToUseWithPage,
    };
    setIsDeleting(true);

    return deleteItem(
      `faces/bulk?&${new URLSearchParams(readyToUseFilters).toString()}`
    ).then((resp) => {
      if (resp.status === 1) {
        setDeletedAmount((current) => current + resp.count);
        setFailedFaces((current) => [...current, ...resp.fail]);
      }
      if (!!resp.next && !stop) {
        deleteFaces();
      }
      if (!resp.next) {
        setIsDone(true);
        setIsDeleting(false);
      }
    });
  };

  useEffect(() => {
    return () => setStop(true);
  }, []);

  return (
    <div className="px-5 py-3">
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <FaTimes />
      </IconButton>
      {!deleteConfirmed && (
        <>
          <div className="text-center fs-4 fw-bold">Are you sure?</div>
          <p>
            You are about to delete {totalEvents}, you won't be able to undo
            this
          </p>
          <div className="d-flex  justify-content-end">
            <Button onClick={close} variant="outlined" className="me-2">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                deleteFaces();
                setDeleteConfirmed(true);
              }}
            >
              Delete all
            </Button>
          </div>
        </>
      )}
      {deleteConfirmed && (
        <>
          <div className="mt-3 text-center">Deleting in progress...</div>
          <div className="fs-4 text-center my-2">
            {isDeleting ? <FaSpinner className="fa-spin" /> : <FaTrash />}
            <span className="ms-3">
              {deletedAmount}/{totalEvents}
            </span>
          </div>

          <hr />
          <div className="text-start">Faces that failed to delete:</div>
          <div
            className="row my-2 p-2"
            style={{
              maxHeight: "250px",
              overflowY: "scroll",
              background: COLORS.Gray800,
              borderRadius: "4px",
            }}
          >
            {failedFaces.map((face) => (
              <div className="mb-1" key={`fail-${face.image}`}>
                <FaceImage
                  face={face}
                  key={face.image}
                  size="30px"
                  alt="face"
                  borderRadius="50%"
                  moreStyle={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "white",
                  }}
                />
                <span className="ms-2">{face.errmsg}</span>
              </div>
            ))}
          </div>
          {isDone && <div>All done!</div>}
          <div className="d-flex  justify-content-end">
            {isDone ? (
              <Button
                variant="contained"
                onClick={() => {
                  setStop(true);
                  window.location.reload();
                }}
                startIcon={<FaThumbsUp />}
              >
                Done
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  setStop(true);
                  close();
                }}
                startIcon={<FaHandPaper />}
              >
                Stop
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DeleteFaces;
