import React from "react";
import { Flex } from "../elements/flex/Flex";
import MainContainer from "../main-menu/MainContainer";
import { Tab, Tabs } from "../elements/tabs/Tabs";
import NotificationsSettings from "./NotificationsSettings";
import { GeneralSettings } from "./GeneralSettings";

type Props = {};

export const Settings = (props: Props) => {
  return (
    <MainContainer>
      <Flex column>
        <Tabs>
          <Tab title="General Settings">
            <GeneralSettings />
          </Tab>
          <Tab title="Notifications Settings">
            <NotificationsSettings />
          </Tab>
        </Tabs>
      </Flex>
    </MainContainer>
  );
};
