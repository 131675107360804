import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Gollum from "./controllers/Gollum";
import MoxaE1214 from "./controllers/MoxaE1214";
import SecusysApi from "./controllers/SecusysApi";
import {
  FaChevronDown,
  FaChevronRight,
  FaLockOpen,
  FaSpinner,
} from "react-icons/fa";
import AudioClips from "./controllers/AudioClips";
import AdditionalControllers from "./controllers/AdditionalControllers";
import { post } from "../../faceit-api/FaceitApi";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { useApiCamerasData } from "../../context/camerasContext";
import TooltipSr from "../general/TooltipSr";
import { useApiGroupsData } from "../../context/groupContext";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";
import { useIsMobile } from "../../utils/hooks";
import { TCPSocketController } from "./controllers/TCPSocketController";

export const controllersSupported = [
  { label: "HTTP Request", value: "send_url" },
  { label: "TCP Socket", value: "tcp_socket" },
  { label: "Moxa E1214", value: "moxa_e1214" },
  { label: "Secusys API", value: "secusys_api" },
  {
    label: "Android Registration",
    value: "android_registration",
  },
];

function CameraSettingsAccessControl(props) {
  const { camera, setCamera } = props;
  const isMobile = useIsMobile();
  const { data: groups } = useApiGroupsData();
  const { data: cameras } = useApiCamerasData();
  const [showAudioClips, setShowAudioClips] = useState(false);
  const [isTestingController, setIsTestingController] = useState(false);
  const [isAntiPassbackOn, setIsAntiPassbackOn] = useState(
    camera?.executer_prev_zone && camera?.executer_prev_zone !== "default"
  );

  const [showAdditionalControllers, setShowAdditionalControllers] =
    useState(false);

  const groupsOptions = groups.map((g) => {
    return {
      label: g.name,
      value: g.id,
    };
  });

  const triggerOnOptions = [
    { value: "face", label: "Face" },
    { value: "tag", label: "Tag" },
    { value: "face_tag", label: "Face + Tag" },
  ];

  var prevZonesOptions = [
    ...new Set(
      cameras.filter((c) => c.zone !== camera.zone).map((c) => c.zone)
    ),
  ].map((zone) => {
    return {
      label: zone,
      value: zone,
    };
  });

  prevZonesOptions.push({
    label: "Disabled",
    value: "default",
  });

  const getDefaultExecutor = () => {
    return camera.executor === "disabled" ? "send_url" : camera.executor;
  };

  const testController = () => {
    setIsTestingController(true);
    post(`cameras/trigger_controller`, camera)
      .then((resp) => {
        if (resp.status === 1) {
          showSuccessDialog("Controller triggered");
        } else {
          showErrorDialog("Failed to trigger controller");
        }
      })
      .finally(() => setIsTestingController(false));
  };

  const animatedComponents = makeAnimated();

  return (
    <div
      className="d-flex flex-column gap-8 pt-2"
      style={{
        fontSize: "14px",
      }}
    >
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className="text-truncate fw-bolder"
          title="Access Control"
          style={{
            maxWidth: "200px",
            width: "200px",
          }}
        >
          Access Control
        </div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              Enable integration for doors / locks / controllers
            </Tooltip>
          }
        >
          <div
            className="w-100"
            style={{
              maxWidth: "300px",
            }}
          >
            {" "}
            <SwitchCheckbox
              checked={camera.executor !== "disabled"}
              onChange={(e) => {
                setCamera({
                  ...camera,
                  executor: e.target.checked
                    ? getDefaultExecutor()
                    : "disabled",
                });
                setShowAdditionalControllers(false);
                setShowAudioClips(false);
              }}
            />
          </div>
        </OverlayTrigger>
      </div>

      <TooltipSr
        placement="top"
        title="The event type that will trigger the controllers"
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Trigger on"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Trigger on
          </div>
          <div
            className="w-100"
            style={{
              maxWidth: "300px",
            }}
          >
            <Select
              isDisabled={camera.executor === "disabled"}
              className="react-select-container"
              classNamePrefix="react-select"
              options={triggerOnOptions}
              value={triggerOnOptions.find(
                (z) => z.value === camera.executor_trigger_on
              )}
              defaultValue={triggerOnOptions.find((z) => z.value === "face")}
              onChange={(e) =>
                setCamera({
                  ...camera,
                  executor_trigger_on: e.value,
                })
              }
              theme="dark"
            />
          </div>
        </div>
      </TooltipSr>

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            The controller will execute on recognition events of people from the
            selected groups
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Apply on groups"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Apply on groups
          </div>
          <div
            className={`w-100 ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            style={{
              maxWidth: "300px",
            }}
          >
            <Select
              isDisabled={camera.executor === "disabled"}
              className="react-select-container"
              classNamePrefix="react-select"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={groupsOptions}
              value={groupsOptions.filter(
                (g) =>
                  camera.executer_groups &&
                  camera.executer_groups.includes(g.value.trim())
              )}
              onChange={(options) =>
                setCamera({
                  ...camera,
                  executer_groups: options.map((o) => o.value.trim()),
                })
              }
              theme="dark"
            />
          </div>
        </div>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>Select the type of the controller / door / locker</Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Controller type"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Controller type
          </div>

          <div
            className={`w-100 ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            style={{
              maxWidth: "300px",
            }}
          >
            <Select
              isDisabled={camera.executor === "disabled"}
              className={`react-select-container`}
              classNamePrefix="react-select"
              placeholder="Select..."
              value={controllersSupported.find(
                (c) => c.value === camera.executor
              )}
              onChange={(e) =>
                setCamera({
                  ...camera,
                  executor: e.value,
                })
              }
              options={controllersSupported}
              theme="dark"
            />
          </div>
        </div>
      </OverlayTrigger>

      {camera.executor === "send_url" && (
        <Gollum camera={camera} setCamera={setCamera} />
      )}
      {camera.executor === "tcp_socket" && (
        <TCPSocketController camera={camera} setCamera={setCamera} />
      )}
      {camera.executor === "moxa_e1214" && (
        <MoxaE1214 camera={camera} setCamera={setCamera} />
      )}
      {camera.executor === "secusys_api" && (
        <SecusysApi camera={camera} setCamera={setCamera} />
      )}

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {"Ignore reoccurred identification in the last specified seconds"}
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Ignore reoccurred identifications"
            style={{
              maxWidth: "750px",
              width: "750px",
            }}
          >
            Ignore reoccurred identifications
          </div>
          <div className="d-flex w-100">
            <input
              className={`sr-text-input ${
                camera.executor === "disabled" ? "sr-disabled" : ""
              }`}
              style={{
                width: "50px",
              }}
              type="text"
              placeholder="5..."
              value={camera.executor_reoccurred_identification_filter}
              onChange={(e) =>
                setCamera({
                  ...camera,
                  executor_reoccurred_identification_filter: e.target.value,
                })
              }
            />
          </div>
        </div>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            Prevent of controller activation if there was no identification
            event in the previously defined zone
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="Anti-Passback"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            Anti-Passback
          </div>
          <div
            className="w-100"
            style={{
              maxWidth: "300px",
            }}
          >
            <SwitchCheckbox
              checked={isAntiPassbackOn}
              disabled={camera.executor === "disabled"}
              onChange={(e) => {
                if (!e.target.checked) {
                  setCamera({
                    ...camera,
                    executer_prev_zone: "default",
                  });
                }
                setIsAntiPassbackOn(e.target.checked);
              }}
            />
          </div>
        </div>
      </OverlayTrigger>

      {isAntiPassbackOn && (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                An zone that if a person has not been identified before to the
                current zone he cannot pass
              </Tooltip>
            }
          >
            <div className="d-flex align-items-center row mb-3">
              <label
                className={`col-12 col-lg-3 ${
                  camera.executor === "disabled" ? "sr-disabled" : ""
                }`}
              >
                Anti-Passback previous zone
              </label>
              <Select
                isDisabled={camera.executor === "disabled"}
                className="react-select-container col-12 col-lg-8 px-0"
                classNamePrefix="react-select"
                placeholder="Select or write new name..."
                options={prevZonesOptions}
                value={prevZonesOptions.find(
                  (z) => z.value === camera.executer_prev_zone
                )}
                onChange={(e) =>
                  setCamera({
                    ...camera,
                    executer_prev_zone: e.value,
                  })
                }
                theme="dark"
              />
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Expiration time for blocking the person in anti-passback (value
                in seconds)
              </Tooltip>
            }
          >
            <div className="d-flex align-items-center row mb-3">
              <label
                className={`col-12 col-lg-3 ${
                  camera.executor === "disabled" ? "sr-disabled" : ""
                }`}
              >
                Anti-Passback expiration time
              </label>
              <input
                className={`sr-text-input col-3 col-lg-1 ${
                  camera.executor === "disabled" ? "sr-disabled" : ""
                }`}
                type="number"
                placeholder="180..."
                value={camera.executor_passback_expiration || 180}
                onChange={(e) =>
                  setCamera({
                    ...camera,
                    executor_passback_expiration: e.target.value,
                  })
                }
              />
            </div>
          </OverlayTrigger>
        </>
      )}

      <div className="mb-3">
        <button
          className={`d-flex sr-btn align-items-center p-2 ${
            camera.executor === "disabled" ? "sr-disabled" : ""
          }`}
          style={{ width: "fit-content" }}
          onClick={testController}
          disabled={["disabled", "android_registration"].includes(
            camera.executor
          )}
        >
          {isTestingController ? (
            <FaSpinner className="me-2 fa-spin" />
          ) : (
            <FaLockOpen className="me-2" />
          )}
          Test Controller
        </button>
      </div>

      {!camera.is_android && !isMobile && (
        <div
          className={`d-flex align-items-center row mb-3  ${
            camera.executor === "disabled" ? "sr-disabled" : ""
          }`}
          style={{ cursor: "pointer" }}
          onClick={() => setShowAudioClips(!showAudioClips)}
        >
          <div
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            Audio clips{" "}
            {showAudioClips ? (
              <FaChevronDown className="ms-1" />
            ) : (
              <FaChevronRight className="ms-1" />
            )}
          </div>
        </div>
      )}
      {showAudioClips && (
        <AudioClips
          camera={camera}
          setCamera={setCamera}
          groupsOptions={groupsOptions}
        />
      )}

      {!camera.is_android && !isMobile && (
        <div
          className={`d-flex align-items-center row mb-3  ${
            camera.executor === "disabled" ? "sr-disabled" : ""
          }`}
          style={{ cursor: "pointer" }}
          onClick={() =>
            setShowAdditionalControllers(!showAdditionalControllers)
          }
        >
          <div
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            Additional controllers{" "}
            {showAdditionalControllers ? (
              <FaChevronDown className="ms-1" />
            ) : (
              <FaChevronRight className="ms-1" />
            )}
          </div>
        </div>
      )}
      {showAdditionalControllers && (
        <AdditionalControllers
          camera={camera}
          setCamera={setCamera}
          groupsOptions={groupsOptions}
        />
      )}
    </div>
  );
}

export default CameraSettingsAccessControl;
