import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, put } from "../faceit-api/FaceitApi";
import { bitAfterNow, DEFAULT_STALE_TIME } from "../utils/helper";
import {
  WebSettings,
  WebSettingsContext,
  WebSettingsUpdate,
} from "../types/WebSettings";

const queryKey = "web-settings";

const getWebSettings = (): WebSettings | any =>
  get(queryKey).then((resp) => {
    if (resp.results) {
      return resp.results[0];
    }
  });

export const useApiWebSettings = () =>
  useQuery<WebSettings, Error>({
    queryKey: [queryKey],
    keepPreviousData: true,
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: true,
    queryFn: async (): Promise<WebSettings> => getWebSettings(),
  });

export const useApiUpdateWebSettings = () => {
  const queryClient = useQueryClient();
  return useMutation<WebSettingsUpdate, Error, WebSettingsContext>({
    mutationKey: [queryKey],
    mutationFn: async ({
      updateData,
    }: WebSettingsContext): Promise<WebSettingsUpdate> =>
      await put(`${queryKey}/1`, updateData).then((resp) => {
        if (resp?.status !== 1)
          throw new Error("Failed to update web settings");
        return resp;
      }),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};
