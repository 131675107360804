import { Autocomplete, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useApiPersonsData } from "../../context/personsContext";
import { Person } from "../../types/Person";
import { Option } from "../../types/Shared";
import { Flex } from "../elements/flex/Flex";

const notificationsOptions = [
  { label: "known tag id", value: "known_tag_id" },
  { label: "unknown tag id", value: "unknown_tag_id" },
  { label: "tag id match person", value: "tag_id_match_person" },
  { label: "tag id_mismatch person", value: "tag_id_mismatch_person" },
  { label: "tag id unknown face", value: "tag_id_unknown_face" },
];

type TagsFilterProps = {
  setFilters: Dispatch<SetStateAction<Record<string, string>>>;
};

function TagsFilter({ setFilters }: TagsFilterProps) {
  const { data: persons } = useApiPersonsData();
  const [personOptions, setPersonsOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (persons) {
      const options = (persons as Person[]).map((person) => ({
        label: person.person_name,
        value: person.id,
      }));
      setPersonsOptions(options);
    }
  }, [persons]);

  return (
    <Flex w100 flexWrap gap="16px">
      <TextField
        id="filter-tag-id"
        label="Search by tag ID"
        variant="standard"
        onChange={(e) =>
          setFilters((current: Record<string, string>) => ({
            ...current,
            tag_id: e?.target?.value?.toString() || "",
          }))
        }
      />
      <Autocomplete
        options={notificationsOptions}
        id="filter-notification-type"
        disableCloseOnSelect
        onChange={(e, option) =>
          setFilters((current: any) => ({
            ...current,
            type: option?.value.toString() || "",
          }))
        }
        renderInput={(params) => (
          <TextField {...params} label="Notification Type" variant="standard" />
        )}
        style={{ width: "200px" }}
      />
      <Autocomplete
        options={personOptions}
        id="filter-person"
        disableCloseOnSelect
        onChange={(e, option) =>
          setFilters((current) => ({
            ...current,
            person_id: option?.value || "",
          }))
        }
        renderInput={(params) => (
          <TextField {...params} label="Person" variant="standard" />
        )}
        style={{ width: "200px" }}
      />
    </Flex>
  );
}

export default TagsFilter;
