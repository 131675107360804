import React from "react";
import { FaBars } from "react-icons/fa";
import { COLORS } from "../../assets/colors";
import Sidebar from "./Sidebar";


function MobileMenu(activeComponent) {
  const [showMenu, setShowMenu] = React.useState(false);

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <div
        className="mx-2 pointer"
        style={{
          position: "absolute",
          top: "10px",
          left: "4px",
          color: COLORS.FontGray,
          cursor: "pointer",
        }}
        onClick={() => setShowMenu(!showMenu)}
      >
        <FaBars
          style={{
            height: "28px",
            width: "auto",
          }}
        />
      </div>
      {showMenu && (
        <Sidebar activeComponent={activeComponent} closeMenu={closeMenu} />
      )}
    </>
  );
}

export default MobileMenu;
