import React, { useContext, useState } from "react";
import {
  BsBarChartLine,
  BsCameraVideo,
  BsClock,
  BsPersonBadgeFill,
} from "react-icons/bs";
import {
  FaHistory,
  FaSave,
  FaSpinner,
  FaTimes,
  FaTrashAlt,
  FaUserPlus,
} from "react-icons/fa";
import IconUser from "../../assets/icons/user.svg";
import { COLORS } from "../../assets/colors";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import { dateDisplay } from "../../utils/helper";
import HistorySection from "./HistorySection";
import DetailLine from "./DetailLine";
import { DataContext } from "../../App";
import Select from "react-select";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
// @ts-ignore
import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import PeopleAddPopup from "../people/PeopleAddPopup";
import { Modal } from "react-bootstrap";
import {
  useApiDeletePerson,
  useApiUpdatePerson,
} from "../../context/personsContext";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiGroupsData } from "../../context/groupContext";
import { Person } from "../../types/Person";
import { Camera } from "../../types/Camera";
import { Group } from "../../types/Group";

type PersonModalProps = {
  close: () => void;
  person: Person;
};

function PersonModal(props: PersonModalProps) {
  const { close, person } = props;
  const { userInfo } = useContext(DataContext);
  const { data: groups } = useApiGroupsData();

  const { data: cameras } = useApiCamerasData();
  const [updatedPerson, setUpdatedPerson] = useState(person);
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);

  const { mutate: updatePerson, isLoading: isUpdating } = useApiUpdatePerson();
  const { mutate: deletePerson, isLoading: isDeleting } = useApiDeletePerson();

  const camera: Camera | undefined = (cameras as Camera[]).find(
    (c) => c.id === person.last_location
  );

  const groupsOptions = (groups as Group[]).map((g) => {
    return {
      label: g.name,
      value: g.id,
    };
  });

  const handleUpdatePerson = () => {
    let tempPerson = { ...updatedPerson };
    let pid = tempPerson?.id;
    delete (tempPerson as any)?.id;

    updatePerson({
      personId: pid,
      personData: tempPerson,
      onSuccessCallback: () =>
        showSuccessDialog("Person updated successfully").then(close),
      onErrorCallback: () => showErrorDialog("Failed to update person"),
    });
  };

  const beforeDeletePerson = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      html: ReactDOMServer.renderToString(
        <div>
          <div>
            You are about to delete person: "
            {person.person_name.replaceAll("_", " ")}".
          </div>

          <div>You won't be able to revert this.</div>
        </div>
      ),
    }).then((result: any) => {
      if (result.value) {
        handleDeletePerson();
      }
    });
  };

  const handleDeletePerson = () => {
    deletePerson({
      personId: person.id,
      onSuccessCallback: () =>
        showSuccessDialog("Person deleted successfully").then(close),
      onErrorCallback: () => showErrorDialog("Failed to delete person"),
    });
  };

  return (
    <div
      className="h-100"
      style={{
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
        zIndex: 1000,
        overflow: "auto",
      }}
    >
      <div
        className="d-flex p-3 justify-content-between"
        style={{
          backgroundColor: COLORS.Gray800,
          borderRadius: "8px 8px 0 0",
        }}
      >
        <div className="d-flex align-items-center gap-2">
          <BsPersonBadgeFill />
          <div className="fw-bold fs-5">Person Details</div>
        </div>
        <FaTimes className="fs-3 pointer me-2" onClick={close} />
      </div>
      <div className="p-3">
        <div className="text-capitalize fw-bold fs-5">
          {person.person_name.replaceAll("_", "")}
        </div>

        <div className="row mt-1">
          <div className="col-12 col-lg-3">
            <div className="d-lg-flex align-items-center">
              <div>
                <img
                  src={
                    person.faces[0]
                      ? `${IMAGE_BASE_PATH}${person.faces[0]}`
                      : IconUser
                  }
                  style={{ borderRadius: "8px" }}
                  alt="clicked-face"
                  height={220}
                  width={220}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 mt-2 mt-lg-0">
            <div className="d-flex align-items-center mb-2 row">
              <label className="col-12 col-lg-3 text-start">Name</label>
              <input
                className={`sr-text-input col-12 col-lg-8`}
                placeholder="Jack Jackson..."
                value={updatedPerson.person_name}
                onChange={(e) =>
                  setUpdatedPerson({
                    ...updatedPerson,
                    person_name: e.target.value,
                  })
                }
                type="text"
              />
            </div>
            <div className="d-flex align-items-center mb-2 row">
              <label className="col-12 col-lg-3 text-start">Group</label>
              <Select
                className={`react-select-container col-12 col-lg-8 px-0`}
                classNamePrefix="react-select"
                placeholder="Select group..."
                value={groupsOptions.find(
                  (g) => g.value === updatedPerson.group
                )}
                onChange={(e) =>
                  setUpdatedPerson({
                    ...updatedPerson,
                    group: e?.value?.toString() || updatedPerson.group,
                  })
                }
                options={groupsOptions}
              />
            </div>
            <div className="d-flex mb-2 row">
              <label className="col-12 col-lg-3 text-start mt-1">
                Description
              </label>
              <textarea
                className={`sr-textarea col-12 col-lg-8`}
                placeholder="Write a short description about the person..."
                value={updatedPerson.description || ""}
                style={{
                  fontSize: "12px",
                  height: "80px",
                }}
                onChange={(e) =>
                  setUpdatedPerson({
                    ...updatedPerson,
                    description: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <div className="d-flex align-items-center mb-2 row">
              <label className="col-12 col-lg-3 text-start">Tag ID</label>
              <input
                className={`sr-text-input col-12 col-lg-8`}
                value={updatedPerson.tag_id}
                onChange={(e) =>
                  setUpdatedPerson({
                    ...updatedPerson,
                    tag_id: e.target.value,
                  })
                }
                type="text"
              />
            </div>
          </div>

          <div className="col-12 col-lg-4 mt-2 mt-lg-0">
            <div className="row">
              <DetailLine
                tooltip={`Created at: ${dateDisplay(new Date(person.created))}`}
                text={`Created at: ${dateDisplay(new Date(person.created))}`}
                icon={<BsClock style={{ color: COLORS.primary }} />}
              />

              <DetailLine
                tooltip={`Recently identified on camera: ${
                  camera?.name || "N/A"
                }`}
                text={`Last location: ${camera?.name || "N/A"}`}
                icon={<BsCameraVideo style={{ color: COLORS.primary }} />}
              />
              <DetailLine
                tooltip={`Total identifications: ${parseInt(
                  person.identifications?.toString() || "0"
                )}`}
                text={`Total identifications: ${parseInt(
                  person.identifications?.toString() || "0"
                )}`}
                icon={<BsBarChartLine style={{ color: COLORS.primary }} />}
              />

              <DetailLine
                tooltip={`Last seen at: ${
                  person.last_seen
                    ? dateDisplay(new Date(person.last_seen))
                    : "N/A"
                }`}
                text={`Last seen: ${
                  person.last_seen
                    ? dateDisplay(new Date(person.last_seen))
                    : "N/A"
                }`}
                icon={<FaHistory style={{ color: COLORS.primary }} />}
              />
            </div>
            {userInfo?.is_staff && (
              <div className="row">
                <div className="mt-2">
                  <button
                    className="sr-outline-btn sr-outline-btn-danger w-100 mx-1 px-1 d-flex align-items-center justify-content-center"
                    onClick={beforeDeletePerson}
                    style={{ fontSize: "14px" }}
                  >
                    {isDeleting ? (
                      <FaSpinner className="me-2 fa-spin" />
                    ) : (
                      <FaTrashAlt className="me-2" />
                    )}
                    <span>Delete person</span>
                  </button>
                </div>
                <div className="mt-2">
                  <button
                    className="sr-btn mx-1 px-1 d-flex align-items-center justify-content-center"
                    onClick={handleUpdatePerson}
                    disabled={
                      !updatedPerson.person_name || !updatedPerson.group
                    }
                    style={{ fontSize: "14px" }}
                  >
                    {isUpdating ? (
                      <FaSpinner className="me-2 fa-spin" />
                    ) : (
                      <FaSave className="me-2" />
                    )}
                    <span>Save changes</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          show={showAddPersonModal}
          onHide={() => setShowAddPersonModal(false)}
          backdrop="static"
          keyboard={false}
          size="xl"
          fullscreen={"md-down"}
        >
          <PeopleAddPopup
            close={() => setShowAddPersonModal(false)}
            closeParent={close}
            groupsOptions={groupsOptions}
            person={person}
          />
        </Modal>

        <div className="mt-2">
          <div>Faces:</div>
          <div className="d-flex">
            {updatedPerson.faces &&
              updatedPerson.faces.slice(0, 10).map((face) => (
                <div
                  key={`person-face-${face}`}
                  className="p-2 me-2"
                  style={{
                    width: "fit-content",
                    backgroundColor: COLORS.Gray600,
                    borderRadius: "8px",
                  }}
                >
                  <div className="image-item__btn-wrapper d-flex justify-content-between mb-1">
                    {updatedPerson.faces.length > 1 && (
                      <FaTimes
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setUpdatedPerson({
                            ...updatedPerson,
                            faces: updatedPerson.faces.filter(
                              (f) => f !== face
                            ),
                          });
                        }}
                      />
                    )}
                  </div>
                  <img
                    src={`${IMAGE_BASE_PATH}${face}`}
                    alt={`person-face-${face}`}
                    width="80"
                    height={"80"}
                    style={{ borderRadius: "8px" }}
                  />
                </div>
              ))}
            {person.faces && person.faces.length < 10 && (
              <div
                className="p-2 me-2 pointer shadow-hover"
                onClick={() => setShowAddPersonModal(true)}
                style={{
                  width: "fit-content",
                  backgroundColor: COLORS.Gray600,
                  borderRadius: "8px",
                }}
              >
                <FaUserPlus
                  style={{
                    height: "80px",
                    width: "80px",
                  }}
                />
                <div className="text-center fw-bold">Add face</div>
              </div>
            )}
          </div>
        </div>

        <hr className="my-4" />
        <div>
          <HistorySection
            person={person}
            isUnknown={false}
            initTime={new Date().toISOString()}
            face={person.faces[0] || {}}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonModal;
