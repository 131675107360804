import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../../assets/colors";
import MainContainer from "../main-menu/MainContainer";

function MainPeople() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const unselectedStyle = {
    color: COLORS.Gray500,
    textDecoration: "none",
  };
  const selectedStyle = {
    color: COLORS.FontGray,
    textDecoration: "none",
    fontWeight: 900,
    borderBottom: `solid 2px ${COLORS.primary}`,
  };

  const unselectedBackgroundStyle = {
    padding: "10px",
    marginRight: "0",
  };

  const isPeopleTabSelected = () => {
    return (
      pathname === "/people" ||
      pathname === "/people/" ||
      pathname.startsWith("/people/list")
    );
  };

  return (
    <MainContainer noPadding={true}>
      <div
        className="d-flex justify-content-around w-100"
        style={{
          fontSize: "20px",
          color: COLORS.FontGray,
        }}
      >
        <div
          className="pointer col text-center"
          onClick={() => navigate(`/people/list`)}
          style={
            isPeopleTabSelected()
              ? { padding: "10px" }
              : { ...unselectedBackgroundStyle, borderTopLeftRadius: "12px" }
          }
        >
          <Link
            className="px-md-5 pb-md-2"
            style={isPeopleTabSelected() ? selectedStyle : unselectedStyle}
            to={`/people/list`}
          >
            People
          </Link>
        </div>
        <div
          className="pointer col text-center"
          onClick={() => navigate(`/people/groups`)}
          style={
            isPeopleTabSelected()
              ? { ...unselectedBackgroundStyle, borderTopRightRadius: "12px" }
              : { padding: "10px" }
          }
        >
          <Link
            className="px-md-5 pb-md-2"
            style={!isPeopleTabSelected() ? selectedStyle : unselectedStyle}
            to={`/people/groups`}
          >
            Groups
          </Link>
        </div>
      </div>

      <Outlet />
    </MainContainer>
  );
}

export default MainPeople;
