import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { COLORS } from "../../assets/colors";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiGroupsData } from "../../context/groupContext";
import { getThresholdDate } from "../../utils/helper";
import DashboardModal from "./DashboardModal";

function DashboardBarChart(props) {
  const { faces, isFetchingFaces, dateRange } = props;
  const { data:groups } = useApiGroupsData();
  const { data: cameras } = useApiCamerasData();
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [modalFaces, setModalFaces] = useState([]);

  const chartData = cameras.map((camera) => {
    var cameraRecognitions = { camera: camera.name };
    var currentDate = getThresholdDate(0);
    if (dateRange > 0) {
      for (let i = 0; i < dateRange; i++) {
        currentDate = getThresholdDate(i);
        cameraRecognitions.day = currentDate;
      }
    } else {
      cameraRecognitions.day = currentDate;
    }
    groups.forEach((group) => {
      cameraRecognitions[group.name] = faces.filter(
        (f) => f.camera_id === camera.id && f.group_id === group.id
      ).length;
    });
    return cameraRecognitions;
  });

  const handleBarChartClicked = (data, index) => {
    const clickedCamera = data.activeLabel;
    setModalSubtitle(groups.map((g) => g.name).join(", "));
    setModalTitle(clickedCamera);

    const camera = cameras.find((c) => c.name === clickedCamera);

    const facesToSet = faces.filter((face) => face.camera_id === camera.id);
    setModalFaces(facesToSet);
    setShowDashboardModal(true);
  };

  return (
    <div
      className="mt-3"
      style={{
        width: "100%",
        padding: "20px 12px 20px 12px",
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <Modal
        show={showDashboardModal}
        onHide={() => setShowDashboardModal(false)}
        keyboard={false}
        size="md"
        fullscreen={"md-down"}
      >
        <DashboardModal
          close={() => setShowDashboardModal(false)}
          faces={modalFaces}
          title={modalTitle}
          subtitle={modalSubtitle}
        />
      </Modal>
      <div className=" w-100 mb-2">
        <span className="fs-5 mx-2">Activities</span>
        <span style={{ fontSize: "12px" }}>
          / Identifications per camera & group
        </span>
      </div>{" "}
      <div style={{ width: "100%", height: "280px" }}>
        {isFetchingFaces ? (
          <div className="text-center h-100 mt-1 d-flex align-items-center justify-content-center">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              // width={500}
              // height={300}
              data={chartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              onClick={handleBarChartClicked}
              cursor="pointer"
            >
              <CartesianGrid vertical={false} strokeWidth={0.5} />
              <XAxis dataKey="camera" />

              <YAxis />
              <Tooltip
                contentStyle={{
                  backgroundColor: COLORS.Gray800,
                  borderWidth: "0",
                  borderRadius: "8px",
                }}
                cursor={{ fill: COLORS.Gray100, strokeWidth: 2 }}
              />
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{
                  paddingLeft: "20px",
                }}
              />
              {groups.map((group, i) => (
                <defs key={`defs-${group.id}`}>
                  <linearGradient
                    id={`groupColor-${group.id}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={COLORS.GROUP[group.color]}
                      stopOpacity={1}
                    />
                    <stop
                      offset="95%"
                      stopColor={COLORS.GROUP.LIGHT[group.color]}
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
              ))}

              {groups.map((group) => (
                <Bar
                  key={`group-bar-${group.id}`}
                  dataKey={group.name}
                  fill={`url(#groupColor-${group.id})`}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
      <div style={{ width: "15%" }}></div>
    </div>
  );
}
export default DashboardBarChart;
