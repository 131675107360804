import React from "react";

function PersonLowQualityCard(props) {
  const { result, changeResultValue } = props;
  return (
    <div
      className=" "
      style={{
        height: "320px",
      }}
    >
      <div>The face image does not seem to be of high quality</div>

      <div
        className="text-start"
        style={{
          fontSize: "12px",
        }}
      >
        The following negative characteristics have been identified with a
        certain probability that exists in the image, if the image looks good
        enough to you, you can ignore this warning and add this face. <br />
        Note that a low quality face image can lead to incorrect
        identifications.
      </div>
      <div
        className="mt-2"
        style={{
          height: "110px",
        }}
      >
        {result.fiq.all
          .filter((fiqRes) => fiqRes.conf > 0.2 && fiqRes.label !== "good")
          .map((fiqRes) => (
            <div key={fiqRes.label} className="text-start">
              <span className="text-capitalize me-2">{fiqRes.label}:</span>
              <span className="fw-bold">{parseInt(fiqRes.conf * 100)}%</span>
            </div>
          ))}
      </div>
      <div>
        <button
          className="sr-outline-btn mt-1"
          onClick={() => {
            changeResultValue("approved", true);
          }}
        >
          Approve and take the risk
        </button>
      </div>
    </div>
  );
}

export default PersonLowQualityCard;
