import React from "react";
import { COLORS } from "../../../assets/colors";

function AudioClips(props) {
  const { camera, setCamera, groupsOptions } = props;

  const groupsAndUnknowns = [
    ...groupsOptions,
    { label: "Unknowns", value: "unidentified" },
  ];

  const onChangeAudioClip = (value, groupId, key) => {
    var tempAudioClip = { ...camera.audio_clips };
    if (!tempAudioClip[groupId]) {
      tempAudioClip[groupId] = {};
    }
    tempAudioClip[groupId][key] = value;
    setCamera({ ...camera, audio_clips: tempAudioClip });
  };

  return (
    <div
      className="my-2 p-3"
      style={{
        border: "solid 1px",
        borderColor: COLORS.Gray600,
        borderRadius: "6px",
        position: "relative",
      }}
    >
      {groupsAndUnknowns.map((group) => (
        <div key={`ac-${group.value}`}>
          <div className="fw-bold">{group.label}:</div>
          <div className="mx-2">
            <div className="d-flex align-items-center row mb-3">
              <lable className="col-12 col-lg-3">Start URL</lable>
              <input
                className="sr-text-input col-12 col-lg-8"
                type="text"
                placeholder="http://10.0.0.0/start-play..."
                value={
                  camera.audio_clips &&
                  camera.audio_clips[group.value] &&
                  camera.audio_clips[group.value].start
                }
                onChange={(e) =>
                  onChangeAudioClip(e.target.value, group.value, "start")
                }
              />
            </div>
            <div className="d-flex align-items-center row mb-3">
              <lable className="col-12 col-lg-3">Stop URL</lable>
              <input
                className="sr-text-input col-12 col-lg-8"
                type="text"
                placeholder="http://10.0.0.0/stop-play..."
                value={
                  camera.audio_clips &&
                  camera.audio_clips[group.value] &&
                  camera.audio_clips[group.value].stop
                }
                onChange={(e) =>
                  onChangeAudioClip(e.target.value, group.value, "stop")
                }
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AudioClips;
