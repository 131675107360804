export const COLORS = {
  Gray900: "#1D1D1D",
  Gray800: "#323232",
  Gray700: "#242424",
  Gray600: "#494949",
  Gray500: "#888888",
  Gray100: "#e2e2e2af",
  Gray50: "#D8D8D8",
  FontGray: "#E5E5E5",
  BgBlack: "#1B1B1B",
  Black: "#000000",
  ShadowLightGray: "rgba(229, 229, 229, 0.2)",
  TransparentWhite: "rgba(255, 255, 255, 0.6)",
  TransparentPrimary: "rgba(23, 103, 102, 0.5)",
  primary: "#176766",
  PrimaryLight: "#8DC4C2",
  primaryDark: "#123332",
  red: "rgb(230, 60, 60)",
  green: "#8dea77",
  orange: "#FEAC0C",
  primaryTauriel: "#06656b",
  gray: "gray",
  lightGray: "rgba(0, 0, 0, 0.125)",
  yellow: "yellow",
  green100: "#00939C",
  LightSky: "#94C1E7",
  Indigo: "#1B5A8E",
  IndigoDark: "#123c5e",
  White: "#FFFFFF",
  inactiveBackground: "#eddfdf",
  inactiveBorder: "#d16860",
  warningBackground: "#fbefd6",
  warningBorder: "#feac0c",

  GROUP: {
    none: "#c8c8c8",
    red: "#A81B2A",
    blue: "#1B5A8E",
    green: "#176766",
    yellow: "#eec300",
    gray: "#4E4E4E",
    purple: "#6D1BD7",
    orange: "#FEAC0C",
    pink: "#FA91AD",
    LIGHT: {
      none: "#c8c8c8",
      red: "#D86E78",
      blue: "#94C1E7",
      green: "#8DC3C2",
      yellow: "rgba(238, 195, 0, 0.5)",
      gray: "#E5E5E5",
      purple: "#BA90EF",
      orange: "#FEDA94",
      pink: "rgba(250, 145, 173, 0.5)",
    },
  },
};
