import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  BsBarChartLine,
  BsCameraVideo,
  BsPeople,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { COLORS } from "../../assets/colors";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";
import moment from "moment";
import { dateDisplay } from "../../utils/helper";
import { FaHistory } from "react-icons/fa";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiGroupsData } from "../../context/groupContext";

function PersonCard(props) {
  const { person, setSelectedPerson, setShowPersonModal } = props;
  const { data:groups } = useApiGroupsData();
  const { data: cameras } = useApiCamerasData();
  const [showDate, setShowDate] = useState(false);
  const camera = cameras.find((c) => c.id === person.last_location) || {};

  const group = groups.find((g) => g.id === person.group) || {};

  const showDetails = () => {
    setSelectedPerson(person);
    setShowPersonModal(true);
  };

  return (
    <div
      className="p-1 pt-2 "
      style={{
        borderRadius: "8px",
        backgroundColor: COLORS.Gray800,
        color: COLORS.FontGray,
        width: "180px",
      }}
    >
      <div
        className="text-center"
        style={{ position: "relative", cursor: "pointer" }}
        onClick={showDetails}
      >
        <img
          height={"160px"}
          width={"160px"}
          style={{ borderRadius: "8px" }}
          src={`${IMAGE_BASE_PATH}${person.faces[0]}`}
          alt={`face-rec-${person.faces[0]}`}
        />
      </div>

      <div className="mx-1 mb-1 row">
        <div className="col-10">
          <div>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{person.person_name}</Tooltip>}
            >
              <div className="text-truncate fw-bolder">
                {person.person_name}
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{dateDisplay(new Date(person.created))}</Tooltip>
              }
            >
              <div
                className="text-truncate"
                onClick={() => setShowDate(!showDate)}
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                {showDate
                  ? dateDisplay(new Date(person.created))
                  : moment(new Date(person.created)).fromNow()}
              </div>
            </OverlayTrigger>
          </div>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Group - {group.name}</Tooltip>}
          >
            <div
              className="d-flex align-items-center fw-bold"
              style={{ fontSize: "12px", color: COLORS.GROUP[group.color] }}
            >
              <BsPeople className="me-1" />
              <div className="text-truncate">{group.name}</div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>{`Recently identified on camera: ${
                camera.name || "N/A"
              }`}</Tooltip>
            }
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <BsCameraVideo className="me-1" />
              <div className="text-truncate">{camera.name || "N/A"}</div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {" "}
                {`Total identifications - ${parseInt(
                  person.identifications || "0"
                )}`}
              </Tooltip>
            }
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <BsBarChartLine className="me-1" />
              <div className="text-truncate">
                {`Identify: ${parseInt(person.identifications || "0")}`}
              </div>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>{`Last seen: ${
                person.last_seen && person.last_seen.create_at
                  ? dateDisplay(new Date(person.last_seen.create_at))
                  : "N/A"
              }`}</Tooltip>
            }
          >
            <div
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <FaHistory className="me-1" />
              <div className="text-truncate">{`Last seen: ${
                person.last_seen && person.last_seen.create_at
                  ? dateDisplay(new Date(person.last_seen.create_at))
                  : "N/A"
              }`}</div>
            </div>
          </OverlayTrigger>
        </div>
        <div className="col-1 pointer" onClick={showDetails}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Show more details</Tooltip>}
          >
            <div>
              <BsThreeDotsVertical />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
}

export default PersonCard;
