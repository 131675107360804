import { Row, Col, Form } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import "./Login.css";
import LoginImage from "../../assets/logo.png";
import {
  get,
  IMAGE_BASE_PATH,
  isLoggedIn,
  login,
} from "../../faceit-api/FaceitApi";
// @ts-ignore
import Swal from "sweetalert2/src/sweetalert2";
import { Button } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

export function Login() {
  const [logo, setLogo] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");

  useEffect(() => {
    get("web-logo").then((resp) => {
      if (resp.status === 1 && resp.logo) {
        setLogo(resp.logo);
      }
    });
  }, []);

  useEffect(() => {
    isLoggedIn().then((isLogged: boolean) => {
      if (isLogged) {
        window.location.assign(next || "/");
      }
    });
  }, [next]);

  const onSubmitHandler = useCallback(() => {
    login(username, password).then((resp) => {
      if (resp.status === 1) {
        window.location.assign(next || "/");
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to login",
          text: resp.errmsg || "Something wrong with credentials provided",
        });
      }
    });
  }, [next, username, password]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmitHandler();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onSubmitHandler, next]);

  const onEnter = (event: any) => {
    if (event?.key === "Enter") {
      onSubmitHandler();
    }
  };
  return (
    <div className="body-bg">
      <div
        className="container d-flex justify-content-center align-content-center"
        style={{
          marginTop: "6%",
        }}
      >
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="login-box p-5">
              <div className=" d-flex row">
                <img
                  src={
                    logo && logo !== "images/logo-white.png"
                      ? `${IMAGE_BASE_PATH}${logo}`
                      : LoginImage
                  }
                  style={{
                    maxHeight: "100px",
                    maxWidth: "300px",
                  }}
                  alt="sr-logo"
                  className="ms-2"
                />
              </div>
              <div>
                <Form.Group className="mt-4">
                  <Form.Control
                    className="login-email-box"
                    type="text"
                    name="username"
                    id="login-username"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="my-2">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="login-password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  className="d-flex align-items-center"
                  size="small"
                  onClick={() => setShowPassword((state) => !state)}
                  startIcon={showPassword ? <FaEyeSlash /> : <FaEye />}
                >
                  {showPassword ? "Hide password" : "Show password"}
                </Button>
                <div className="text-center mt-4">
                  <button
                    className="sr-btn "
                    style={{
                      color: "white",
                      backgroundColor: "rgb(23, 103, 102, 0.9)",
                    }}
                    onClick={onSubmitHandler}
                    onKeyUp={onEnter}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
