import React from "react";
import { COLORS } from "../../assets/colors";
import { FaTrash } from "react-icons/fa";
import TimeRange from "react-time-range";
import moment from "moment";
import "./NotificationsTimeRanges.css";

function NotificationsTimeRanges(props) {
  const { notificationsSettings, changeNotifyGroup, notify_group } = props;

  const changeTime = (e, from_key, to_key, group_id, index) => {
    let tempTr = [
      ...notificationsSettings.notify_groups.find(
        (g) => g.group_id === group_id
      ).time_ranges,
    ];
    tempTr[index][to_key] = e[from_key].substring(11, 16);
    changeNotifyGroup("time_ranges", tempTr, group_id);
  };

  const toLocalTimeMoment = (utcTime) => {
    const utcDatetime = new Date(
      Date.UTC(
        1970,
        1,
        1,
        parseInt(utcTime.split(":")[0]),
        parseInt(utcTime.split(":")[1]),
        0,
        0
      )
    );
    return moment(utcDatetime.toLocaleTimeString().substring(0, 5), "HH:mm");
  };

  return (
    <>
      <div
        className="p-2 m-2"
        style={{
          border: "1px solid",
          borderRadius: "8px",
          borderColor: COLORS.Gray800,
        }}
      >
        {notify_group.time_ranges &&
          notify_group.time_ranges.map((tr, i) => (
            <div
              key={`tr-${i}`}
              className="d-flex justify-content-between align-items-center mb-2"
            >
              <div>
                <TimeRange
                  startMoment={toLocalTimeMoment(tr.from_time)}
                  endMoment={toLocalTimeMoment(tr.to_time)}
                  onStartTimeChange={(e) =>
                    changeTime(
                      e,
                      "startTime",
                      "from_time",
                      notify_group.group_id,
                      i
                    )
                  }
                  onEndTimeChange={(e) =>
                    changeTime(
                      e,
                      "endTime",
                      "to_time",
                      notify_group.group_id,
                      i
                    )
                  }
                  sameIsValid={false}
                  minuteIncrement={5}
                  use24Hours={true}
                />
              </div>
              <div>
                <FaTrash
                  className="mx-3 pointer"
                  onClick={() =>
                    changeNotifyGroup(
                      "time_ranges",
                      notify_group.time_ranges.filter((g, j) => j !== i),
                      notify_group.group_id
                    )
                  }
                />
              </div>
            </div>
          ))}
        <div className="d-flex justify-content-center">
          <button
            className="sr-outline-btn"
            onClick={() =>
              changeNotifyGroup(
                "time_ranges",
                notify_group.time_ranges
                  ? [
                      ...notify_group.time_ranges,
                      { from_time: "06:00", to_time: "15:00" },
                    ]
                  : [{ from_time: "06:00", to_time: "15:00" }],
                notify_group.group_id
              )
            }
          >
            Add time range
          </button>
        </div>
      </div>
    </>
  );
}

export default NotificationsTimeRanges;
