import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SwitchCheckbox } from "../elements/switch/SwitchCheckbox";

function CameraSettingsAntiFraud(props) {
  const { camera, setCamera } = props;

  const changeAntiFraudValue = (key, value) => {
    var tempSettings = { ...camera.anti_spoofing };
    tempSettings[key] = value;
    setCamera({ ...camera, anti_spoofing: tempSettings });
  };

  const isEnabled = (fieldName) => {
    // check if some field of anti_spoofing are enable
    if (camera.anti_spoofing) {
      if (fieldName in camera.anti_spoofing) {
        return camera.anti_spoofing[fieldName];
      }
    }
    return false;
  };

  return (
    <div
      className="d-flex flex-column gap-8 py-2"
      style={{
        fontSize: "14px",
      }}
    >
      <div className="d-flex align-items-center justify-content-start gap-8">
        <div
          className="text-truncate fw-bolder"
          title="Enable Anti-Fraud"
          style={{
            maxWidth: "200px",
            width: "100%",
          }}
        >
          Enable Anti-Fraud
        </div>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Blocking and alerting of fraud attempts</Tooltip>}
        >
          <div className="w-100" style={{ maxWidth: "300px" }}>
            <SwitchCheckbox
              checked={isEnabled("enabled")}
              onChange={(e) =>
                changeAntiFraudValue("enabled", e.target.checked)
              }
            />
          </div>
        </OverlayTrigger>
      </div>

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            Threshold between 0 - 1, the lower the threshold, the wider the
            fraud detection (More chances to have false alerts){" "}
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              isEnabled("enabled") ? "" : "sr-disabled"
            }`}
            title="Threshold"
            style={{
              maxWidth: "200px",
              width: "100%",
            }}
          >
            Threshold
          </div>
          <div className="w-100">
            <input
              className={`sr-text-input w-100 ${
                isEnabled("enabled") ? "" : "sr-disabled"
              }`}
              style={{ maxWidth: "50px" }}
              type="text"
              placeholder="5..."
              value={camera.anti_spoofing && camera.anti_spoofing.threshold}
              onChange={(e) =>
                changeAntiFraudValue(
                  "threshold",
                  Number(e.target.value) !== 0 ? Number(e.target.value) : 0.65
                )
              }
            />
          </div>
        </div>
      </OverlayTrigger>
      
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              isEnabled("enabled") ? "" : "sr-disabled"
            }`}
            title="Min Frames For Decision"
            style={{
              maxWidth: "200px",
              width: "100%",
            }}
          >
            Min Frame For Decision
          </div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Use Accurate Min Frame Mode to improve anti-fraud. It might
                cause a little slower mode.
              </Tooltip>
            }
          >
            <div className="w-100">
              <input
                className={`sr-text-input w-100`}
                style={{ maxWidth: "50px" }}
                type="number"
                value={camera.anti_spoofing?.min_frames_for_decision || 3}
                onChange={(e) =>
                  changeAntiFraudValue(
                    "min_frames_for_decision",
                    Number(e.target.value) !== 0 ? Number(e.target.value) : 3
                  )
                }
                disabled={isEnabled("enabled") ? false : true}
              />
            </div>
          </OverlayTrigger>
        </div>
      
      {camera.is_android && (
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`text-truncate fw-bolder ${
              isEnabled("enabled") ? "" : "sr-disabled"
            }`}
            title="Enable Smile-To-Pass"
            style={{
              maxWidth: "200px",
              width: "100%",
            }}
          >
            Enable Smile-To-Pass
          </div>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Use Smile-To-Pass attribute to avoid fraud attempts
              </Tooltip>
            }
          >
            <div className="w-100" style={{ maxWidth: "300px" }}>
              <SwitchCheckbox
                disabled={isEnabled("enabled") ? false : true}
                checked={isEnabled("smile_to_pass_enable")}
                onChange={(e) =>
                  changeAntiFraudValue("smile_to_pass_enable", e.target.checked)
                }
              />
            </div>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
}

export default CameraSettingsAntiFraud;
