import React from "react";
import { Route, Routes } from "react-router-dom";
import Accounts from "./components/accounts/Accounts";
import MainCameras from "./components/cameras/MainCameras";
import Dashboard from "./components/dashboard/Dashboard";
import Faces from "./components/faces/Faces";
import FiqResults from "./components/fiq/fiqFaces/FiqResults";
import FiqPredict from "./components/fiq/predict/FiqPredict";
import LiveView from "./components/live-view/LiveView";
import { Login } from "./components/login/Login";
import { Groups } from "./components/people/Groups";
import MainPeople from "./components/people/MainPeople.js";
import People from "./components/people/People";
import Menu from "./components/main-menu/Menu";
import SystemSettings from "./components/system-settings/SystemSettings";
import Tags from "./components/tags/Tags";
import PersonTracking from "./components/person-tracks/PersonTracking";
import { Settings } from "./components/settings/Settings";

function FaceitRouter() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <>
            <Login />
          </>
        }
      />
      <Route
        path="/dashboard"
        element={
          <>
            <Menu activeComponent="dashboard" />
            <Dashboard />
          </>
        }
      />
      <Route
        path="/live"
        element={
          <>
            <Menu activeComponent="detections" />
            <LiveView />
          </>
        }
      />
      <Route
        path="/faces"
        element={
          <>
            <Menu activeComponent="detections" />
            <Faces />
          </>
        }
      />
      <Route
        path="/tags"
        element={
          <>
            <Menu activeComponent="detections" />
            <Tags />
          </>
        }
      />
      <Route
        path="/person-tracking"
        element={
          <>
            <Menu activeComponent="detections" />
            <PersonTracking />
          </>
        }
      />
      <Route
        path="/cameras"
        element={
          <>
            <Menu activeComponent="cameras" />
            <MainCameras />
          </>
        }
      />
      <Route
        path="/settings"
        element={
          <>
            <Menu activeComponent="settings" />
            <Settings />
          </>
        }
      />
      <Route
        path="/system"
        element={
          <>
            <Menu activeComponent="settings" />
            <SystemSettings />
          </>
        }
      />
      <Route
        path="/accounts"
        element={
          <>
            <Menu activeComponent="settings" />
            <Accounts />
          </>
        }
      />
      <Route
        path="/people/"
        element={
          <>
            <Menu activeComponent="people" />
            <MainPeople component="list" />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <People />
            </>
          }
        />
        <Route
          path="groups"
          element={
            <>
              <Groups />
            </>
          }
        />
        <Route
          path=""
          element={
            <>
              <People />
            </>
          }
        />
      </Route>
      <Route
        path="/fiq/"
        element={
          <>
            <Menu activeComponent="detections" />
            <FiqResults load={true} />
          </>
        }
      >
        <Route
          path="predict"
          element={
            <>
              <FiqPredict />
            </>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <>
            <Menu activeComponent="dashboard" />
            <Dashboard />
          </>
        }
      ></Route>
    </Routes>
  );
}

export default FaceitRouter;
