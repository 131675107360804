import { Button } from "@mui/material";
import React, { useState } from "react";
import { HiChevronDoubleDown } from "react-icons/hi";
import PersonCard from "./PersonCard";

const PAGE_SIZE = 30;

function PeopleGrid(props) {
  const { people, setSelectedPerson, setShowPersonModal } = props;
  const [page, setPage] = useState(1);

  return (
    <div className="row d-flex justify-content-center">
      {people.slice(0, page * PAGE_SIZE).map((person, i) => (
        <div
          key={`person-${i}`}
          className="col mb-2 d-flex justify-content-around"
        >
          <PersonCard
            setSelectedPerson={setSelectedPerson}
            setShowPersonModal={setShowPersonModal}
            person={person}
          />
        </div>
      ))}
      <div className="text-center">
        {people.length > PAGE_SIZE * page && (
          <Button
            startIcon={<HiChevronDoubleDown />}
            className="my-3 px-5"
            variant="contained"
            onClick={() => setPage(page + 1)}
          >
            Load More
          </Button>
        )}
      </div>
    </div>
  );
}

export default PeopleGrid;
