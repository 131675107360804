import React, { useState } from "react";
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";
import Swal from "sweetalert2/src/sweetalert2.js";
import { COLORS } from "../../../assets/colors";
import { FIQ_STATICS_URL, put } from "../../../faceit-api/FaceitApi";

const LABELS = {
  good: "Good",
  blur: "Blurring",
  dark: "Dark",
  face_mask: "Face mask",
  pose: "Head in profile",
  rotate: "Rotated",
  sunglasses: "Sunglasses",
};

function FiqFace(props) {
  const { fiqFace, i, reload } = props;
  const [hover, setHover] = useState(false);
  const [progress, setProgress] = useState(false);

  const labels = fiqFace.labels || fiqFace.all;

  const handleRightPrediction = () => {
    setProgress(true);
    put(`fiq/${fiqFace.id}/`, { is_right_prediction: true })
      .then((response) => {
        setProgress(false);
        if (response.status === 1) {
          Swal.fire({
            title: "Awesome 🥳",
            icon: "success",
            text: "Thanks to you we will be even better next time",
          }).then(() => reload());
        } else {
          Swal.fire({
            title: "Oops... 😕",
            icon: "error",
            text: response.data.errmsg,
          });
        }
      })
      .catch((err) => {
        setProgress(false);
        Swal.fire({
          title: "Oops... 😕",
          icon: "error",
          text: err,
        });
      });
  };
  const handleWrongPrediction = () => {
    setProgress(true);
    Swal.fire({
      title: "Choose the right label:",
      input: "select",
      inputOptions: LABELS,
      inputPlaceholder: "Select label",
      confirmButtonText: "Send",
      showCancelButton: true,
    }).then((send) => {
      if (send.value) {
        put(`fiq/${fiqFace.id}/`, {
          is_right_prediction: false,
          label_correction: send.value,
        })
          .then((response) => {
            setProgress(false);
            if (response.status === 1) {
              Swal.fire({
                title: "Awesome 🥳",
                icon: "success",
                text: "Thanks to you we will be even better next time",
              }).then(() => reload());
            } else {
              Swal.fire({
                title: "Oops... 😕",
                icon: "error",
                text: response.errmsg,
              });
            }
          })
          .catch((err) => {
            setProgress(false);
            Swal.fire({
              title: "Oops... 😕",
              icon: "error",
              text: err,
            });
          });
      } else {
        setProgress(false);
      }
    });
  };

  return (
    <div
      style={{
        position: "relative",
        height: "200px",
        width: "200px",
        margin: "auto",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!hover && (
        <div
          className="w-100 p-2 fw-bold text-center"
          style={{
            position: "absolute",
            top: "0px",
            borderRadius: "6px",
            color: COLORS.Gray600,
            backgroundColor: COLORS.TransparentWhite,
          }}
        >
          {`${LABELS[fiqFace.label] || "Error"} - ${parseInt(
            fiqFace.conf * 100
          )}%`}
          {fiqFace.is_right_prediction !== null && (
            <span className="me-auto">
              {fiqFace.is_right_prediction ? (
                <FaCheck style={{ float: "right", color: COLORS.green }} />
              ) : (
                <FaTimes style={{ float: "right", color: COLORS.red }} />
              )}
            </span>
          )}
        </div>
      )}
      <img
        src={FIQ_STATICS_URL + fiqFace.image}
        alt={"fiq-face-" + i}
        style={{
          height: "200px",
          width: "200px",
          borderRadius: "6px",
        }}
      />
      {hover && (
        <div
          className="p-2 fw-bold "
          style={{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            height: "100%",
            width: "200px",
            color: COLORS.Gray600,
            backgroundColor: COLORS.Gray100,
            borderRadius: "6px",
          }}
        >
          <div className="text-start">
            {labels
              .filter((f) => f.conf > 0.1)
              .map((label, i) => (
                <div key={i} className="row">
                  <div className="col">{LABELS[label.label]}</div>
                  <div className="col d-flex" style={{ fontSize: "12px" }}>
                    <span className="me-1">
                      {parseInt(label.conf * 100) + "%"}
                    </span>

                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: parseInt(label.conf * 100),
                          backgroundColor: COLORS.primary,
                        }}
                        aria-valuenow={parseInt(label.conf * 100)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              ))}
            {fiqFace.is_right_prediction !== null && (
              <span className="me-auto">
                {fiqFace.is_right_prediction ? (
                  <div className="mt-1 fw-normal" style={{ fontSize: "12px" }}>
                    Marked as a correct predication manually
                  </div>
                ) : (
                  <div className="mt-1 fw-normal" style={{ fontSize: "12px" }}>
                    {`Marked as incorrect predication, the correct label is "${
                      LABELS[fiqFace.label_correction]
                    }"`}
                  </div>
                )}
              </span>
            )}
            <div className="text-center mt-2 justify-content-between">
              <div>Is that right?</div>
              {progress ? (
                <div>
                  <FaSpinner
                    className="fa-spin mt-2 fs-4"
                    style={{ color: COLORS.primaryTauriel }}
                  />
                </div>
              ) : (
                <div className="row mt-2">
                  <div className="col-6">
                    <button
                      className="sr-btn  d-flex align-items-center p-1 justify-content-center"
                      onClick={handleRightPrediction}
                    >
                      <FaCheck
                        className="me-2"
                        style={{ color: COLORS.green }}
                      />{" "}
                      Yes
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="sr-btn  d-flex align-items-center p-1 justify-content-center"
                      onClick={handleWrongPrediction}
                    >
                      <FaTimes className="me-2" style={{ color: COLORS.red }} />{" "}
                      No
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FiqFace;
