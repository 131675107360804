import React, { useEffect, useState } from "react";
import { isWeakPassword } from "../../utils/helper";
import { post } from "../../faceit-api/FaceitApi";
import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2/src/sweetalert2";
import { Modal } from "react-bootstrap";

export function AddAccountModal({
  show,
  onHide,
  integratorsList,
  integratorAdmin,
}) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isStaff, setIsStaff] = useState(false);
  const [isIntegratorAdmin, setIsIntegratorAdmin] = useState(false);
  const [integrator, setIntegrator] = useState("");
  const [integratorOption, setIntegratorOption] = useState([]);

  useEffect(() => {
    setIntegratorOption(
      integratorsList?.map((i) => ({ value: i, label: i })) || []
    );
  }, [integratorsList]);

  const checkBeforeAdd = () => {
    if (!username && !password && !passwordConfirm) {
      return "";
    }
    if (!username) {
      return "Username cannot be empty";
    }
    if (passwordConfirm && password !== passwordConfirm) {
      return "Passwords didn't match";
    }
    if (isStaff && isIntegratorAdmin) {
      return "User cannot be both Staff and Integrator Admin";
    }
    if (password && isWeakPassword(password)) {
      return "Weak Password. Please contains at least 8 characters, including digit, uppercase and lowercase letter.";
    }
    return "ok";
  };

  const CreateNewUser = () => {
    var data = {
      username: username,
      password: password,
      password_confirm: passwordConfirm,
      first_name: first_name,
      last_name: last_name,
      email: email,
      is_staff: isStaff,
      integrator: integrator,
      is_integrator_admin: isIntegratorAdmin,
    };
    post(`users`, data).then((resp) => {
      if (resp.status === 1) {
        Swal.fire({
          icon: "success",
          title: "Awesome 🥳",
          text: "User has been added successfully",
        }).then(() => {
          onHide();
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ooops... 😕",
          text: resp.message || "Failed to add user",
        });
      }
    });
  };

  const handleNewIntegrator = (newValue) => {
    if (!integratorAdmin) {
      Swal.fire({
        title: `Create New Integrator "${newValue}"?`,
        text: `Only Tenant Admins can create new integrators...`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setIntegrator(newValue);
          setIntegratorOption([
            ...integratorOption,
            { value: newValue, label: newValue },
          ]);
        } else {
          setIntegrator("");
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Ooops... 😕",
        text: "You are not aloud to create new integrator",
      }).then(() => setIntegrator(""));
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <div style={{ backgroundColor: "#212529", border: "2px solid gray" }}>
        <Modal.Header>
          <Modal.Title style={{ color: "#e5e5e5" }}>
            Create new user
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#e5e5e5" }}>
          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">Username: </label>
            <input
              type="text"
              className="sr-text-input mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">Password: </label>
            <input
              type="password"
              className="sr-text-input mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">
              Password confirm:{" "}
            </label>
            <input
              type="password"
              className="sr-text-input mt-1"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>

          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">First Name: </label>
            <input
              type="text"
              className="sr-text-input mt-1"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">Last Name: </label>
            <input
              type="text"
              className="sr-text-input mt-1"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">Email:</label>
            <input
              type="text"
              className="sr-text-input mt-1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center mb-2 ">
            <label className="col-5 col-lg-3 text-start">Integrator: </label>
            <CreatableSelect
              className="react-select-container mt-1 w-100"
              classNamePrefix="react-select"
              isClearable
              value={integratorOption.find((i) => i.value === integrator)}
              onChange={(newValue) => {
                if (newValue) {
                  setIntegrator(newValue.value);
                } else {
                  setIntegrator("");
                }
              }}
              options={integratorOption}
              onCreateOption={handleNewIntegrator}
            />
          </div>
          {integrator || integratorAdmin ? (
            <div className="d-flex align-items-center mb-2 ">
              <label className="col-5 col-lg-3 text-start">
                Integrator Admin:{" "}
              </label>
              <input
                type="checkbox"
                className=" mt-1"
                checked={isIntegratorAdmin}
                onChange={(e) => setIsIntegratorAdmin(e.target.checked)}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center mb-2 ">
              <label className="col-5 col-lg-3 text-start">Staff: </label>
              <input
                type="checkbox"
                className=" mt-1"
                checked={isStaff}
                onChange={(e) => setIsStaff(e.target.checked)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {checkBeforeAdd() !== "ok" && (
            <div className="text-danger">{checkBeforeAdd()}</div>
          )}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onHide}
            style={{ borderWidth: "0px" }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{
              backgroundColor: "#176766",
              color: "#e5e5e5",
              borderWidth: "0px",
            }}
            onClick={CreateNewUser}
            disabled={checkBeforeAdd() !== "ok"}
          >
            Add
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
