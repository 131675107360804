import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { get } from "../../faceit-api/FaceitApi";
import FaceCardUnkownSmall from "../face/FaceCardUnkownSmall";
import { isFaceModalOrPopupOpen } from "./LiveView";

function LiveViewUnkowns(props) {
  const { selectedCameras, events } = props;

  const [isFetchingFaces, setIsFetchingFaces] = useState(false);
  const [faces, setFaces] = useState([]);

  useEffect(() => {
    if (selectedCameras.length === 0) return;
    setIsFetchingFaces(true);
    get(
      `faces?page_size=20&details=true&camera_id=${selectedCameras.map(
        (c) => c.value
      )}&group_id=unidentified`
    ).then((resp) => {
      setIsFetchingFaces(false);
      if (resp.results) {
        setFaces(resp.results);
      }
    });
  }, [selectedCameras]);

  useEffect(() => {
    if (events.length === 0) return;
    if (isFaceModalOrPopupOpen()) return;
    setFaces((state) => [...events, ...state]);
  }, [events]);

  return (
    <div className=" mt-2">
      <div className=" d-flex justify-content-between align-items-center">
        <div className="fw-bold">Unrecognized faces</div>
      </div>
      <div
        className="row mt-2 d-flex justify-content-center"
        key={faces.length}
      >
        {isFetchingFaces && <FaSpinner className="fa-spin my-2 fs-5" />}
        {faces
          .sort((a, b) => new Date(b.create_at) - new Date(a.create_at))
          .map((face) => (
            <div
              key={`unkown-face-${face.image}`}
              className="col mb-2"
              style={{ maxWidth: "220px" }}
            >
              <FaceCardUnkownSmall face={face} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default LiveViewUnkowns;
