import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function SecusysApi(props) {
  const { camera, setCamera, onControllerChange, index } = props;

  const onChange = (value, key) => {
    if (setCamera) {
      setCamera({
        ...camera,
        [key]: value,
      });
    } else {
      onControllerChange(index, key, value);
    }
  };

  return (
    <div className="d-flex flex-column gap-8">
      {" "}
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {
              "URL to the to the hardware component responsible for opening the door"
            }
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="API URL"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            API URL
          </div>
          <input
            className="sr-text-input w-100"
            style={{
              maxWidth: "300px",
            }}
            type="text"
            placeholder="http://10.0.0.0/IntegraionWebService/WebService/WebTestEng.aspx"
            value={camera.executor_url}
            onChange={(e) => onChange(e.target.value, "executor_url")}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{"API Username"}</Tooltip>}
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="API User Name"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            API User Name
          </div>
          <input
            className="sr-text-input w-100"
            style={{
              maxWidth: "300px",
            }}
            type="text"
            placeholder="Administrator..."
            value={camera.secusys_api_username}
            onChange={(e) => onChange(e.target.value, "secusys_api_username")}
          />
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>{"API Password"}</Tooltip>}
      >
        <div className="d-flex align-items-center justify-content-start gap-8">
          <div
            className={`d-flex text-truncate fw-bolder ${
              camera.executor === "disabled" ? "sr-disabled" : ""
            }`}
            title="API Password"
            style={{
              maxWidth: "200px",
              width: "200px",
            }}
          >
            API Password
          </div>
          <input
            className="sr-text-input w-100"
            style={{
              maxWidth: "300px",
            }}
            type="password"
            placeholder="****"
            value={camera.secusys_api_password}
            onChange={(e) => onChange(e.target.value, "secusys_api_password")}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default SecusysApi;
