import { DialogContent, IconButton } from "@mui/material";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";

function TrackingFacesModal({ relatedFaces, close, showFaceModal }) {
  return (
    <div className="px-5 py-3 h-100">
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <FaTimes />
      </IconButton>
      <DialogContent>
        <div className="row" style={{ maxHeight: "400px" }}>
          {relatedFaces.map((face) => (
            <div
              key={`related_faces_${face}`}
              className="col m-2 pointer text-center"
              style={{ borderRadius: "12px" }}
              onClick={() => {
                showFaceModal(face);
                close();
              }}
            >
              <img
                alt="related-face"
                className="shadow-hover"
                src={`${IMAGE_BASE_PATH}${face}`}
                height="100"
                width="100"
                style={{ borderRadius: "12px" }}
              />
            </div>
          ))}
        </div>
      </DialogContent>
    </div>
  );
}

export default TrackingFacesModal;
