import styled from "styled-components";

export const PersonImageCircle = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  right: 12px;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  /* Box shadow */
  filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.2));

  &:hover {
    height: 140px;
    width: 140px;
    border-radius: 8px;
    position: absolute;
  }
`;
