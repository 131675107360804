import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteItem, get } from "../faceit-api/FaceitApi";
import { DEFAULT_STALE_TIME } from "../utils/helper";

async function getPersonTracking(params) {
  return get(`person-tracking?${new URLSearchParams(params).toString()}`).then(
    (resp) => {
      return resp;
    }
  );
}

async function deletePersonTracking({ personTrackingId }) {
  return deleteItem(`person-tracking/bulk?id=${personTrackingId}`);
}

export const useApiPersonTrackingFilteredPagination = (
  paginationPerPage,
  ordering,
  page,
  filters
) => {
  const readyToUseFilters = {
    ...{
      page_size: paginationPerPage,
      ordering: ordering,
      page: page,
    },
    ...filters,
  };

  return useQuery(
    ["getPersonTracking", readyToUseFilters],
    () => getPersonTracking(readyToUseFilters),
    {
      keepPreviousData: true,
      staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
    }
  );
};

export const useApiDeletePersonTracking = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePersonTracking, {
    onSuccess: (
      resp,
      { personTrackingId, onSuccessCallback, onErrorCallback }
    ) => {
      if (resp.status === 1) {
        queryClient.setQueryData("getPersonTracking", (oldPersonTracking) =>
          oldPersonTracking?.filter((t) => t.id !== personTrackingId)
        );
        return onSuccessCallback();
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
    onSettled: () => queryClient.invalidateQueries("getPersonTracking"),
  });
};
