import React from "react";
import { IoWarningOutline } from "react-icons/io5";
import { COLORS } from "../../../assets/colors";

export default function InactiveBanner(props) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.2em",
            color: "black",
            backgroundColor: COLORS.inactiveBackground,
            borderRadius: "8px",
            border: `2px solid ${COLORS.inactiveBorder}`,
            height: "40px",
            width: "200px",
            gap: "5px",
        }}
        >
        <IoWarningOutline
            style={{
            width: "25px",
            height: "25px",
            color: COLORS.inactiveBorder,
            }}
        />
        {props.children}
        </div>
        </div>
    );
    }

