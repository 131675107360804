import moment from "moment";
import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRegPlayCircle } from "react-icons/fa";
import { useApiCamerasData } from "../../context/camerasContext";
import { useApiPersonsData } from "../../context/personsContext";
import { playVideoClip } from "../../popups/opretaions";
import { dateDisplay } from "../../utils/helper";
import FaceRecognizedModal from "./FaceRecognizedModal";
import { useIsMobile } from "../../utils/hooks";
import { FaceImage } from "./FaceImage";

function FaceCardUnkownSmall(props) {
  const { face } = props;
  const isMobile = useIsMobile();
  const [showDate, setShowDate] = useState(false);
  const [showFaceModal, setShowFaceModal] = useState(false);
  const { data: cameras } = useApiCamerasData();
  const { data: persons } = useApiPersonsData();

  const faceExtra = {
    video: face.video ? face.video : face.face_extra?.video,
  };

  return (
    <div className="text-center" style={{ width: "100px" }}>
      <Modal
        show={showFaceModal}
        onHide={() => setShowFaceModal(false)}
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <FaceRecognizedModal
          close={() => setShowFaceModal(false)}
          camera={cameras.find((c) => c.id === face.camera_id) || {}}
          face={face}
          person={persons.find((p) => p.id === face.predicted_id) || {}}
          isUnknown={true}
        />
      </Modal>

      <div style={{ width: "100px" }} className=" mx-auto">
        <FaceImage
          face={face}
          size="100px"
          onClick={() => setShowFaceModal(true)}
        />
      </div>
      <div style={{ width: "100px" }}>
        <div className="row d-flex  mt-0 pt-0 ">
          <div className="col-9">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{dateDisplay(new Date(face.create_at))}</Tooltip>
              }
            >
              <div
                className="text-truncate"
                onClick={() => setShowDate(!showDate)}
                style={{ fontSize: "10px", cursor: "pointer" }}
              >
                {showDate
                  ? dateDisplay(new Date(face.create_at))
                  : moment(new Date(face.create_at)).fromNow()}
              </div>
            </OverlayTrigger>
          </div>
          <div className="col-1 d-flex align-items-start">
            {faceExtra.video && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Watch a short video of this event</Tooltip>}
              >
                <div className="d-flex align-items-start mt-1">
                  <FaRegPlayCircle
                    style={{ fontSize: "10px", cursor: "pointer" }}
                    onClick={() => playVideoClip(faceExtra.video, isMobile)}
                  />
                </div>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceCardUnkownSmall;
