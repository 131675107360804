import React from "react";
import Popup from "reactjs-popup";

export function Menu(props) {
  const { trigger, children, position = "bottom", width = "200px" } = props;

  return (
    <Popup
      className=""
      trigger={trigger}
      on="click"
      closeOnDocumentClick
      closeOnEscape
      position={position}
      contentStyle={{
        width: width,
      }}
    >
      {children || <></>}
    </Popup>
  );
}
