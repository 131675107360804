import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { COLORS } from "../../assets/colors";

function DetailLine(props) {
  const { tooltip, color, text, icon } = props;
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
      <div
        className="text-truncate d-flex align-items-center mb-1"
        style={{ fontSize: "16px", lineHeight: "22px" }}
      >
        <div
          className="p-1 d-flex me-2"
          style={{
            width: "fit-content",
            border: "solid",
            borderWidth: "1px",
            borderRadius: "4px",
            borderColor: color || COLORS.primary,
            color: color || COLORS.primary,
          }}
        >
          {icon}
        </div>
        {text}
      </div>
    </OverlayTrigger>
  );
}

export default DetailLine;
