import React from "react";
import Gollum from "./Gollum";
import MoxaE1214 from "./MoxaE1214";
import SecusysApi from "./SecusysApi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { controllersSupported } from "../CameraSettingsAccessControl";
import { COLORS } from "../../../assets/colors";
import { FaTimes } from "react-icons/fa";
import CustomCheckbox from "../../general/CustomCheckbox";

function Controller(props) {
  const {
    controller,
    groupsOptions,
    changeControllerValue,
    index,
    removeController,
  } = props;

  const animatedComponents = makeAnimated();

  return (
    <div
      className="my-2 p-3"
      style={{
        border: "solid 1px",
        borderColor: COLORS.Gray600,
        borderRadius: "6px",
        position: "relative",
      }}
    >
      <FaTimes
        style={{
          position: "absolute",
          top: "15px",
          right: "15px",
          fontSize: "20px",
          cursor: "pointer",
        }}
        onClick={() => removeController(index)}
      />

      <div className="d-flex align-items-center row mb-3">
        <lable
          className={`col-12 col-lg-3 ${
            controller.type === "disabled" ? "sr-disabled" : ""
          }`}
        >
          Apply on groups
        </lable>
        <div
          className={`col-12 col-lg-8  px-0 ${
            controller.type === "disabled" ? "sr-disabled" : ""
          }`}
        >
          <Select
            isDisabled={controller.type === "disabled"}
            className="react-select-container w-100"
            classNamePrefix="react-select"
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={groupsOptions}
            value={groupsOptions.filter(
              (g) =>
                controller.groups && controller.groups.includes(g.value.trim())
            )}
            //(index, key, value)
            onChange={(options) =>
              changeControllerValue(
                index,
                "groups",
                options.map((o) => o.value.trim())
              )
            }
            theme="dark"
          />
        </div>
      </div>

      <div className="d-flex align-items-center row mb-3">
        <lable
          className={`col-12 col-lg-3 ${
            controller.type === "disabled" ? "sr-disabled" : ""
          }`}
        >
          Controller type
        </lable>
        <div className="col-12 col-lg-8  px-0">
          <Select
            isDisabled={controller.type === "disabled"}
            className={`react-select-container`}
            classNamePrefix="react-select"
            placeholder="Select..."
            value={controllersSupported.find(
              (c) => c.value === controller.type
            )}
            onChange={(e) => changeControllerValue(index, "type", e.value)}
            options={controllersSupported}
            theme="dark"
          />
        </div>
      </div>

      {controller.type === "send_url" && (
        <Gollum
          camera={controller}
          setCamera={null}
          onControllerChange={changeControllerValue}
          index={index}
        />
      )}
      {controller.type === "moxa_e1214" && (
        <MoxaE1214
          camera={controller}
          setCamera={null}
          onControllerChange={changeControllerValue}
          index={index}
        />
      )}
      {controller.type === "secusys_api" && (
        <SecusysApi
          camera={controller}
          setCamera={null}
          onControllerChange={changeControllerValue}
          index={index}
        />
      )}

      <div className="d-flex align-items-center">
        <CustomCheckbox
          checked={controller.is_door}
          onChange={(e) =>
            changeControllerValue(index, "is_door", e.target.checked)
          }
        />
        <lable className="ms-2" style={{ fontSize: "12px" }}>
          Is a door
        </lable>
      </div>
    </div>
  );
}

export default Controller;
