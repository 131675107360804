import React, { useContext, useState } from "react";
import MainContainer from "../main-menu/MainContainer";
import DataTable, { createTheme } from "react-data-table-component";
import { COLORS } from "../../assets/colors";
import { deleteItem, IMAGE_BASE_PATH, post } from "../../faceit-api/FaceitApi";
import { FaCheck, FaTimes, FaTrash, FaUserEdit } from "react-icons/fa";
import IconUser from "../../assets/icons/user.svg";
import Swal from "sweetalert2/src/sweetalert2.js";
import ReactDOMServer from "react-dom/server";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { DataContext } from "../../App";
import Select from "react-select";
import { MdGroupAdd } from "react-icons/md";
import { useIsMobile } from "../../utils/hooks";
import { AddAccountModal } from "./AddAccountModal";
import { EditAccountModal } from "./EditAccount";
import { Modal } from "react-bootstrap";
import {
  useApiIntegratorsData,
  useApiTenantsData,
  useApiUsersData,
} from "../../context/usersContext";

function Accounts() {
  const isMobile = useIsMobile();
  const { data: users, isFetching: isFetchingUsers } = useApiUsersData();
  const { data: integrators, isFetching: isFetchingIntegrators } =
    useApiIntegratorsData();
  const { data: tenants } = useApiTenantsData();
  const { userInfo } = useContext(DataContext);
  const [showAddUserModal, setShowAddUser] = useState(false);
  const [showEditUserModal, setShowEditUser] = useState(false);
  const [user, setUserToEdit] = useState({});

  const tenantsOptions = tenants.map((t) => {
    return { label: t.name, value: t.id };
  });

  // const userExtension = (user) => {
  //   return {
  //     integrator: integrators.find((i) => i.username === user.username),
  //   };
  // };

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const moveTenant = (tenant_id) => {
    post(`users/move_tenant`, {
      tenant_id: tenant_id,
    }).then((resp) => {
      if (resp.status === 1) {
        showSuccessDialog(
          `The transition was completed successfully, you are now part of the tenant group: ${
            tenants.find((t) => t.id === tenant_id)?.name
          }`
        ).then(() => window.location.reload());
      } else {
        showErrorDialog(resp.message || "Failed to create tenant");
      }
    });
  };

  const addTenant = () => {
    Swal.fire({
      title: "Enter tenant name",
      showCancelButton: true,
      input: "text",
      confirmButtonColor: COLORS.primary,
      cancelButtonColor: "#afa7a7",
      confirmButtonText: "Create",
      preConfirm: (input) => {
        if (!input) {
          Swal.showValidationMessage("Name cannot be empty");
          return false;
        } else {
          return input;
        }
      },
    }).then((result) => {
      if (result.value) {
        post(`tenants`, {
          name: result.value,
        }).then((resp) => {
          if (resp.status === 1) {
            showSuccessDialog("Tenant has been created successfully").then(() =>
              window.location.reload()
            );
          } else {
            showErrorDialog(resp.message || "Failed to create tenant");
          }
        });
      }
    });
  };

  function openAddUserModal() {
    setShowAddUser(true);
  }

  function closeAddUserModal() {
    setShowAddUser(false);
  }

  function openEditUserModal(row) {
    setShowEditUser(true);
    setUserToEdit(row);
  }
  function closeEditUserModal() {
    setShowEditUser(false);
  }

  function deleteAccount(user) {
    Swal.fire({
      title: `Are you sure you want to delete ${user.username}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: COLORS.primary,
      cancelButtonColor: "#afa7a7",
      confirmButtonText: "Yes, delete him!",
      html: ReactDOMServer.renderToString(
        <div className="">
          <img
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "8px",
            }}
            alt="avatar"
            src={user.avatar ? `${IMAGE_BASE_PATH}/${user.avatar}` : IconUser}
          />
          <h6 className="mt-2 font-weight-bold">
            You won't be able to revert this!
          </h6>
        </div>
      ),
    }).then((result) => {
      if (result.value) {
        deleteItem(`users/${user.id}`).then((resp) => {
          if (resp.status === 1) {
            showSuccessDialog("User has been deleted successfully").then(() =>
              window.location.reload()
            );
          } else {
            showErrorDialog(resp.message || "Failed to delete user");
          }
        });
      }
    });
  }

  // const integratorsList = Array.from(new Set(integrators?.map((i) => i.integrator))) || [];
  const columns = [
    {
      name: "Avatar",
      id: "avatar",
      selector: (row) => row.avatar,
      sortable: false,
      cell: (row) => (
        <div className="py-3">
          <img
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
            }}
            src={row.avatar ? `${IMAGE_BASE_PATH}/${row.avatar}` : IconUser}
            alt="user-avatar"
          />
        </div>
      ),
    },
    {
      name: "Username",
      id: "username",
      selector: (row) => row.username,
      sortable: false,
    },
    {
      name: "Name",
      id: "name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: false,
    },
    {
      name: "Email",
      id: "email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Integrator",
      id: "integrator",
      selector: (row) => row.integrator,
      sortable: false,
      cell: (row) => (
        <div>
          {row.integrator ? (
            <span className="text">{row.integrator}</span>
          ) : (
            <span className="text-muted"></span>
          )}
        </div>
      ),
    },
    {
      name: "Is Admin", // is_staff or is integrator admin
      id: "admin",
      selector: (row) => row.is_staff && row.is_integrator_admin,
      sortable: false,
      cell: (row) => (
        <div>
          {row.is_staff || row.is_integrator_admin ? (
            <FaCheck className="fs-5" color={COLORS.PrimaryLight} />
          ) : (
            <FaTimes className="fs-5" color={COLORS.red} />
          )}
        </div>
      ),
    },
    {
      name: "Last login",
      id: "last_login",
      selector: (row) => row.last_login,
      sortable: false,
      cell: (row) => (
        <div>
          <span
            title={new Date(row["last_login"]).toLocaleString()}
            className="fw-bold"
          >
            {new Date(row["last_login"]).toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      name: "Created",
      id: "created",
      selector: (row) => row.date_joined,
      sortable: false,
      cell: (row) => (
        <div>
          <span
            title={new Date(row["date_joined"]).toLocaleString()}
            className="fw-bold"
          >
            {new Date(row["date_joined"]).toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      name: "Actions",
      id: "actions",
      selector: (row) => row.date_joined,
      sortable: false,
      cell: (row) =>
        row.is_superuser ? (
          <></>
        ) : (
          <div className="d-flex align-items-center">
            {!isFetchingIntegrators && (
              <FaUserEdit
                color={COLORS.PrimaryLight}
                className="pointer fs-5 me-4"
                onClick={() => {
                  openEditUserModal(row);
                }}
              />
            )}
            <FaTrash
              color={COLORS.red}
              className="pointer fs-6 "
              onClick={() => deleteAccount(row)}
            />
          </div>
        ),
    },
  ];

  return (
    <MainContainer>
      <div
        className={`d-flex justify-content-between gap-2 ${
          isMobile ? "flex-column align-items-start " : "align-items-center "
        }`}
      >
        <div className="fs-4 fw-bold">Accounts</div>
        <div
          className={`d-flex justify-content-between gap-2 ${
            isMobile ? "flex-column" : ""
          }`}
        >
          <div className="d-flex gap-4">
            {(userInfo?.is_staff || userInfo?.is_integrator_admin) && (
              <div>
                <button
                  className="sr-btn d-flex align-items-center"
                  onClick={openAddUserModal}
                  style={{ width: "fit-content" }}
                  disabled={isFetchingIntegrators}
                >
                  <MdGroupAdd className="me-2" />
                  Add User
                </button>
                <AddAccountModal
                  show={showAddUserModal}
                  onHide={closeAddUserModal}
                  integratorsList={integrators}
                  integratorAdmin={userInfo?.is_integrator_admin}
                />
              </div>
            )}
            {userInfo?.is_superuser && (
              <button
                className="sr-btn d-flex align-items-center"
                onClick={addTenant}
                style={{ width: "fit-content" }}
              >
                <MdGroupAdd className="me-2" />
                Add tenant
              </button>
            )}
          </div>
          {userInfo?.is_superuser && (
            <div className="d-flex align-items-center">
              <span className="me-3">Go to other tenant</span>
              <span
                style={{
                  width: "180px",
                }}
              >
                <Select
                  className="react-select-container w-100"
                  classNamePrefix="react-select"
                  placeholder="Select tenant"
                  options={tenantsOptions}
                  value={tenantsOptions.find(
                    (o) => o.value === userInfo?.tenant_id
                  )}
                  onChange={(e) => moveTenant(e.value)}
                  theme="dark"
                />
              </span>
            </div>
          )}
        </div>

        <Modal
          show={showEditUserModal}
          onHide={() => closeEditUserModal()}
          backdrop="static"
          keyboard={false}
          size="s"
          fullscreen={"md-down"}
        >
          <EditAccountModal
            close={(updated) => closeEditUserModal(updated)}
            userToEdit={user}
            integratorsList={integrators}
            integratorAdmin={userInfo?.is_integrator_admin}
          />
        </Modal>
      </div>
      <hr />
      <div>
        <DataTable
          key={users.length}
          columns={columns}
          data={users}
          pagination
          highlightOnHover
          paginationTotalRows={users?.length || 0}
          paginationRowsPerPageOptions={[25, 50, 100]}
          noDataComponent={
            isFetchingUsers ? "Loading users..." : "No users found"
          }
          theme="solarized"
        />
      </div>
    </MainContainer>
  );
}

export default Accounts;
