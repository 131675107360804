import React, { useState } from "react";
import { COLORS } from "../../assets/colors";
import DataTable, { createTheme } from "react-data-table-component";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaFileAlt, FaPlay, FaSpinner, FaStop, FaSync } from "react-icons/fa";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import LogsModal from "./LogsModal";
import {
  useApiContainers,
  useApiTriggerContainers,
} from "../../context/dockerContainersContext";
import {
  DockerContainer,
  DockerContainerAction,
  DockerContainerState,
} from "../../types/DockerContainer";

function ContainersTable() {
  const [selectedContainers, setSelectedContainers] = useState<
    DockerContainer[]
  >([]);
  const [showLogsModal, setShowLogsModal] = useState<boolean>(false);
  const [lastClickedAction, setLastClickedAction] =
    useState<DockerContainerAction | null>(null);
  const [containerLogsName, setContainerLogsName] = useState<string | null>(
    null
  );

  const { data: containers, isFetching } = useApiContainers();

  const { mutate: trigger, isLoading: isActionInProgress } =
    useApiTriggerContainers();

  const getStateColor = (state: DockerContainerState) => {
    if (["exited"].includes(state)) {
      return "red";
    }
    if (["running"].includes(state)) {
      return "green";
    } else {
      return "blue";
    }
  };

  const onSelectedRowsChange = ({ selectedRows }: any) => {
    setSelectedContainers(selectedRows);
  };

  const triggerContainers = (action: DockerContainerAction) => {
    setLastClickedAction(action);
    trigger({
      updateData: {
        containers: selectedContainers,
        action: action,
      },
      onSuccessCallback: () => {
        showSuccessDialog(`Operation ${action} ended successfully`);
      },
      onErrorCallback: (error) => {
        showErrorDialog(error.message || `Failed to ${action} containers`);
      },
    });
  };

  const columns = [
    {
      name: "Name",
      id: "name",
      selector: (row: DockerContainer) => row.name,
      sortable: false,
      cell: (row: DockerContainer) => (
        <div style={{ display: "contents" }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.name}</Tooltip>}
          >
            <span className="text-truncate fw-bold">{row.name}</span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Logs",
      id: "logs",
      selector: (row: DockerContainer) => row.name,
      sortable: false,
      cell: (row: DockerContainer) => (
        <div style={{ display: "contents" }}>
          <FaFileAlt
            className="fs-4 pointer"
            style={{ color: COLORS.PrimaryLight }}
            onClick={() => {
              setContainerLogsName(row.name);
              setShowLogsModal(true);
            }}
          />
        </div>
      ),
    },
    {
      name: "State",
      id: "state",
      selector: (row: DockerContainer) => row.state,
      sortable: false,
      cell: (row: DockerContainer) => (
        <div
          className="p-3 w-50 text-center fw-bold m-1"
          style={{
            borderRadius: "8px",
            backgroundColor: COLORS.GROUP.LIGHT[getStateColor(row.state)],
            color: COLORS.GROUP[getStateColor(row.state)],
          }}
        >
          {row.state}
        </div>
      ),
    },
  ];

  createTheme(
    "solarized",
    {
      text: {
        primary: COLORS.Gray50,
        secondary: COLORS.Gray100,
      },
      background: {
        default: COLORS.BgBlack,
      },
      context: {
        background: "#cb4b16",
        text: COLORS.Gray50,
      },
      divider: {
        default: COLORS.Gray50,
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: COLORS.BgBlack,
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  return (
    <div>
      <Modal
        show={showLogsModal}
        onHide={() => setShowLogsModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen={"md-down"}
      >
        <LogsModal
          close={() => setShowLogsModal(false)}
          containerName={containerLogsName}
        />
      </Modal>

      <div className="mt-5">
        <div className="p-3" id="system_services_buttons">
          <div className="btn-group" role="group">
            <button
              type="button"
              id="containers_start"
              className="d-flex align-items-center btn  sr-btn-hover"
              disabled={
                isActionInProgress ||
                !selectedContainers.some((c) => c.state !== "running")
              }
              style={{
                color: COLORS.FontGray,
                backgroundColor: COLORS.GROUP.green,
              }}
              onClick={() => triggerContainers("start")}
            >
              {isActionInProgress && lastClickedAction === "start" ? (
                <FaSpinner className="fa-spin me-2" />
              ) : (
                <FaPlay className="me-2" />
              )}
              Start
            </button>
            <button
              type="button"
              id="containers_stop"
              className="d-flex align-items-center btn sr-btn-hover"
              disabled={
                isActionInProgress ||
                !selectedContainers.some((c) => c.state !== "exited")
              }
              style={{
                color: COLORS.FontGray,
                backgroundColor: COLORS.GROUP.red,
              }}
              onClick={() => triggerContainers("stop")}
            >
              {isActionInProgress && lastClickedAction === "stop" ? (
                <FaSpinner className="fa-spin me-2" />
              ) : (
                <FaStop className="me-2" />
              )}
              Stop
            </button>
            <button
              type="button"
              id="containers_restart"
              className="d-flex align-items-center btn sr-btn-hover"
              disabled={
                isActionInProgress ||
                !selectedContainers.some((c) => c.state !== "restarting")
              }
              style={{
                color: COLORS.FontGray,
                backgroundColor: COLORS.GROUP.blue,
              }}
              onClick={() => triggerContainers("restart")}
            >
              {isActionInProgress && lastClickedAction === "restart" ? (
                <FaSpinner className="fa-spin me-2" />
              ) : (
                <FaSync className="me-2" />
              )}
              Restart
            </button>
          </div>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={containers || []}
        pagination
        highlightOnHover
        paginationTotalRows={containers?.length || 0}
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationPerPage={25}
        selectableRows // add for checkbox selection
        noDataComponent={
          isFetching ? "Loading containers..." : "No containers found"
        }
        theme="solarized"
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowsHighlight={true}
      />
    </div>
  );
}

export default ContainersTable;
