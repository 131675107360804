import { get } from "../faceit-api/FaceitApi";
import { DEFAULT_STALE_TIME, bitAfterNow } from "../utils/helper";
import { useQuery } from "react-query";

const USERS_PAGE_SIZE = 10000;

async function getUsers() {
  return get(`users?page_size=${USERS_PAGE_SIZE}`).then((resp) => {
    return resp?.results || [];
  });
}

export const useApiUsersData = () => {
  return useQuery("getUsers", getUsers, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

async function getIntegrators() {
  return get(`users/integrators`).then((resp) => {
    return resp?.results || [];
  });
}

export const useApiIntegratorsData = () => {
  return useQuery("getIntegrators", getIntegrators, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};

async function getTenants() {
  return get(`tenants`).then((resp) => {
    return resp?.results || [];
  });
}

export const useApiTenantsData = () => {
  return useQuery("getTenants", getTenants, {
    keepPreviousData: true,
    initialData: [],
    initialDataUpdatedAt: bitAfterNow(),
    staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
  });
};
