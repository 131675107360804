import { useCallback, useEffect } from "react";

export function useInfiniteScroll(observableElement, hasNextPage, onNextPage) {
  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (hasNextPage && target.isIntersecting) {
        onNextPage();
      }
    },
    [onNextPage, hasNextPage]
  );

  useEffect(() => {
    const element = observableElement.current;
    if (!element) return;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [onNextPage, hasNextPage, handleObserver, observableElement]);
}
