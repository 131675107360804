import { COLORS } from "../../assets/colors";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { datesAreOnSameDay, getThresholdDate } from "../../utils/helper";
import { Modal } from "react-bootstrap";
import DashboardModal from "./DashboardModal";
import { CircularProgress } from "@mui/material";
import { useApiGroupsData } from "../../context/groupContext";

const DashboardLineChart = (props) => {
  const { faces, isFetchingFaces, dateRange } = props;
  const { data:groups } = useApiGroupsData();
  const [showDashboardModal, setShowDashboardModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [modalFaces, setModalFaces] = useState([]);
  const [activeGroups, setActiveGroups] = useState([
    ...groups.map((g) => g.name),
    "Total",
  ]);

  const [chartData, setChartData] = useState([]);

  useEffect(
    () => setActiveGroups([...groups.map((g) => g.name), "Total"]),
    [groups]
  );

  useEffect(() => {
    const buildChartData = () => {
      const numRange =
        dateRange > 0 ? [...Array(dateRange).keys()].reverse() : [0];
      setChartData(
        numRange.map((i) => {
          var currentDay = getThresholdDate(i);
          var cameraRecognitions = { date: currentDay };
          groups
            .filter((group) => activeGroups.includes(group.name))
            .forEach((group) => {
              cameraRecognitions[group.name] = faces.filter(
                (f) =>
                  datesAreOnSameDay(currentDay, new Date(f.create_at)) &&
                  f.group_id === group.id
              ).length;
            });
          if (activeGroups.includes("Total")) {
            cameraRecognitions["Total"] = faces.filter((f) =>
              datesAreOnSameDay(currentDay, new Date(f.create_at))
            ).length;
          }

          return cameraRecognitions;
        })
      );
    };
    buildChartData();
  }, [activeGroups, groups, dateRange, faces]);

  const dayTickFormatter = (tick) => {
    const date = new Date(tick);

    var dateArr = date.toLocaleDateString().split("/");
    return `${dateArr[0]}/${dateArr[1]}`;
  };
  const customLegend = (props) => {
    const { payload } = props;
    return (
      <div className="text-center  align-items-center">
        {payload.map((entry, index) => (
          <span
            key={`item-${index}`}
            className="fs-7 me-2"
            style={{ cursor: "pointer" }}
            onClick={() => handleOnclickLegend(entry.value)}
          >
            <FaCircle
              style={{
                width: "14px",
                marginRight: "2px",
                color:
                  entry.value === "Total"
                    ? COLORS.GROUP.green
                    : COLORS.GROUP[
                        groups.find((g) => g.name === entry.value).color
                      ],
              }}
            />
            <span
              style={{
                color: COLORS.FontGray,
                fontSize: "14px",
                lineHeight: "16px",
                textDecoration: activeGroups.includes(entry.value)
                  ? "unset"
                  : "line-through",
              }}
            >
              {entry.value}
            </span>
          </span>
        ))}
      </div>
    );
  };

  const handleOnclickLegend = (label) => {
    activeGroups.includes(label)
      ? setActiveGroups(activeGroups.filter((l) => l !== label))
      : setActiveGroups([...activeGroups, label]);
  };

  const handleLineChartClicked = (data, index) => {
    const clickedDate = data.activeLabel;
    setModalSubtitle(
      activeGroups.includes("Total")
        ? groups.map((g) => g.name).join(", ")
        : activeGroups.join(", ")
    );
    setModalTitle(clickedDate.toLocaleDateString());
    const releventGroupsIds = groups
      .filter((group) => activeGroups.includes(group.name))
      .map((group) => group.id);
    const facesToSet = faces.filter(
      (face) =>
        datesAreOnSameDay(new Date(face.create_at), clickedDate) &&
        (activeGroups.includes("Total") ||
          releventGroupsIds.includes(face.group_id))
    );
    setModalFaces(facesToSet);
    setShowDashboardModal(true);
  };

  return (
    <div
      className="mt-3"
      style={{
        width: "100%",
        padding: "20px 12px 20px 12px",
        color: COLORS.FontGray,
        backgroundColor: COLORS.Gray700,
        borderRadius: "8px",
      }}
    >
      <Modal
        show={showDashboardModal}
        onHide={() => setShowDashboardModal(false)}
        keyboard={false}
        size="md"
        fullscreen={"md-down"}
      >
        <DashboardModal
          close={() => setShowDashboardModal(false)}
          faces={modalFaces}
          title={modalTitle}
          subtitle={modalSubtitle}
        />
      </Modal>

      <div className=" w-100 ">
        <span className="fs-5 mx-2">Trends</span>
        <span style={{ fontSize: "12px" }}>
          / Total identifications over time
        </span>
      </div>

      <div
        style={{
          width: "100%",
          height: "280px",
        }}
      >
        {isFetchingFaces ? (
          <div className="text-center h-100 mt-1 d-flex align-items-center justify-content-center">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ left: 20, right: 20 }}
              onClick={handleLineChartClicked}
              cursor="pointer"
            >
              <CartesianGrid vertical={false} strokeWidth={0.5} />
              <XAxis
                dataKey={"date"}
                height={20}
                tick={{ fontSize: 12 }}
                tickFormatter={dayTickFormatter}
                interval={"preserveStartEnd"}
              />
              <YAxis width={15} tick={{ fontSize: 12 }} />
              <Tooltip
                labelFormatter={(t) => new Date(t).toLocaleDateString()}
                contentStyle={{
                  backgroundColor: COLORS.Gray800,
                  borderWidth: "0",
                  borderRadius: "8px",
                }}
              />
              <Legend
                content={customLegend}
                align="center"
                verticalAlign="top"
              />
              {groups.map((group, i) => (
                <defs key={`defs-${group.id}`}>
                  <linearGradient
                    id={`groupColor-${group.id}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={COLORS.GROUP[group.color]}
                      stopOpacity={1}
                    />
                    <stop
                      offset="95%"
                      stopColor={COLORS.GROUP.LIGHT[group.color]}
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
              ))}

              {groups.map((group) => (
                <Line
                  key={`posture-lagend-${group.id}`}
                  type="monotone"
                  dataKey={group.name}
                  stroke={`url(#groupColor-${group.id})`}
                  strokeWidth={2}
                />
              ))}

              <defs>
                <linearGradient
                  id={`groupColor-Total`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={COLORS.GROUP.green}
                    stopOpacity={1}
                  />
                  <stop
                    offset="95%"
                    stopColor={COLORS.GROUP.LIGHT.green}
                    stopOpacity={0.7}
                  />
                </linearGradient>
              </defs>
              <Line
                type="monotone"
                dataKey="Total"
                strokeWidth={3}
                stroke={"url(#groupColor-Total)"}
                strokeDasharray="5 5"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default DashboardLineChart;
