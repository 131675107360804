import React from "react";
import { COLORS } from "../../assets/colors";
import { IMAGE_BASE_PATH } from "../../faceit-api/FaceitApi";

const FaceEffect = (props) => {
  const { image, person, setShowEffect, group } = props;

  setTimeout(() => {
    setShowEffect(false);
  }, 2000);

  // useEffect(() => {

  // });

  if (!person) {
    return <></>;
  }

  return (
    <div className="popup-face">
      <h3
        className="text-capitalize text-center w-100  text-white fw-bold"
        style={{
          position: "absolute",
          borderRadius: "8px",
          top: "10px",
          zIndex: "10",
        }}
      >
        {person.person_name.replaceAll("_", " ")}
      </h3>

      <div
        className="corners-only"
        style={{
          width: "inherit",
          height: "inherit",
          top: "0",
          left: "0",
          color: group ? COLORS.GROUP[group.color] : COLORS.GROUP.LIGHT.green,
        }}
      >
        <div style={{ height: "inherit", width: "inherit" }}>
          <img
            src={`${IMAGE_BASE_PATH}${image}`}
            style={{
              height: "inherit",
              width: "inherit",
              borderRadius: "8px",
            }}
            alt="Identified Person"
          />
        </div>
      </div>
    </div>
  );
};

export default FaceEffect;
