import { useInfiniteQuery, useMutation } from "react-query";
import { get, post } from "../faceit-api/FaceitApi";
import {
  bitAfterNow,
  buildFiltersQuery,
  DEFAULT_STALE_TIME,
  getQueryParams,
} from "../utils/helper";

import { useQuery } from "react-query";

async function checkFaces({ facesData }) {
  return post(`faces/check`, facesData);
}

async function getFaces(params, pageParam, details) {
  const queryParams = details ? { ...params, details: true } : params;
  const similarity = params.similarity ? "/similarity" : "";
  return get(
    `faces${similarity}?${new URLSearchParams({
      ...queryParams,
      page: pageParam,
    }).toString()}`
  ).then((resp) => {
    return resp;
  });
}

export const fetchFacesByTrackingId = async (trackingId) => {
  return get(`faces?tracking_id=${trackingId}`).then((resp) => {
    return resp;
  });
};

export async function getSingleFace(faceId) {
  return get(`faces?image=${faceId}&details=true`).then((resp) => {
    if (resp.results?.length) return resp.results[0];
    return { status: 0, message: "Face not found" };
  });
}

export const useApiCheckFaces = () => {
  return useMutation(checkFaces, {
    onSuccess: (resp, { onSuccessCallback, onErrorCallback }) => {
      if (resp.status === 1) {
        return onSuccessCallback(resp);
      } else {
        onErrorCallback(resp);
      }
    },
    onError: (resp, { onErrorCallback }) => onErrorCallback(resp),
  });
};

export const useApiFacesFilteredPagination = (
  pageSize,
  filters,
  sort,
  persons,
  enabled,
  details = false,
  noFilterBuild = false
) => {
  const parsed_filters = noFilterBuild
    ? filters
    : buildFiltersQuery(filters || {}, persons);
  const readyToUseFilters = {
    page_size: pageSize,
    ordering: sort,
    ...parsed_filters,
  };

  return useInfiniteQuery(
    ["getFaces", readyToUseFilters],
    ({ pageParam = 1 }) => getFaces(readyToUseFilters, pageParam, details),
    {
      keepPreviousData: true,
      enabled: enabled,
      initialData: {}, // { results: [] },
      initialDataUpdatedAt: bitAfterNow(),
      staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
      getNextPageParam: (lastPage, allPages) =>
        lastPage?.next
          ? parseInt(getQueryParams(lastPage?.next)?.page || 1)
          : null,
    }
  );
};

export function useApiFacesByTrackingId(trackingId) {
  return useQuery(
    ["facesByTrackingId", trackingId],
    () => fetchFacesByTrackingId(trackingId),
    {
      keepPreviousData: true,
      initialData: [],
      initialDataUpdatedAt: bitAfterNow(),
      staleTime: DEFAULT_STALE_TIME, // only eligible to refetch after 1 minute
      enabled: !!trackingId,
    }
  );
}
