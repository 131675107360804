import styled from "styled-components";
import { COLORS } from "../../../assets/colors";

export type ButtonSize = "small" | "medium" | "large";

export type BaseButtonProps = {
  children: React.ReactNode;
  size?: ButtonSize;
  onClick?: () => void;
  className?: string;
  id?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
};

const getPadding = (size: ButtonSize) => {
    if (size === "small") {
      return "6px 20px";
    }

    if (size === "large") {
      return "14px 38px";
    }
    return "10px 28px";
  };

export const BaseButton = styled.button<BaseButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => getPadding(props.size || "small")};
  background: ${COLORS.primary};
  border: unset;
  border-radius: 8px;
  color: ${COLORS.FontGray};
  text-align: center;
  font-size: 14px;
  &:focus {
    outline: none;
  }
  &:hover:enabled {
    background: ${COLORS.PrimaryLight} !important;
    color: ${COLORS.Gray800} !important;
  }
  &:active {
    background: ${COLORS.primaryDark} !important;
  }
  &:disabled {
    background: ${COLORS.Gray500} !important;
    color: ${COLORS.Gray800} !important;
    cursor: not-allowed !important;
  }
  ${({ style }) => style && { ...style }};
`;