import React, { useState } from "react";
import Select from "react-select";
import { COLORS } from "../../assets/colors";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { MdGroupAdd } from "react-icons/md";
import { showErrorDialog, showSuccessDialog } from "../../popups/opretaions";
import { useApiCreatePerson } from "../../context/personsContext";
import { useApiGroupsData } from "../../context/groupContext";

function AddFaceToNewPerson(props) {
  const { face, close } = props;
  const { data:groups } = useApiGroupsData();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [personName, setPersonName] = useState(null);
  const [personDescription, setPersonDescription] = useState(null);
  const { mutate: createPerson, isLoading: addingPerson } =
    useApiCreatePerson();

  const createNewPerson = () => {
    createPerson({
      personData: {
        faces: [face.image],
        person_name: personName,
        group: selectedGroup,
        description: personDescription,
      },
      onSuccessCallback: () =>
        showSuccessDialog("Person added successfully").then(close),
      onErrorCallback: () => showErrorDialog("Failed to add person"),
    });
  };

  return (
    <div className="mx-2 row mt-2">
      <div
        className="p-2 "
        style={{
          backgroundColor: COLORS.Gray600,
          borderRadius: "8px",
        }}
      >
        <div className="d-flex justify-content-end">
          <FaTimes
            className="text-end fs-4"
            onClick={close}
            style={{ color: COLORS.FontGray, cursor: "pointer" }}
          />
        </div>
        <div className="text-center mb-2 fs-5 fw-bold ">Create new person</div>
        <div className="row col-12 col-lg-10 d-flex justify-content-center">
          <div className="d-flex align-items-center mb-2 row">
            <label className="col-12 col-lg-3 text-start">Name</label>
            <input
              className={`sr-text-input col-12 col-lg-8`}
              placeholder="Jack Jeckson..."
              value={personName}
              onChange={(e) => setPersonName(e.target.value)}
              type="text"
            />
          </div>
          <div className="d-flex align-items-center mb-2 row">
            <label className="col-12 col-lg-3 text-start">Group</label>
            <Select
              className={`react-select-container col-12 col-lg-8 px-0`}
              classNamePrefix="react-select"
              placeholder="Select group..."
              value={groups
                .map((g) => {
                  return {
                    label: g.name,
                    value: g.id,
                  };
                })
                .find((g) => g.value === selectedGroup)}
              onChange={(e) => setSelectedGroup(e.value)}
              options={groups.map((g) => {
                return {
                  label: g.name,
                  value: g.id,
                };
              })}
              theme="dark"
            />
          </div>
          <div className="d-flex  mb-2 row">
            <label className="col-12 col-lg-3 text-start mt-1">
              Description
            </label>
            <textarea
              className={`sr-textarea col-12 col-lg-8`}
              placeholder="Write a short description about the person..."
              value={personDescription}
              onChange={(e) => setPersonDescription(e.target.value)}
              type="textarea"
            ></textarea>
          </div>
        </div>

        <div className="d-lg-flex justify-content-around mt-2">
          <button
            onClick={close}
            className="sr-outline-btn col-12 col-lg-3 mt-2"
          >
            Cancel
          </button>
          <button
            disabled={!selectedGroup || !personName}
            className="sr-btn  col-12 col-lg-3 mt-2 d-flex align-items-center justify-content-center"
            onClick={createNewPerson}
          >
            {addingPerson ? (
              <FaSpinner className="fa-spin me-2 fs-4" />
            ) : (
              <MdGroupAdd className="me-2 fs-4" />
            )}
            Create Person
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddFaceToNewPerson;
